import {
    FETCH_CALLS,
    FETCH_CALLS_RESULT,
    FETCH_CALLS_ERROR,
    FETCH_CALLS_RETRY,

    FETCH_CALL,
    FETCH_CALL_RESULT,
    FETCH_CALL_ERROR,
    FETCH_CALL_RETRY,

    CREATE_CALL,
    CREATE_CALL_RESULT,
    CREATE_CALL_ERROR,
    CREATE_CALL_RETRY,

    UPDATE_CALL,
    UPDATE_CALL_RESULT,
    UPDATE_CALL_ERROR,
    UPDATE_CALL_RETRY,

    FETCH_INCOMING_CALL,
    FETCH_INCOMING_CALL_ERROR,
    FETCH_INCOMING_CALL_RESULT,
    FETCH_INCOMING_CALL_RETRY,

    CREATE_CALL_TRANSFER,
    CREATE_CALL_TRANSFER_RESULT,
    CREATE_CALL_TRANSFER_ERROR,
    CREATE_CALL_TRANSFER_RETRY,

    FETCH_ACTIVE_AGENTS,
    FETCH_ACTIVE_AGENTS_RESULT,
    FETCH_ACTIVE_AGENTS_ERROR,
    FETCH_ACTIVE_AGENTS_RETRY,

} from '../../actions/ecommerce/calls';


const initialState = {
    incomingCall: null,
    detail: {},
    list: {},
    activeAgents: [],
    isFetching: false,
    isFetchingList: false,
    isCreating: false,
    isUpdating: false,
    isFetchingIncomingCall: false,
    isCreatingCallTransfer: false,
    isFetchingAgents: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_CALLS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_CALLS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_CALLS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_CALLS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_CALL:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_CALL_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_CALL_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_CALL_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CREATE_CALL:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_CALL_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_CALL_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_CALL_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_CALL:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_CALL_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_CALL_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_CALL_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case FETCH_INCOMING_CALL:
            return {...state, isFetchingIncomingCall: true, incomingCall: null, error: false, message: null, retry: false};
        case FETCH_INCOMING_CALL_ERROR:
            return {...state, isFetchingIncomingCall: false, error: true, message: action.message};
        case FETCH_INCOMING_CALL_RESULT:
            return {...state, isFetchingIncomingCall: false, incomingCall: action.data};
        case FETCH_INCOMING_CALL_RETRY:
            return {...state, isFetchingIncomingCall: false, message: action.message, retry: true};


        case CREATE_CALL_TRANSFER:
            return {...state, isCreatingCallTransfer: true, error: false, message: null, retry: false};
        case CREATE_CALL_TRANSFER_RESULT:
            return {...state, isCreatingCallTransfer: false, };
        case CREATE_CALL_TRANSFER_ERROR:
            return {...state, isCreatingCallTransfer: false, error: true, message: action.message};
        case CREATE_CALL_TRANSFER_RETRY:
            return {...state, isCreatingCallTransfer: false, retry: true, message: action.message};


        case FETCH_ACTIVE_AGENTS:
            return {...state, isFetchingAgents: true, error: false, message: null, retry: false};
        case FETCH_ACTIVE_AGENTS_RESULT:
            return {...state, isFetchingAgents: false, activeAgents: action.data};
        case FETCH_ACTIVE_AGENTS_ERROR:
            return {...state, isFetchingAgents: false, error: true, message: action.message};
        case FETCH_ACTIVE_AGENTS_RETRY:
            return {...state, isFetchingAgents: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
