import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchClinics} from "../../actions/support/clinics";
import './styles.scss';


class Clinics extends React.Component {

    static propTypes = {
        clinics: PropTypes.object,
        fetchClinics: PropTypes.func,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchClinics();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        let {clinics} = this.props;

        return (
            <SidebarContainer>
                <div className="container-fluid">
                    <MainNavbar pageName={'Clinics'}/>
                    <div className="row">
                        <div className="col-12 pt-3">
                            <Table className="order-list text-unselectable">
                                <thead>
                                <tr>
                                    <th>S no.</th>
                                    <th>Clinic Name</th>
                                    <th>Clinic Number</th>
                                    <th>Clinic Email</th>
                                    <th>Clinic Address</th>
                                    <th>Clinic Link</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Gurgaon Sex Clinic</td>
                                    <td>1800-121-9555</td>
                                    <td>m@misters.in</td>
                                    <td style={{maxWidth: 200}}>
                                        1st Floor, Shop no. 13-14, Goshala Market, Opposite Bus, Gurgaon - 122001
                                    </td>
                                    <td>
                                        <a href="https://misters.in/en/pages/sexologists-sex-clinic-location-gurgaon/"
                                           target="_blank">
                                            Open Page
                                        </a>
                                    </td>
                                </tr>
                                {
                                    (clinics.list || []).map(
                                        (clinic, index) => (
                                            <tr key={clinic.id}>
                                                <td>{index + 2}</td>
                                                <td>{clinic.title}</td>
                                                <td>{clinic.phone_number}</td>
                                                <td>{clinic.email}</td>
                                                <td style={{maxWidth: 200}}>{clinic.address}</td>
                                                <td>
                                                    <a href={`https://misters.in/en/clinic-community/${clinic.slug}/`}
                                                       target="_blank">
                                                        Open Page
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    clinics: state.support.clinics,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchClinics,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Clinics);
