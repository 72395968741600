import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import moment from "moment";
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";
import './styles.scss';


export default class CallHistory extends PureComponent {

    static propTypes = {
        supportCalls: PropTypes.array,
        onClickView: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        supportCalls: [],
        title: 'Call History'
    };

    render() {
        let {supportCalls, onClickView, title} = this.props

        return (
            <React.Fragment>
                <h5 className="heading">{title}</h5>
                <Table className="order-list" responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Called by</th>
                        <th>Call Type</th>
                        <th>Order ID</th>
                        <th>Recording</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        supportCalls.map((call, index) => (
                                <tr key={call.sid}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {moment(call.created_at).format("DD MMMM YYYY, h:mm a")}
                                    </td>
                                    <td>{call.user_name}</td>
                                    <td>{call.call_type}</td>
                                    <td><NavLink to={`/orders/${call.order_id}`}>{call.order_id}</NavLink></td>
                                    <td>
                                        {!!call.recording_url &&
                                        <audio className="audio-input" controls controlsList="nodownload">
                                            <source src={call.recording_url}/>
                                        </audio>
                                        }
                                    </td>
                                    <td>
                                        <Button variant="dark" size={'sm'} onClick={() => onClickView(call)}>
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            )
                        )
                    }
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
