import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import moment from "moment";
import {Button, Form, Spinner, Table} from "react-bootstrap";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {
    getCallAttemptsBackgroundColor,
    isObjectsEqual,
    getQueryString,
    getSearchParams,
    scrollToTop,
    getIndexCount,
} from "../../constants";
import './styles.scss';
import PaginationComponent from "../../components/others/PaginationComponent";
import OrdersColorCoding from "../../components/others/OrdersColorCoding";
import {fetchQuizzers} from "../../actions/quiz/quizzers";


class Quizzers extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        fetchQuizzers: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {
            page = '1',
            q = '',
        } = getSearchParams(search);

        this.state = {
            order: null,
            reportId: null,

            filters: {
                q,
                page: parseInt(page),
            }
        };
    }

    componentDidMount() {
        let {filters} = this.state;
        this.props.fetchQuizzers(filters);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetchingList, error, retry} = this.props.quizzers;

        let {filters} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isObjectsEqual(queryParams, prevQueryParams)) {
            this.setState({
                filters: {
                    ...filters,
                    q: queryParams.q || '',
                    page: parseInt(queryParams.page || "1"),
                },
            }, () => this.handleFetchQuizzers());
        }

        if (!isFetchingList && prevProps.quizzers.isFetchingList && !error && !retry) {
            scrollToTop();
        }
    }

    handleChangeFilter = (filters) => {
        this.setState({filters});
    }

    handleChangePagination = (filters) => {
        this.setState({filters}, () => this.handleSubmitFilters());
    }

    handleSubmitFilters = (e) => {
        !!e && e.preventDefault();
        let {filters} = this.state;
        this.props.history.push(`/quizzers?${getQueryString(filters)}`);
    }

    handleFetchQuizzers = () => {
        let {filters} = this.state;
        this.props.fetchQuizzers(filters);
    };

    handleClickInfo = (report) => {
        let {history} = this.props
        history.push(`/quizzers/${report.id}`)
    }

    render() {
        let {quizzers: {list: {results = [], count}, isFetchingList}} = this.props;
        let {filters, filters: {q, page}, reportId} = this.state;
        // created_at: "2020-01-18T11:35:23.506706+05:30"
        // data: {age: {…}, 18-30: {…}, 31-40: {…}, 41-50: {…}, 51-60: {…}, …}
        // email: null
        // id: 56
        // report_id: "krgy42wenmnv7gg10ug4nt56"
        // report_sheet: 4
        // updated_at: "2020-01-18T11:35:23.510849+05:30"
        // user: 136802

        return (
            <SidebarContainer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <MainNavbar pageName={'All Quiz Takers'}/>
                            <form className="row" onSubmit={this.handleSubmitFilters}>
                                <div className="col-12">
                                    <Form.Group className="m-0">
                                        <Form.Control type="text" className="custom-form-control mr-2"
                                                      value={q}
                                                      placeholder="Search reports..."
                                                      onChange={(e) => this.handleChangeFilter({
                                                          ...filters,
                                                          page: 1,
                                                          q: e.target.value
                                                      })}/>
                                    </Form.Group>
                                </div>
                                <div className="col-12 mt-3">
                                    <Button className="custom-button py-2" type="submit" variant="success"
                                            size="sm" style={{maxWidth: '200px', width: '100%'}}>
                                        {isFetchingList ?
                                            <Spinner size="sm" animation="border"/> :
                                            "Apply Filter"
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div>

                        <div className="col-12 col-md-6 offset-md-1 mt-3">
                            <div className="row">
                                <div className="col-md-6 col-12 order-md-2 order-1">
                                    <OrdersColorCoding/>
                                </div>
                                {!!count &&
                                <div className="col-12 mt-4">
                                    <PaginationComponent className="m-0" page={parseInt(page)} count={parseInt(count)}
                                                         totalLabel={'Total Quiz Takers'}
                                                         onClick={(page) => this.handleChangePagination({
                                                             ...filters,
                                                             page,
                                                         })}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>


                    <div className="row pt-3">
                        <div className="col-12">
                            <Table className="order-list" responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Report ID</th>
                                    <th>Timestamp</th>
                                    <th className="text-center">Call Attempts</th>
                                    <th className="text-center">Call Connected</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (report, index) => (
                                            <tr key={report.report_id} onClick={() => this.handleClickInfo(report)}
                                                className="cursor-pointer"
                                                style={{backgroundColor: getCallAttemptsBackgroundColor(report)}}>
                                                <td>{getIndexCount(index, page, 50)}</td>
                                                <td>{report.report_id}</td>
                                                <td>
                                                    {moment(report.created_at).format("D MMMM YYYY,")}
                                                    <br/>
                                                    {moment(report.created_at).format("h:mm:ss a")}
                                                </td>
                                                <td className="text-center">
                                                    {report.attempted_calls}
                                                </td>
                                                <td className="text-center">
                                                    {report.connected_calls}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    quizzers: state.quiz.quizzers,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchQuizzers,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Quizzers);
