import {call, put} from 'redux-saga/effects';
import {
    USER_LOGIN_RESULT,
    USER_LOGIN_ERROR,
    USER_LOGIN_RETRY,
    REQUEST_OTP_RESULT,
    REQUEST_OTP_ERROR,
    REQUEST_OTP_RETRY, TOKEN_VERIFY_RESULT, TOKEN_VERIFY_ERROR, TOKEN_VERIFY_RETRY,
} from '../../actions/accounts/login';
import api from '../api';
import {ACCESS, ACCESS_EXPIRE, ACCESS_EXPIRE_TIME, REFRESH, USER} from "../makeRequest";
import {isUserSupport} from "../../permissions";
import {setStorageItem} from "../../constants";
import moment from "moment";


export function* userLogin(action) {
    try {
        // const {phoneNumber, otp} = action;
        const response = yield call(api.accounts.postUserLogin, action.data);
        const result = yield response.json();
        console.warn('SAGA:userLogin', action, result);

        if (response.status === 200) {
            if (action.data.otp) {
                if (isUserSupport(result.user)) {
                    yield setStorageItem(ACCESS, result.token.access);
                    yield setStorageItem(USER, JSON.stringify(result.user));
                    yield setStorageItem(REFRESH, result.token.refresh);
                    yield setStorageItem(ACCESS_EXPIRE, moment().add(ACCESS_EXPIRE_TIME.value, ACCESS_EXPIRE_TIME.unit));
                    yield put({type: USER_LOGIN_RESULT, data: result, otpSent: false});
                }
                else {
                    yield put({type: USER_LOGIN_ERROR, error: true, message: 'You are not a support member.'});
                }
            }
            yield put({type: USER_LOGIN_RESULT, data: result, otpSent: true});
        }
        else {
            yield put({type: USER_LOGIN_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: USER_LOGIN_RETRY, retry: true, message: e.message});
    }
}

export function* requestOtp(action) {
    try {
        // const {phoneNumber, otp} = action;
        const response = yield call(api.accounts.requestOtp, action.data);
        const result = yield response.json();
        console.warn('SAGA:requestOtp', action, result);

        if (response.status === 200) {
            yield put({type: REQUEST_OTP_RESULT, data: result});
        }
        else {
            yield put({type: REQUEST_OTP_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: REQUEST_OTP_RETRY, retry: true, message: e.message});
    }
}


export function* tokenVerify(action) {
    try {
        // const {phoneNumber, otp} = action;
        let token = yield localStorage.getItem(ACCESS);

        const response = yield call(api.accounts.tokenVerify, {token});
        const result = yield response.json();
        console.warn('SAGA:tokenVerify', action, result);

        if (response.status === 200) {
            yield put({type: TOKEN_VERIFY_RESULT, });
        }
        else {
            yield localStorage.removeItem(ACCESS);
            yield localStorage.removeItem('user');
            yield put({type: TOKEN_VERIFY_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: TOKEN_VERIFY_RETRY, retry: true, message: e.message});
    }
}
