import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';

import './styles.scss';
import Button from "react-bootstrap/Button";


class OrderDetailButtons extends PureComponent {

    static propTypes = {
        onClickConfirmOrder: PropTypes.func,
        onClickCancelOrder: PropTypes.func,
        onClickCallOrder: PropTypes.func,
        onClickDetailedTrack: PropTypes.func,
        onClickRefund: PropTypes.func,
        onClickResendPaymentLink: PropTypes.func,
        onClickConvertToOnline: PropTypes.func,
        onClickScheduledCall: PropTypes.func,
        onClickTrackOrder: PropTypes.func,
        onClickCreateOrder: PropTypes.func,
        onClickAyurvedicLink: PropTypes.func,
        onClickCheckoutLink: PropTypes.func,
        onClickAyurvedicQuiz: PropTypes.func,
        showCall: PropTypes.bool,
        showCancel: PropTypes.bool,
        showConfirm: PropTypes.bool,
        showRefund: PropTypes.bool,
        showResendPaymentLink: PropTypes.bool,
        showConvertToOnline: PropTypes.bool,
        showTrackOrder: PropTypes.bool,
        isCreatingCall: PropTypes.bool,
    };

    render() {

        let {
            onClickConfirmOrder, onClickCancelOrder, onClickCallOrder, onClickRefund, onClickResendPaymentLink, onClickConvertToOnline,
            onClickDetailedTrack, showCall, showCancel, showConfirm, showRefund, showResendPaymentLink, showConvertToOnline,
            onClickScheduledCall, showTrackOrder, onClickTrackOrder, onClickCreateOrder, onClickAyurvedicLink, onClickCheckoutLink,
            onClickAyurvedicQuiz, isCreatingCall
        } = this.props

        return (
            <div className="order-detail-top-section">
                <div className="button-container">
                    {!!onClickConfirmOrder && !!showConfirm &&
                    <Button className="order-detail-button" variant="primary"
                            onClick={onClickConfirmOrder}>Confirm Order</Button>}

                    {!!onClickCancelOrder && !!showCancel &&
                    <Button className="order-detail-button" variant="warning"
                            onClick={onClickCancelOrder}>Cancel Order</Button>}

                    {!!onClickCallOrder && !!showCall &&
                    <Button className="order-detail-button" variant="info"
                            disabled={isCreatingCall}
                            onClick={onClickCallOrder}>{isCreatingCall ? "Calling...": "Call Customer"}</Button>}

                    {!!onClickDetailedTrack &&
                    <Button className="order-detail-button tracking" variant="primary"
                            onClick={onClickDetailedTrack}>
                        Order status detail
                    </Button>}
                    {!!onClickCreateOrder &&
                    <Button variant="info" className="order-detail-button"
                            onClick={onClickCreateOrder}>Create New Order</Button>}
                    {
                        showResendPaymentLink &&
                        <Button className="order-detail-button" variant="danger"
                                onClick={onClickResendPaymentLink}>
                            Resend Payment Link
                        </Button>
                    }
                    {
                        showConvertToOnline &&
                        <Button className="order-detail-button" variant="danger"
                                onClick={onClickConvertToOnline}>
                            Convert to Online
                        </Button>
                    }

                    {!!showRefund && !!onClickRefund &&
                    <Button className="order-detail-button" variant="danger"
                            onClick={onClickRefund}>
                        Refund
                    </Button>}

                    {!!onClickScheduledCall &&
                    <Button variant="dark" className="order-detail-button"
                            onClick={onClickScheduledCall}>Schedule Call</Button>}

                    {!!showTrackOrder &&
                    <Button variant="primary" className="order-detail-button"
                            onClick={onClickTrackOrder}>Track Order</Button>}

                    {!!onClickAyurvedicLink &&
                    <Button variant="warning" className="order-detail-button"
                            onClick={onClickAyurvedicLink}>Send Ayurvedic Quiz Link</Button>}

                    {!!onClickAyurvedicQuiz &&
                    <Button variant="warning" className="order-detail-button"
                            onClick={onClickAyurvedicQuiz}>Take Ayurvedic Quiz</Button>}

                    {!!onClickCheckoutLink &&
                    <Button variant="primary" className="order-detail-button"
                            onClick={onClickCheckoutLink}>Send Checkout Link</Button>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isCreatingCall: state.ecommerce.calls.isCreating,
    }
}

export default connect(mapStateToProps)(OrderDetailButtons);
