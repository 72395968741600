import {call, put} from 'redux-saga/effects';
import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_RESULT,
    FETCH_CUSTOMERS_ERROR,
    FETCH_CUSTOMERS_RETRY,

    FETCH_CUSTOMER_RESULT,
    FETCH_CUSTOMER_ERROR,
    FETCH_CUSTOMER_RETRY,
} from '../../actions/ecommerce/customers';
import api from "../api";


export function* fetchCustomers(action) {
    try {
        const response = yield call(api.ecommerce.customers.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CUSTOMERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_CUSTOMERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CUSTOMERS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchCustomer(action) {
    try {
        const response = yield call(api.ecommerce.customers.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrderInfo', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CUSTOMER_RESULT, data: result});
        } else {
            yield put({type: FETCH_CUSTOMER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CUSTOMER_RETRY, retry: true, message: e.message});
    }
}
