import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import {Images} from "../../../constants";


class SidebarHeader extends PureComponent {

    static propTypes = {
        // type: PropTypes.oneOf(['Dashboard', 'Forms', 'Reports', 'Settings']),
        type: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
    };
    render() {
        const {type, icon, onClick} = this.props;

        return (
            <li className='sidebar-header mb-5' onClick={onClick}>
                {/*<i className="material-icons">{icon}</i>*/}
                {/*<span className='side-item-text text-capitalize'>{type}</span>*/}
                <img className="logo" src={Images.logo} alt="logo"/>
            </li>
        )
    }
}

export default SidebarHeader;