import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {createCall, fetchCalls} from "../../actions/ecommerce/calls";
import {getQueryString, getSearchParams, isObjectsEqual} from "../../constants";
import {callStatuses, callTypeOptions} from "../../callConstants";
import CreateCallModal from "../../components/modals/CreateCallModal";
import CallDetailModal from "../../components/modals/CallDetailModal";
import PaginationComponent from "../../components/others/PaginationComponent";
import './styles.scss';
import {NavLink} from "react-router-dom";
import DateRange from "../../components/others/DateRange";


class Calls extends React.Component {

    static propTypes = {
        calls: PropTypes.object,
        fetchCalls: PropTypes.func,
        createCall: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = '1', q = '', status = '', from_date = '', to_date = ''} = getSearchParams(search);

        this.state = {
            showCallDetail: false,
            showCreateCall: false,
            order: null,

            filters: {
                q,
                page: parseInt(page),
                status,
                from_date,
                to_date,
            }
        };
    }

    componentDidMount() {
        this.handleCallUpdates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {calls} = this.props;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isObjectsEqual(queryParams, prevQueryParams)) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    status: queryParams.status || '',
                    q: queryParams.q || '',
                    page: parseInt(queryParams.page || "1"),
                    from_date: queryParams.from_date || '',
                    to_date: queryParams.to_date || '',
                }
            }, () => this.handleCallUpdates());
        }

        if (!calls.isCreating && prevProps.calls.isCreating && !calls.error && !calls.retry) {
            this.handleCallUpdates();
            this.setState({callDetail: calls.detail, showCreateCall: false})
        }

        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            this.handleCallUpdates();
            this.setState({callDetail: {}});
        }
    }

    handleCallUpdates = () => {
        let {filters} = this.state;
        this.props.fetchCalls(filters);
    };

    handleMakeCall = (call) => {
        this.props.createCall(call);
    }

    handleChangeFilter = (filters, refresh = false) => {
        this.setState({filters}, () => !!refresh ? this.handleSubmitFilters() : '');
    }

    handleSubmitFilters = (e) => {
        !!e && e.preventDefault();
        let {filters} = this.state;
        this.props.history.push(`/calls/?${getQueryString(filters)}`);
    }

    render() {
        let {calls, calls: {list: {count = 0}, isFetchingList}} = this.props;
        let {filters, filters: {q, page, status, from_date, to_date}} = this.state;

        return (
            <SidebarContainer>
                <CreateCallModal show={this.state.showCreateCall}
                                 handleMakeCall={this.handleMakeCall}
                                 handleClose={() => this.setState({showCreateCall: false})}/>

                {
                    !!this.state.callDetail &&
                    <CallDetailModal show={true} callDetail={this.state.callDetail}
                                     handleClose={() => this.setState({callDetail: undefined})}/>
                }

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-5">
                            <MainNavbar pageName={'Calls'}/>

                            <form className="row" onSubmit={this.handleSubmitFilters}>
                                <div className="col-12">
                                    <Form.Control type="text" className="custom-form-control mr-2"
                                                  value={q || ''} placeholder="Search"
                                                  onChange={(e) => this.handleChangeFilter({
                                                      ...filters,
                                                      page: 1,
                                                      q: e.target.value
                                                  })}/>
                                </div>
                                <div className="col-6 mt-2">
                                    <Form.Group controlId="fieldId" className="m-0">
                                        <Form.Label className="custom-form-label">Status</Form.Label>
                                        <Select options={callStatuses.map(s => s)} isClearable={true}
                                                className="custom-form-control" placeholder="Select Status"
                                                value={callStatuses.filter(s => s.value === status)[0]}
                                                onChange={(s) => this.handleChangeFilter({
                                                    ...filters,
                                                    page: 1,
                                                    status: s ? s.value : ''
                                                })}/>
                                    </Form.Group>
                                </div>
                                <div className="col-6 mt-2">
                                    <DateRange fromDate={from_date} toDate={to_date}
                                               label={'Call Date Filter'}
                                               handleChangeFromDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   from_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}
                                               handleChangeToDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   to_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}/>
                                </div>
                                <div className="col-12 mt-3">
                                    <Button className="custom-button py-2" type="submit" variant="success"
                                            size="sm" style={{maxWidth: '200px', width: '100%'}}>
                                        {isFetchingList ?
                                            <Spinner size="sm" animation="border"/> :
                                            "Apply Filter"
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div>

                        <div className="col-12 col-sm-6 offset-sm-1 mt-5">
                            <div className="row">
                                <div className="col-12 text-right">
                                    <Button variant="primary" size={'sm'}
                                            onClick={() => this.setState({showCreateCall: true})}>
                                        Make Call
                                    </Button>
                                </div>
                                {!!count &&
                                <div className="col-12 mt-4">
                                    <PaginationComponent page={parseInt(page)} perPage={20} count={parseInt(count)}
                                                         totalLabel={'Total Calls'}
                                                         onClick={(page) => this.handleChangeFilter({
                                                             ...filters,
                                                             page,
                                                         }, true)}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 pt-3">
                            <Table className="order-list text-unselectable">
                                <thead>
                                <tr>
                                    <th>Exophone SID</th>
                                    <th>Call ID</th>
                                    <th>Order ID</th>
                                    <th>Customer ID</th>
                                    <th>Status</th>
                                    <th>Call Type</th>
                                    <th>User Name</th>
                                    <th>Recording</th>
                                    <th>Call Duration</th>
                                    <th>Conversation Duration</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (calls.list.results || []).map(
                                        (call) => (
                                            <tr key={call.sid}>
                                                <td className='order-cid'>
                                                    <NavLink to={`/calls/${call.sid}/`}>
                                                        {call.exophone_sid}
                                                    </NavLink>
                                                </td>
                                                <td className='order-cid'>{call.id}</td>
                                                <td className='order-cid'>{call.order}</td>
                                                <td className='order-cid'>{call.customer_id}</td>
                                                <td className="order-status">{call.status}</td>
                                                <td className="order-status">{call.call_type.split('_').join(" ")}</td>
                                                <td className="order-status">{call.user_name}</td>
                                                <td>
                                                    {!!call.recording_url &&
                                                    <audio className="audio-input" controls controlsList="nodownload"
                                                           style={{maxWidth: '250px'}}>
                                                        <source src={call.recording_url}/>
                                                    </audio>
                                                    }
                                                </td>
                                                <td className='order-date'>
                                                    {call.duration ? call.duration: '0'}
                                                </td>
                                                <td className='order-date'>
                                                    {call.conversation_duration ? call.conversation_duration: '0'}
                                                </td>
                                                <td className='order-date'>
                                                    {moment(call.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                                <td>
                                                    <Button className="custom-button" variant={'success'} size={'sm'}
                                                            onClick={() => this.setState({callDetail: call})}>
                                                        View
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCalls,
    createCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(Calls);
