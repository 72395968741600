import {
    FETCH_QUIZZERS,
    FETCH_QUIZZERS_RESULT,
    FETCH_QUIZZERS_ERROR,
    FETCH_QUIZZERS_RETRY,

    FETCH_QUIZZER_INFO,
    FETCH_QUIZZER_INFO_RESULT,
    FETCH_QUIZZER_INFO_ERROR,
    FETCH_QUIZZER_INFO_RETRY,
} from '../../actions/quiz/quizzers';


const initialState = {
    detail: {},
    list: {},
    isFetching: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_QUIZZERS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_QUIZZERS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_QUIZZERS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_QUIZZERS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_QUIZZER_INFO:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_QUIZZER_INFO_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_QUIZZER_INFO_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_QUIZZER_INFO_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
