// External Imports
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
// My Imports
import registerServiceWorker from './registerServiceWorker';
import store, {history} from './store';
import App from './App';

const target = document.getElementById('root');


ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>,
    target
);

// registerServiceWorker();
