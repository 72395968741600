import {
    FETCH_SCHEDULED_CALLS,
    FETCH_SCHEDULED_CALLS_RESULT,
    FETCH_SCHEDULED_CALLS_ERROR,
    FETCH_SCHEDULED_CALLS_RETRY,

    CREATE_SCHEDULED_CALL,
    CREATE_SCHEDULED_CALL_RESULT,
    CREATE_SCHEDULED_CALL_ERROR,
    CREATE_SCHEDULED_CALL_RETRY,

    UPDATE_SCHEDULED_CALL,
    UPDATE_SCHEDULED_CALL_RESULT,
    UPDATE_SCHEDULED_CALL_ERROR,
    UPDATE_SCHEDULED_CALL_RETRY,
} from '../../actions/ecommerce/scheduledcalls';


const initialState = {
    detail: {},
    list: {},
    isFetchingList: false,
    isCreating: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_SCHEDULED_CALLS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_SCHEDULED_CALLS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_SCHEDULED_CALLS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_SCHEDULED_CALLS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case CREATE_SCHEDULED_CALL:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_SCHEDULED_CALL_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_SCHEDULED_CALL_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_SCHEDULED_CALL_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_SCHEDULED_CALL:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_SCHEDULED_CALL_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_SCHEDULED_CALL_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_SCHEDULED_CALL_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
