import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col} from "react-bootstrap";

import './styles.scss';
import {isObjectsEqual} from "../../../constants";
import {updateOrderAddress} from "../../../actions/ecommerce/orders";


class AddressForm extends React.Component {

    static propTypes = {
        shippingAddress: PropTypes.any,
        handleUpdate: PropTypes.func,
        updateOrderAddress: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let {shippingAddress} = this.props;

        let {name = '', street = '', street2 = '', landmark = '', city = '', state = '', zipcode = '', email = ''} = shippingAddress;
        this.state = {
            name,
            street,
            city,
            state,
            zipcode,
            street2,
            landmark,
            email: !!email ? email : '',
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {shippingAddress} = this.props;

        if (!isObjectsEqual(shippingAddress, prevProps.shippingAddress)) {
            let {name = '', street = '', street2 = '', landmark = '', city = '', state = '', zipcode = '', email = ''} = shippingAddress;
            this.setState({
                name,
                street,
                city,
                state,
                zipcode,
                street2,
                landmark,
                email: !!email ? email : '',
            })
        }
    }

    handleUpdate = () => {

        let {updateOrderAddress, orderId, shippingAddress} = this.props;
        let {name, street, city, state, zipcode} = this.state;
        let enableUpdate = name.length && street.length && city.length && state.length && zipcode.length;

        if (!!enableUpdate) {
            updateOrderAddress({id: orderId, shipping_address: {id: shippingAddress.id, ...this.state}});
        }
    }

    render() {
        let {name, street, city, state, zipcode} = this.state;
        let enableUpdate = name.length && street.length && city.length && state.length && zipcode.length;

        return (
            <div className="address-form">
                <Form.Row>
                    <Form.Group as={Col} controlId="formName">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control type="text" placeholder="Name"
                                      onChange={(e) => this.setState({name: e.target.value})}
                                      value={this.state.name}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="customer’s email address"
                                      onChange={(e) => this.setState({email: e.target.value})}
                                      value={this.state.email}/>
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formStreet">
                    <Form.Label>Flat, House no, Building, Company, Apartments*</Form.Label>
                    <Form.Control type="text" placeholder="Flat, House no, Building, Company, Apartments"
                                  onChange={(e) => this.setState({street: e.target.value})}
                                  value={this.state.street}/>
                </Form.Group>
                <Form.Group controlId="formStreet2">
                    <Form.Label>Area, Colony, Street, Sector, Village</Form.Label>
                    <Form.Control type="text" placeholder="Area, Colony, Street, Sector, Village"
                                  onChange={(e) => this.setState({street2: e.target.value})}
                                  value={this.state.street2}/>
                </Form.Group>

                <Form.Row>
                    <Form.Group as={Col} controlId="formState">
                        <Form.Label>State*</Form.Label>
                        <Form.Control type="text" placeholder="State"
                                      onChange={(e) => this.setState({state: e.target.value})}
                                      value={this.state.state}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formCity">
                        <Form.Label>City*</Form.Label>
                        <Form.Control type="text" placeholder="City"
                                      onChange={(e) => this.setState({city: e.target.value})}
                                      value={this.state.city}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formZipcode">
                        <Form.Label>Pincode*</Form.Label>
                        <Form.Control type="number" placeholder="Pincode"
                                      onChange={(e) => this.setState({zipcode: e.target.value})}
                                      value={this.state.zipcode}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formLandmark">
                        <Form.Label>Landmark</Form.Label>
                        <Form.Control type="text" placeholder="e.g. Near AIMS Fliover, near pipal ka ped"
                                      onChange={(e) => this.setState({landmark: e.target.value})}
                                      value={this.state.landmark}/>
                    </Form.Group>
                </Form.Row>

                <div className="mt-2">
                    <Button variant="primary" className={!enableUpdate ? 'disabled' : ''} onClick={this.handleUpdate}>
                        Update Address
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    updateOrderAddress,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(AddressForm);
