import {call, put} from 'redux-saga/effects';
import {

    FETCH_CALLS,
    FETCH_CALLS_RESULT,
    FETCH_CALLS_ERROR,
    FETCH_CALLS_RETRY,

    FETCH_CALL,
    FETCH_CALL_RESULT,
    FETCH_CALL_ERROR,
    FETCH_CALL_RETRY,

    CREATE_CALL,
    CREATE_CALL_RESULT,
    CREATE_CALL_ERROR,
    CREATE_CALL_RETRY,

    UPDATE_CALL,
    UPDATE_CALL_RESULT,
    UPDATE_CALL_ERROR,
    UPDATE_CALL_RETRY,

    FETCH_INCOMING_CALL,
    FETCH_INCOMING_CALL_RESULT,
    FETCH_INCOMING_CALL_RETRY,
    FETCH_INCOMING_CALL_ERROR,

    CREATE_CALL_TRANSFER,
    CREATE_CALL_TRANSFER_RESULT,
    CREATE_CALL_TRANSFER_ERROR,
    CREATE_CALL_TRANSFER_RETRY,
    FETCH_ACTIVE_AGENTS,
    FETCH_ACTIVE_AGENTS_RESULT,
    FETCH_ACTIVE_AGENTS_ERROR,
    FETCH_ACTIVE_AGENTS_RETRY,
} from '../../actions/ecommerce/calls';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchCalls(action) {
    try {
        const response = yield call(api.ecommerce.calls.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchCalls', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CALLS_RESULT, data: result});
        } else {
            yield put({type: FETCH_CALLS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CALLS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchCall(action) {
    try {
        const response = yield call(api.ecommerce.calls.get, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchCall', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CALL_RESULT, data: result});
        } else {
            yield put({type: FETCH_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CALL_RETRY, retry: true, message: e.message});
    }
}

export function* createCall(action) {
    try {
        const response = yield call(api.ecommerce.calls.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createCall', action, result);

        if (response.status === 200) {
            yield put({type: CREATE_CALL_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message);
            if (result.detail)
                toast(result.detail);
            yield put({type: CREATE_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_CALL_RETRY, retry: true, message: e.message});
    }
}

export function* updateCall(action) {
    try {
        const response = yield call(api.ecommerce.calls.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateCall', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_CALL_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message);
            yield put({type: UPDATE_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_CALL_RETRY, retry: true, message: e.message});
    }
}

export function* fetchIncomingCall(action) {
    try {
        const response = yield call(api.ecommerce.calls.incomingCall, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchIncomingCall', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_INCOMING_CALL_RESULT, data: result});
        } else {
            yield put({type: FETCH_INCOMING_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_INCOMING_CALL_RETRY, retry: true, message: e.message});
    }
}

export function* createCallTransfer(action) {
    try {
        const response = yield call(api.ecommerce.calls.callTransfer, action.data);
        const result = yield response.json();
        console.warn('SAGA:createCallTransfer', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_CALL_TRANSFER_RESULT, data: result});
        } else {
            yield put({type: CREATE_CALL_TRANSFER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_CALL_TRANSFER_RETRY, retry: true, message: e.message});
    }
}

export function* fetchActiveAgents(action) {
    try {
        const response = yield call(api.ecommerce.calls.activeAgents, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchActiveAgents', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ACTIVE_AGENTS_RESULT, data: result});
        } else {
            yield put({type: FETCH_ACTIVE_AGENTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ACTIVE_AGENTS_RETRY, retry: true, message: e.message});
    }
}
