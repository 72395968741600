import React from 'react';
import MainNavbar from "../../components/navbars/MainNavbar";
import {SidebarContainer} from "../../components/sidebars/MainSidebar";


export default class Account extends React.Component {

    render() {

        return (
            <SidebarContainer>
                <MainNavbar pageName={'Account'}/>

            </SidebarContainer>
        )
    }
}
