import {
    FETCH_SHIFT,
    FETCH_SHIFT_RESULT,
    FETCH_SHIFT_ERROR,
    FETCH_SHIFT_RETRY,

    UPDATE_SHIFT,
    UPDATE_SHIFT_RESULT,
    UPDATE_SHIFT_ERROR,
    UPDATE_SHIFT_RETRY,

} from '../../actions/ecommerce/shift';


const initialState = {
    detail: {},
    isFetching: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_SHIFT:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_SHIFT_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_SHIFT_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_SHIFT_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case UPDATE_SHIFT:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_SHIFT_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_SHIFT_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_SHIFT_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
