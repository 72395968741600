import React from "react";
import Modal from 'react-bootstrap/Modal';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import SocketIOClient from "socket.io-client";

import {createAnswer, createReport, createReportForQuizTaker, updateAnswer} from "../../../actions/quiz/answers";
import {fieldTypes, getMessages, languageOptions, NODE_SERVER_HOST} from "../../../constants";
import HeightInput from "../../inputs/HeightInput";
import SingleInput from "../../inputs/SingleInput";
import ChoiceInput from "../../inputs/ChoiceInput";
import StarInput from "../../inputs/StarInput";
import RatingInput from "../../inputs/RatingInput";
import './styles.scss';
import {withRouter} from "react-router";
import {getSearchParams} from "../../../constants";


let {HEIGHT, INTEGER, TEXT, FLOAT, DATE, CHOICE, MULTIPLE_CHOICE, STAR, RATING} = fieldTypes;


class QuizModal extends React.Component {

    static propTypes = {
        order_id: PropTypes.number,
        createReport: PropTypes.func,
        field_id: PropTypes.string,
        bot_form_id: PropTypes.string,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
        createAnswer: PropTypes.func,
        updateAnswer: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            answer: {},
            message: undefined,
            language: 'english',
        }
    }

    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999,
    });

    setSocketListeners = () => {
        let {id: userId} = this.props.profile.data;

        if (userId) {
            this.socket.on(`PAGE:${userId}`, this.handlePageSocketData);
            this.socket.on('reconnect', (attemptNumber) => {
                this.setState({connected: true});
                // DropDownHolder.alert('success', 'Internet', 'Connected successfully');
            });
            this.socket.on('disconnect', (reason) => {
                if (reason === 'io server disconnect') {
                    // the disconnection was initiated by the server, you need to reconnect manually
                    this.socket.connect();
                }
                this.setState({connected: false});
                // else the socket will automatically try to reconnect
            });
        }
    };

    handlePageSocketData = (data) => {
        let params = getSearchParams(data.url);
        console.warn('params', params, this.props.report_id, this.props.order_id);

        if (this.props.order_id) {
            this.props.createReport({report_id: params.id, order_id: this.props.order_id});
        }
        if (this.props.report_id) {
            this.props.createReportForQuizTaker({new_report_id: params.id, report_id: this.props.report_id});
        }
    };

    componentDidMount() {
        if (this.props.field_id && this.props.bot_form_id) {
            this.props.createAnswer({field_id: this.props.field_id, bot_form_id: this.props.bot_form_id,});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {answers: {detail, isCreating, isUpdating, isCreatingReport, message, error, retry}} = this.props;

        if (!isUpdating && prevProps.answers.isUpdating && !error && !retry) {
            this.setState({answer: detail});
        }
        if (!isCreating && prevProps.answers.isCreating && !error && !retry) {
            this.setState({answer: detail});
            this.setSocketListeners();
        }
        if (!isCreating && prevProps.answers.isCreating && error && !retry) {
            this.setState({answer: {}, message});
        }
        if (!isUpdating && prevProps.answers.isUpdating && error && !retry) {
            this.setState({answer: {}, message});
        }
        if (!isCreatingReport && prevProps.answers.isCreatingReport && !error && !retry) {
            this.handleOpenQuizReport();
            this.setState({answer: {}, message, reportMessage: 'Report sent to the customer\'s phone number'});
        }
    }

    handleOpenQuizReport = () => {
        const {answers: {report}} = this.props;
        window.open(`https://misters.in/en/quiz/reports/?id=${report.report_id}`);
    };

    renderField = () => {
        const {answer, language} = this.state;
        // console.warn('field type', answer.bot_field.type);
        if (answer.bot_field) {
            switch (answer.bot_field.type) {
                case HEIGHT:
                    return <HeightInput handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case INTEGER:
                case TEXT:
                case FLOAT:
                case DATE:
                    return <SingleInput language={language}
                                        inputType={answer.bot_field.type}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case CHOICE:
                    return <ChoiceInput language={language}
                                        choices={answer.field_response_children}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case MULTIPLE_CHOICE:
                    return <ChoiceInput multipleChoice={true}
                                        language={language}
                                        choices={answer.field_response_children}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                case STAR:
                    return <StarInput hideNextButton={true}
                                      handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;

                case RATING:
                    return <RatingInput hideNextButton={true}
                                        handleSendClick={(value) => this.handleSendClick(answer.id, value)}/>;
                default:
                    return null;
            }
        }
        return null;
    };


    handleSendClick = async (responseId, data) => {
        this.props.updateAnswer({id: responseId, ...data});
    };

    render() {
        let {show, answers: {detail}, handleConfirm, handleClose} = this.props;
        let {answer, language, message, reportMessage} = this.state;

        if (reportMessage) {
            return (
                <Modal show={show} onHide={handleClose} size='xl' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Quiz</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {reportMessage}
                    </Modal.Body>
                </Modal>
            );
        }

        if (!answer.bot_field)
            return null;

        let {[language]: htmlData = detail.bot_field.text} = answer.bot_field.languages;

        return (
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<h5>{!!answer.bot_field && answer.bot_field.text}</h5>*/}
                    <div dangerouslySetInnerHTML={{__html: htmlData}}/>
                    {this.renderField()}

                    {
                        !!message &&
                        <Alert variant={'danger'}>
                            {getMessages(message)}
                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Select options={languageOptions}
                            className="quiz-modal-language-select"
                            value={languageOptions.filter(o => o.value === language)}
                            onChange={(value) => {
                                this.setState({language: value.value,})
                            }}/>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    answers: state.quiz.answers,
    profile: state.accounts.profile,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createAnswer,
    createReport,
    createReportForQuizTaker,
    updateAnswer,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(QuizModal));
