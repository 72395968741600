import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';

import './styles.scss';
import moment from "moment";
import {Button} from "react-bootstrap";
import {copyText, getPrice} from "../../../constants";


export default class OrderDetailHeader extends PureComponent {

    static propTypes = {
        objectId: PropTypes.any,
        objectTitle: PropTypes.string,
        paymentType: PropTypes.string,
        orderDate: PropTypes.string,
        orderTotal: PropTypes.any,
        status: PropTypes.string,
        shippingAddressName: PropTypes.string,
    };

    render() {
        let {
            objectId, objectTitle, paymentType, orderDate, orderTotal, status, orderTotalOnline, autoConfirmationSchedule,
            shippingAddressName, reportId
        } = this.props

        return (
            <div className="order-detail-top-section">
                <div className="d-flex flex-wrap">
                    {!!objectId && <div className="order-detail-text-container" style={{minWidth: '148px'}}>
                        <h5 className="order-detail-text-heading">{objectTitle}</h5>
                        <Button variant="primary" className="p-1 copy-button"
                                size="sm" onClick={() => copyText(objectId)}>Copy</Button>
                        <p className="order-detail-text">{objectId}</p>
                    </div>}

                    {!!paymentType && <div className="order-detail-text-container">
                        <h5 className="order-detail-text-heading">Payment Type</h5>
                        <p className="order-detail-text">{paymentType}</p>
                    </div>}

                    {!!orderDate && <div className="order-detail-text-container">
                        <h5 className="order-detail-text-heading">Order Date</h5>
                        <p className="order-detail-text">
                            {moment(orderDate).format("DD MMMM YYYY, h:mm a")}
                        </p>
                    </div>}

                    {!!status && <div className="order-detail-text-container">
                        <h5 className="order-detail-text-heading">status</h5>
                        <p className="order-detail-text">{status}</p>
                    </div>}

                    {!!orderTotal && <div className="order-detail-text-container">
                        <h5 className="order-detail-text-heading">Order Total</h5>
                        <p className="order-detail-text">₹{orderTotal}</p>
                    </div>}

                    {
                        !!orderTotalOnline &&
                        <div className="order-detail-text-container">
                            <h5 className="order-detail-text-heading">Price if paid online</h5>
                            <p className="order-detail-text">₹{getPrice(orderTotalOnline)}</p>
                        </div>
                    }

                    {
                        !!autoConfirmationSchedule &&
                        <div className="order-detail-text-container">
                            <h5 className="order-detail-text-heading">Auto Confirmation scheduled at</h5>
                            <p className="order-detail-text">{moment(autoConfirmationSchedule).format("MMMM Do YYYY, h:mm A")}</p>
                        </div>
                    }

                    {!!shippingAddressName && <div className="order-detail-text-container" style={{minWidth: '148px'}}>
                        <h5 className="order-detail-text-heading">Name</h5>
                        <p className="order-detail-text">{shippingAddressName}</p>
                    </div>}
                </div>
            </div>
        );
    }
}
