// External Imports
import React from "react";
// My Imports
import {
    Home,
    Login,
    Logout,
    Orders,
    OrderDetail,
    Account,
    Calls,
    CallDetail,
    ScheduledCalls,
    RepeatOrders,
    Quizzers,
    QuizzerDetail,
    Customers,
    CustomerDetail,
    Clinics,
} from './screens';

import MissedCalls from "./screens/MissedCalls";


export default [
    {
        exact: true,
        path: '/',
        component: Orders,
    },
    {
        exact: true,
        path: '/customers',
        component: Customers,
    },
    {
        exact: true,
        path: '/customers/:customerId',
        component: CustomerDetail,
    },
    {
        exact: true,
        path: '/quizzers',
        component: Quizzers,
    },
    {
        exact: true,
        path: '/quizzers/:reportId',
        component: QuizzerDetail,
    },
    {
        exact: true,
        path: '/orders/repeat',
        component: RepeatOrders,
    },
    {
        exact: true,
        path: '/orders/:orderId',
        component: OrderDetail,
    },
    {
        exact: true,
        path: '/calls',
        component: Calls,
    },
    {
        exact: true,
        path: '/missed-calls',
        component: MissedCalls,
    },
    {
        exact: true,
        path: '/calls/:callSid',
        component: CallDetail,
    },
    {
        exact: true,
        path: '/scheduled-calls',
        component: ScheduledCalls,
    },
    {
        exact: true,
        path: '/clinics',
        component: Clinics,
    },
    {
        exact: true,
        path: '/account',
        component: Account,
    },
    {
        exact: true,
        path: '/login',
        component: Login,
    },
    {
        exact: true,
        path: '/logout',
        component: Logout,
    },
];
