const COMMON_ATTRIBUTES = {
    chief_complaint: {label: 'Chief complaint', options: ['ed', 'pme', 'desire', 'size_increase', 'fertility', 'energy', 'others']},
    reason_for_order_not_placed: {
        label: 'Reason for order not placed',
        options: ['too_costly', 'will_call_back', 'not_interested', 'unrelated_problem', 'ordered_for_a_friend', 'order_placed']
    },
}


export const CREATE_ORDER = {
    ...COMMON_ATTRIBUTES,
    did_not_order_or_asked_to_cancel: {label: 'Did not order/Asked to cancel'},
    order_modified: {label: 'Order Modified'},
    took_ayurvedic_quiz: {label: 'Took Ayurvedic Quiz'},
    online_conversion_link_sent: {label: 'Online conversion link sent'},
    address_modified: {label: 'Address Modified'},
}

export const PENDING_ORDER = {
    ...COMMON_ATTRIBUTES,
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    wants_to_talk_to_doctor: {label: 'Wants to talk to doctor',},
}

export const RETURNED_ORDER = {
    ...COMMON_ATTRIBUTES,
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    wants_to_talk_to_doctor: {label: 'Wants to talk to doctor',},

    did_not_get_delivery: {label: 'Did not get delivery'},
    was_out_of_station: {label: 'Was out of station'},
    dont_remember_no_reason_specified: {label: 'Dont remember / no reason specified'},
    ordered_for_a_friend: {label: 'Ordered for a friend'},
    product_quality_effectiveness_issues: {label: 'Not convinced about product quality/effectiveness'},
}

export const DELIVERED_ORDER = {
    ...COMMON_ATTRIBUTES,
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    product_feedback: {label: 'Product Feedback', options: ['happy', 'nuetral', 'unsatisfied']},
    usage: {label: 'Usage', options: ['not_used', 'discontinued', 'using', 'completed_course']},
    reported_side_effects: {label: 'Reported side effects'}
}

export const SHIPPED_ORDER = {
    ...COMMON_ATTRIBUTES,
    rescheduled: {label: 'Rescheduled'},
    fake_comment: {label: 'Fake comment'},
    rto: {label: 'RTO'},
    could_not_complete_conversation: {label: 'Could not complete conversation'},
    ordered_by_mistake: {label: 'Wants to cancel / Ordered by mistake / for a friend who does not want it'},
    others: {label: 'Others'},
}

export const QUIZ_CALL = {
    ...COMMON_ATTRIBUTES,
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    wanted_to_reschedule: {label: 'Wanted to reschedule consultation'},
    consultation_with_doctor_completed: {label: 'Consultation with Doctor completed'},
    ayurvedic_quiz_taken: {label: 'Ayurvedic quiz taken'},
};

export const MANUAL_CALL = {
    ...COMMON_ATTRIBUTES,
    call_source: {label: 'Call source', options: ['google', 'facebook', 'pamphlet', 'newspaper', 'reference', 'others']},
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    conversation_about: {label: 'Conversation was about', options: ['order_delivery_related', 'health_query', 'wanted_to_place_order', 'other']},
    reason_for_calling: {
        label: 'Reason for calling',
        options: [
            'wanted_to_talk_to_doctor', 'order_delivery_issue', 'payment_issue',
            'wanted_to_cancel_order', 'product_complaint', 'other',
        ]
    },
}

export const INCOMING_CALL = {
    ...COMMON_ATTRIBUTES,
    call_source: {label: 'Call source', options: ['google', 'facebook', 'pamphlet', 'newspaper', 'reference', 'others']},
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    conversation_about: {label: 'Conversation was about', options: ['order_delivery_related', 'health_query', 'wanted_to_place_order', 'other']},
    reason_for_calling: {
        label: 'Reason for calling',
        options: [
            'wanted_to_talk_to_doctor', 'order_delivery_issue', 'payment_issue',
            'wanted_to_cancel_order', 'product_complaint', 'other',
        ]
    },
    wants_to_talk_to_doctor: {label: 'Wants to talk to doctor',},
}

export const INCOMING_CALL_DOCTOR = {
    ...COMMON_ATTRIBUTES,
    call_source: {label: 'Call source', options: ['google', 'facebook', 'pamphlet', 'newspaper', 'reference', 'others']},
    lead_quality: {label: 'Lead quality', options: ['hot', 'neutral', 'not_interested']},
    conversation_about: {label: 'Conversation was about', options: ['order_delivery_related', 'health_query', 'wanted_to_place_order', 'other']},
    reason_for_calling: {
        label: 'Reason for calling',
        options: [
            'wanted_to_talk_to_doctor', 'order_delivery_issue', 'payment_issue',
            'wanted_to_cancel_order', 'product_complaint', 'other',
        ]
    },
}

export const callTypeOptions = {
    manual_call: {label: 'MANUAL CALL', value: 'manual_call', data: MANUAL_CALL},
    create_order: {label: 'CREATE ORDER', value: 'create_order', data: CREATE_ORDER},
    pending_order: {label: 'PENDING ORDER', value: 'pending_order', data: PENDING_ORDER},
    canceled_order: {label: 'CANCELED ORDER', value: 'canceled_order', data: MANUAL_CALL},
    returned_order: {label: 'RETURNED ORDER', value: 'returned_order', data: RETURNED_ORDER},
    delivered_order: {label: 'DELIVERED ORDER', value: 'delivered_order', data: DELIVERED_ORDER},
    shipped_order: {label: 'SHIPPED ORDER', value: 'shipped_order', data: SHIPPED_ORDER},
    quiz_call: {label: 'QUIZ CALL', value: 'quiz_call', data: QUIZ_CALL},
    incoming_call: {label: 'Incoming Call', value: 'incoming_call', data: INCOMING_CALL},
    incoming_call_doctor: {label: 'Incoming Call Doctor', value: 'incoming_call_doctor', data: INCOMING_CALL_DOCTOR},
    missed_call: {label: 'Missed Call', value: 'missed_call', data: {}},
}

export const allowedOutgoingCallTypes = {
    manual_call: {label: 'MANUAL CALL', value: 'manual_call', data: MANUAL_CALL},
    create_order: {label: 'CREATE ORDER', value: 'create_order', data: CREATE_ORDER},
    pending_order: {label: 'PENDING ORDER', value: 'pending_order', data: PENDING_ORDER},
    canceled_order: {label: 'CANCELED ORDER', value: 'canceled_order', data: MANUAL_CALL},
    returned_order: {label: 'RETURNED ORDER', value: 'returned_order', data: RETURNED_ORDER},
    delivered_order: {label: 'DELIVERED ORDER', value: 'delivered_order', data: DELIVERED_ORDER},
    shipped_order: {label: 'SHIPPED ORDER', value: 'shipped_order', data: SHIPPED_ORDER},
    quiz_call: {label: 'QUIZ CALL', value: 'quiz_call', data: QUIZ_CALL},
};


export const allowedCallTransferCallTypes = {
    incoming_call: {label: 'Incoming Call', value: 'incoming_call', data: INCOMING_CALL},
    incoming_call_doctor: {label: 'Incoming Call Doctor', value: 'incoming_call_doctor', data: INCOMING_CALL_DOCTOR},
};

export const callStatuses = [
    {label: 'Completed', value: 'completed'},
    {label: 'Failed', value: 'failed'},
    {label: 'No Answer', value: 'no-answer'},
    {label: 'In Progress', value: 'in-progress'},
    {label: 'Busy', value: 'busy'},
]
