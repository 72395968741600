import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SidebarHeader from './SidebarHeader';
import './styles.scss';
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchShift, updateShift} from "../../../actions/ecommerce/shift";
import moment from "moment";


class MainSidebar extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
        fetchShift: PropTypes.func,
        updateShift: PropTypes.func,
    };

    static defaultProps = {
        active: false,
    };

    componentDidMount() {
        this.props.fetchShift();
    }

    handleToggleShiftActive = () => {
        this.props.updateShift();
    }

    handleLogoutClick = () => {
        this.props.history.push('/logout');
    };

    render() {
        let {shift: {detail: shiftDetail, isUpdating}} = this.props;
        const path = this.props.history.location.pathname;
        // let user = localStorage.getItem('user');
        // try {user = JSON.parse(user);}
        // catch (e) {user = {};}
        // console.warn(user);

        return (
            <div className={'sidebar ' + (this.props.active ? 'active' : '')}>
                <i onClick={this.props.toggleSidebar}
                   className={'material-icons sidebar-toggle ' + (this.props.active ? 'active' : '')}>keyboard_arrow_right</i>

                <ul className='sidebar-items'>
                    <SidebarHeader type={'Misters'}/>

                    <SidebarItem icon='list_alt'
                                 type='Customers'
                                 active={path === '/customers'}
                                 onClick={() => this.props.history.push('/customers')}/>

                    <SidebarItem icon='format_list_bulleted'
                                 type='Orders'
                                 active={path === '/'}
                                 onClick={() => this.props.history.push('/')}/>

                    <SidebarItem icon='repeat'
                                 type='Repeat orders'
                                 active={path === '/orders/repeat'}
                                 onClick={() => this.props.history.push('/orders/repeat')}/>

                    <SidebarItem icon='phone_in_talk'
                                 type='Scheduled calls'
                                 active={path === '/scheduled-calls'}
                                 onClick={() => this.props.history.push('/scheduled-calls')}/>

                    <SidebarItem icon='phone_in_talk'
                                 type='Calls'
                                 active={path === '/calls'}
                                 onClick={() => this.props.history.push('/calls')}/>

                    <SidebarItem icon='phone_in_talk'
                                 type='Missed Calls'
                                 active={path === '/missed-calls'}
                                 onClick={() => this.props.history.push('/missed-calls')}/>

                    <SidebarItem icon='list_alt'
                                 type='Quiz Takers'
                                 active={path === '/quizzers'}
                                 onClick={() => this.props.history.push('/quizzers')}/>

                    <SidebarItem icon='list_alt'
                                 type='Clinics'
                                 active={path === '/clinics'}
                                 onClick={() => this.props.history.push('/clinics')}/>

                    <SidebarItem icon='lock'
                                 type='Logout'
                                 active={path === '/logout'}
                                 onClick={this.handleLogoutClick}/>

                    {
                        !shiftDetail.is_active &&
                        <div className="shift-inactive-mask">
                            <p>Away from desk. <br/>Please click activate to start your shift.</p>
                            <button className="btn btn-primary"
                                    onClick={this.handleToggleShiftActive}
                                    disabled={isUpdating}>
                                {(!isUpdating && shiftDetail.is_active) ? 'Deactivate': 'Activate'}
                                {isUpdating && 'Updating'}
                            </button>
                        </div>
                    }

                    {
                        this.props.active &&
                        <div className="sidebar-shift-container">
                            <h6>Shift details</h6>
                            {
                                !!shiftDetail.shifts &&
                                shiftDetail.shifts.map(
                                    (shift) => (
                                        <table key={shift.id}>
                                            <tbody className="table">
                                            <tr>
                                                <th>Start time</th>
                                                <td>{shift.start_time}</td>
                                            </tr>
                                            <tr>
                                                <th>End time</th>
                                                <td>{shift.end_time}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}><strong>Days: </strong>
                                                    {shift.days.map(day => <span key={day}>{day}, </span>)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )
                                )
                            }
                            <table>
                                <tbody className="table">
                                <tr>
                                    <th>Is Shift <br/> Active</th>
                                    <td>{shiftDetail.is_active_shift ? 'Yes': 'No'}</td>
                                </tr>
                                <tr>
                                    <th>Is Active</th>
                                    <td>
                                        {shiftDetail.is_active ? 'Yes': 'No'} <br/>
                                        <button className="btn btn-primary"
                                                onClick={this.handleToggleShiftActive}
                                                disabled={isUpdating}>
                                            {(!isUpdating && shiftDetail.is_active) ? 'Deactivate': 'Activate'}
                                            {isUpdating && 'Updating'}
                                        </button>

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }

                </ul>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    shift: state.ecommerce.shift,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchShift,
    updateShift,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(MainSidebar));
