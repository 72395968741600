import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {cancelReason} from "../../../constants";


export default class OrderCancel extends React.Component {

    static propTypes = {
        order: PropTypes.any,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleCancel: PropTypes.func,
    };

    state = {
        orderId: '',
        cancel_reason: '',
        cancel_reason_select: '',
        showText: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.show && prevProps.show) {
            this.setState({
                orderId: '',
                cancel_reason: '',
                cancel_reason_select: '',
                showText: false,
            });
        }
    }

    handleCancelReasonChange = (reason) => {
        if (reason === 'other') {
            this.setState({showText: true, cancel_reason: '', cancel_reason_select: reason});
        } else {
            this.setState({cancel_reason_select: reason, showText: false, cancel_reason: ''});
        }
    };

    handleCancelClick = () => {
        let {order, handleCancel} = this.props;
        let {cancel_reason, cancel_reason_select, showText} = this.state;

        if (showText) {
            handleCancel({id: order.id, message: cancel_reason})
        } else {
            handleCancel({id: order.id, message: cancel_reason_select})
        }
    };

    render() {
        let {order, show, handleClose} = this.props;
        let {cancel_reason, cancel_reason_select, showText} = this.state;
        if (!order)
            order = {};

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>
                        Are You Sure You want to cancel this Order?</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {!!order.checkout && !!order.checkout.razorpay_order_id &&
                    <h3 className="text-danger text-center">This is an online order</h3>}

                    <p>If yes then type the order ID - {order.cid} below and press Cancel Order</p>
                    <Form.Control size="md" type="text" placeholder="Order ID"
                                  value={this.state.orderId}
                                  onChange={(e) => this.setState({orderId: e.target.value})}/>

                    <Form.Group controlId="cancel_reason_select">
                        <Form.Label>Select Cancel reason</Form.Label>
                        <Select options={cancelReason.map(reason => ({label: reason.label, value: reason.value}))}
                                value={cancelReason.filter(reason => reason.value === cancel_reason_select).map(reason => ({
                                    label: reason.label,
                                    value: reason.value
                                }))}
                                onChange={(reason) => this.handleCancelReasonChange(reason.value)}/>
                    </Form.Group>
                    {showText && <Form.Group controlId="cancel_reason">
                        <Form.Label>Other Cancel reason</Form.Label>
                        <Form.Control as="textarea" rows="3" value={cancel_reason}
                                      onChange={(e) => this.setState({cancel_reason: e.target.value})}/>
                    </Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={() => this.handleCancelClick()}
                            disabled={!(order.cid === this.state.orderId)}>
                        Cancel Order
                    </Button>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
