import {call, put} from 'redux-saga/effects';
import {
    FETCH_PHARMACY_LIST,
    FETCH_PHARMACY_LIST_RESULT,
    FETCH_PHARMACY_LIST_ERROR,
    FETCH_PHARMACY_LIST_RETRY,
} from '../../actions/ecommerce/pharmacy';
import api from "../api";


export function* fetchPharmacyList(action) {
    try {
        const response = yield call(api.ecommerce.pharmacy.list);
        const result = yield response.json();
        console.warn('SAGA:fetchPharmacyList', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PHARMACY_LIST_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PHARMACY_LIST_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PHARMACY_LIST_RETRY, retry: true, message: e.message});
    }
}
