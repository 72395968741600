import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import {getPhoneNumberWithCountryCode} from "../../../constants";


export default class CreateCallModal extends React.Component {

    static propTypes = {
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleMakeCall: PropTypes.func,
    };

    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.show && prevProps.show) {
            this.setState({phone_number: ''});
        }
    }

    handleMakeCall = () => {
        let {handleMakeCall} = this.props;
        let {phone_number} = this.state;
        phone_number = getPhoneNumberWithCountryCode(phone_number);
        handleMakeCall({phone_number, call_type: 'manual_call'});
    }

    render() {
        let {show, handleClose} = this.props;
        let {phone_number} = this.state;


        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Make a call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="tel" placeholder="Phone Number"
                                  value={phone_number || ''} maxLength={10}
                                  onChange={(e) => this.setState({phone_number: e.target.value})}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                    <Button variant="success" onClick={this.handleMakeCall}>Call</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}