import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {
    cancelOrder,
    confirmOrder,
    convertToOnline,
    fetchOrderInfo,
    refundOrder,
    resendPaymentLink,
    sendAyurvedicQuizLink,
} from "../../actions/ecommerce/orders";
import {createCall} from "../../actions/ecommerce/calls";
import {
    AYURVEDIC_QUIZ_BOT_FORM_ID,
    AYURVEDIC_QUIZ_FIELD_ID, copyText,
    orderCallTypeFromStatus,
} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import CallDetailForm from "../../components/forms/CallDetailForm";
import OrderDetailHeader from "../../components/others/OrderDetailHeader";
import AddressForm from "../../components/forms/AddressForm";
import AddressAndItemList from "../../components/others/AddressAndItemList";
import OrderDetailButtons from "../../components/others/OrderDetailButtons";
import CallHistory from "../../components/others/CallHistory";
import OrderHistoryTable from "../../components/others/OrderHistoryTable";
import ScheduleCallModal from "../../components/modals/ScheduleCallModal";
import OrderConfirm from "../../components/modals/OrderConfirm/OrderConfirm";
import OrderCancel from "../../components/modals/OrderCancel";
import CallDetailModal from "../../components/modals/CallDetailModal";
import OrderStatusModal from "../../components/modals/OrderStatusModal";
import OrderCreate from "../../components/modals/OrderCreate";
import CouponModal from "../../components/modals/CouponModal";
import QuizModal from "../../components/modals/QuizModal";
import {Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";


class OrderDetail extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        fetchOrderInfo: PropTypes.func,
        confirmOrder: PropTypes.func,
        cancelOrder: PropTypes.func,
        createCall: PropTypes.func,
        refundOrder: PropTypes.func,
        convertToOnline: PropTypes.func,
        resendPaymentLink: PropTypes.func,
        sendAyurvedicQuizLink: PropTypes.func,
    };

    state = {
        showConfirm: false,
        showCancel: false,
        showStatuses: false,
        showCreate: false,
        showCheckout: false,
        order: null,
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.props.fetchOrderInfo({id: params.orderId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            detail, isFetching, isUpdatingAddress, isConfirming, isRefunding, isCreating,
            isFetchingConvertToOnline, isFetchingResendPaymentLink, isSendingAyurvedicQuizLink, isSendingCheckoutLink, isCanceling, error, retry
        } = this.props.orders;
        let {calls, match: {params}} = this.props;

        if (params.orderId !== prevProps.match.params.orderId) {
            this.props.fetchOrderInfo({id: params.orderId});
        }

        if (!isCreating && prevProps.orders.isCreating && !error && !retry) {
            toast("Order created");
            this.handleOrderUpdates();
        }

        if (!isConfirming && prevProps.orders.isConfirming && !error && !retry) {
            toast("Order confirmed",);
            this.setState({showConfirm: false, order: null});
            this.handleOrderUpdates();
        }

        if (!isCanceling && prevProps.orders.isCanceling && !error && !retry) {
            toast("Order cancelled");
            this.setState({showCancel: false, showConfirm: false, order: null});
            this.handleOrderUpdates();
        }

        if (!isRefunding && prevProps.orders.isRefunding && !error && !retry) {
            toast("Order refund initiated");
            this.handleOrderUpdates();
        }

        if (!calls.isCreating && prevProps.calls.isCreating && !calls.error && !calls.retry) {
            toast("Call Created");
            this.handleOrderUpdates();
        }

        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            toast("Call Detail Updated");
            this.handleOrderUpdates();
        }

        if (!isUpdatingAddress && prevProps.orders.isUpdatingAddress && !error && !retry) {
            toast("Address Updated");
            this.handleOrderUpdates();
        }

        if (!isFetchingConvertToOnline && prevProps.orders.isFetchingConvertToOnline && !error && !retry) {
            toast("Converted to online");
            this.handleOrderUpdates();
        }

        if (!isFetchingResendPaymentLink && prevProps.orders.isFetchingResendPaymentLink && !error && !retry) {
            toast("Resend payment link");
            this.handleOrderUpdates();
        }

        if (!isSendingAyurvedicQuizLink && prevProps.orders.isSendingAyurvedicQuizLink && !error && !retry) {
            toast("Ayurvedic quiz link successfully sent");
            this.handleOrderUpdates();
        }

        if (!isSendingCheckoutLink && prevProps.orders.isSendingCheckoutLink && !error && !retry) {
            toast("Checkout link successfully sent");
            this.setState({showCheckout: false})
            this.handleOrderUpdates();
        }
    }

    handleOrderUpdates = () => {
        let {params} = this.props.match;
        this.props.fetchOrderInfo({id: params.orderId});
    };

    handleCancel = ({id, message}) => {
        this.props.cancelOrder({id, message});
    };

    handleConfirm = (order) => {
        this.props.confirmOrder(order);
    };

    handleMakeCall = (order) => {
        let {orders: {detail}} = this.props;
        this.props.createCall({order_id: order.id, call_type: orderCallTypeFromStatus(detail.status)});
    };

    handleRefund = () => {
        let {orders: {detail}} = this.props;
        this.props.refundOrder({id: detail.id});
    };

    handleConvertToOnline = () => {
        let {orders: {detail}} = this.props;
        this.props.convertToOnline({id: detail.id});
    };

    handleResendPaymentLink = () => {
        let {orders: {detail}} = this.props;
        this.props.resendPaymentLink({id: detail.id});
    };

    handleSendAyurvedicQuizLink = () => {
        let {orders: {detail}} = this.props;
        this.props.sendAyurvedicQuizLink({order_id: detail.id});
    };

    handleTrackOrder = () => {
        let {orders: {detail}} = this.props;
        window.open(`http://www.pickrr.com/tracking/#?tracking_id=${detail.tracking_id}`)
    };

    render() {
        let {orders: {detail, detail: {shipping_address = {}, order_items = [],
            support_calls = [], support_calls_history = [], orders_history = [], order_statuses = []}}} = this.props;

        let showRefundButton = detail.is_eligible_for_refund;
        let showConfirmButton = detail.status === 'created';
        let showCancelButton = detail.status === 'created';

        return (
            <SidebarContainer>
                <OrderCancel show={this.state.showCancel}
                             order={this.state.order}
                             handleClose={() => this.setState({showCancel: false})}
                             handleCancel={this.handleCancel}/>

                <OrderConfirm show={this.state.showConfirm} order={this.state.order}
                              handleConfirm={this.handleConfirm}
                              handleClose={() => this.setState({showConfirm: false})}/>

                <OrderCreate show={this.state.showCreate} orderId={detail.id} address={shipping_address}
                             handleClose={() => this.setState({showCreate: false})}/>

                <CouponModal show={this.state.showCheckout} orderId={detail.id}
                             handleClose={() => this.setState({showCheckout: false})}/>

                <OrderStatusModal show={this.state.showStatuses} orderStatus={order_statuses}
                                  handleClose={() => this.setState({showStatuses: false})}/>
                {
                    !!this.state.callDetail &&
                    <CallDetailModal show={true} callDetail={this.state.callDetail}
                                     handleClose={() => this.setState({callDetail: undefined})}/>
                }
                {
                    !!this.state.scheduledCall &&
                    <ScheduleCallModal show={true} data={this.state.scheduledCall}
                                       handleClose={() => this.setState({scheduledCall: undefined})}/>
                }
                {
                    !!this.state.showAyurvedicQuiz &&
                    <QuizModal show={true}
                               field_id={AYURVEDIC_QUIZ_FIELD_ID}
                               bot_form_id={AYURVEDIC_QUIZ_BOT_FORM_ID}
                               order_id={detail.id}
                               handleClose={() => this.setState({showAyurvedicQuiz: undefined})}/>
                }
                <div className="container-fluid pt-5 position-relative">
                    <span className="material-icons back-button"
                          onClick={() => this.props.history.goBack()}>keyboard_backspace</span>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <OrderDetailHeader objectId={detail.cid}
                                               objectTitle={'Order CID'}
                                               status={detail.status}
                                               paymentType={detail.payment_type} orderTotal={detail.total}
                                               orderDate={detail.created_at}
                                               autoConfirmationSchedule={detail.auto_confirmation_schedule}
                                               orderTotalOnline={detail.price_if_paid_online}/>
                            <div className="order-detail-text-container" style={{minWidth: '148px'}}>
                                <h5 className="order-detail-text-heading">Customer ID</h5>
                                <Button variant="primary" className="p-1 copy-button"
                                        size="sm" onClick={() => copyText(detail.user)}>Copy</Button>
                                <NavLink className="order-detail-text" to={`/customers/${detail.user}`}>
                                    {detail.user}
                                </NavLink>
                            </div>
                            <div className="order-detail-text-container" style={{minWidth: '250px'}}>
                                <h5 className="order-detail-text-heading">Customer ID (address)</h5>
                                <Button variant="primary" className="p-1 copy-button"
                                        size="sm" onClick={() => copyText(detail.address_customer_id)}>Copy</Button>
                                <NavLink className="order-detail-text" to={`/customers/${detail.address_customer_id}`}>
                                    {detail.address_customer_id}
                                </NavLink>
                            </div>
                            {
                                !!detail.checkout && !!detail.checkout.payment_link &&
                                <div className="order-detail-text-container" style={{minWidth: '250px'}}>
                                    <h5 className="order-detail-text-heading">Payment Link</h5>
                                    <Button variant="primary" className="p-1 copy-button"
                                            size="sm" onClick={() => copyText(`Misters.in has requested a payment of ${detail.total} for order id ${detail.cid}. Use this link: ${detail.checkout.payment_link} to complete the payment.`)}>Copy</Button>
                                    {detail.checkout.payment_link}
                                </div>
                            }
                        </div>
                        <div className="col-12 col-md-5">
                            <OrderDetailButtons showCall={!!detail.id} showCancel={showCancelButton}
                                                showConfirm={showConfirmButton} showRefund={showRefundButton}
                                                showConvertToOnline={detail.payment_type === 'cod'}
                                                showResendPaymentLink={detail.payment_type === 'online' && !detail.is_payment_captured}
                                                showTrackOrder={!!detail.tracking_id}
                                                onClickCallOrder={() => this.handleMakeCall(detail)}
                                                onClickConfirmOrder={() => this.setState({
                                                    order: detail,
                                                    showConfirm: true
                                                })}
                                                onClickCancelOrder={() => this.setState({
                                                    order: detail,
                                                    showCancel: true
                                                })}
                                                onClickDetailedTrack={() => this.setState({showStatuses: true})}
                                                onClickRefund={this.handleRefund}
                                                onClickConvertToOnline={this.handleConvertToOnline}
                                                onClickResendPaymentLink={this.handleResendPaymentLink}
                                                onClickScheduledCall={() => this.setState({scheduledCall: {order: detail.id}})}
                                                onClickTrackOrder={this.handleTrackOrder}
                                                onClickCreateOrder={() => this.setState({showCreate: true})}
                                                onClickAyurvedicLink={this.handleSendAyurvedicQuizLink}
                                                onClickCheckoutLink={() => this.setState({showCheckout: true})}
                                                onClickAyurvedicQuiz={() => {
                                                    console.warn('show modal');
                                                    this.setState({showAyurvedicQuiz: true})
                                                }}
                            />
                        </div>
                    </div>
                    <div className="row pt-3 px-2">
                        <div className="col-12 col-md-6 pr-0">
                            <div className="row">
                                <div className="col-12">
                                    <AddressAndItemList orderItems={order_items} shippingAddress={shipping_address}/>
                                </div>

                                <div className="col-12 p-1 pr-3 pr-md-5 pt-3">
                                    {
                                        showConfirmButton &&
                                        <AddressForm orderId={detail.id} shippingAddress={shipping_address}/>
                                    }
                                </div>

                                <div className="col-12 p-1 pr-md-5 pt-4">
                                    <OrderHistoryTable orderHistory={orders_history}/>
                                </div>
                                <div className="col-12 p-1 pr-md-5 pt-4">
                                    <h5 className="heading">Delivery attempts</h5>
                                    <Table className="order-list" responsive>
                                        <thead>
                                        <tr>
                                            <th>tracking_id</th>
                                            <th>status</th>
                                            <th>status_timestamp</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !!detail.delivery_attempts &&
                                            detail.delivery_attempts.map(({id, tracking_id, status, status_timestamp}) => (
                                                    <tr key={id}>
                                                        <td className='order-cid'>#{tracking_id}</td>
                                                        <td className="order-status">{status}</td>
                                                        <td className="order-status">{moment(status_timestamp).format('DD-MM-YYYY hh:mm a')}</td>
                                                    </tr>
                                                )
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 px-0">
                            <CallDetailForm callData={detail.ongoing_call}/>
                            <CallHistory supportCalls={support_calls}
                                         title={'Order call history'}
                                         onClickView={(call) => this.setState({callDetail: call, order: null})}/>
                            <CallHistory supportCalls={support_calls_history}
                                         title={'Customer call history'}
                                         onClickView={(call) => this.setState({callDetail: call, order: null})}/>
                        </div>

                    </div>

                    <div className="row">
                        {!!detail.checkout && !!detail.checkout.payments.length &&
                        <div className="col-12 pt-3">
                            <h5 className="heading">Payments</h5>

                            <Table className="order-list">
                                <thead>
                                <tr>
                                    <th>Payment id</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Fee</th>
                                    <th>Method</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    detail.checkout.payments.map((payment) => (
                                            <tr key={payment.payment_id}>
                                                <td className='order-cid'>#{payment.payment_id}</td>
                                                <td className="order-status">{payment.amount}</td>
                                                <td className="order-status">{payment.status}</td>
                                                <td className="order-status">{payment.fee}</td>
                                                <td>{payment.method}</td>
                                                <td className='order-date'>
                                                    {moment(payment.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>}
                        {!!detail.checkout && !!detail.checkout.refunds.length &&
                        <div className="col-12 pt-3">
                            <h5 className="heading">Refunds</h5>
                            <Table className="order-list">
                                <thead>
                                <tr>
                                    <th>refund_id</th>
                                    <th>payment_id</th>
                                    <th>amount</th>
                                    <th>status</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    detail.checkout.refunds.map((refund) => (
                                            <tr key={refund.refund_id}>
                                                <td className='order-cid'>#{refund.refund_id}</td>
                                                <td className="order-status">{refund.payment_id}</td>
                                                <td className="order-status">{refund.amount}</td>
                                                <td className="order-status">{refund.status}</td>
                                                <td className='order-date'>
                                                    {moment(refund.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>}

                        {!!detail.checkout && !!detail.checkout.transfers.length &&
                        <div className="col-12 pt-3">
                            <h5 className="heading">Transfers</h5>
                            <Table className="order-list">
                                <thead>
                                <tr>
                                    <th>transfer_id</th>
                                    <th>recipient</th>
                                    <th>amount</th>
                                    <th>amount_reversed</th>
                                    <th>fees</th>
                                    <th>recipient_settlement_id</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    detail.checkout.transfers.map((transfer) => (
                                            <tr key={transfer.transfer_id}>
                                                <td className='order-cid'>#{transfer.transfer_id}</td>
                                                <td className="order-status">{transfer.recipient}</td>
                                                <td className="order-status">{transfer.amount}</td>
                                                <td className="order-status">{transfer.amount_reversed}</td>
                                                <td className="order-status">{transfer.fees}</td>
                                                <td className="order-status">{transfer.recipient_settlement_id}</td>
                                                <td className='order-date'>
                                                    {moment(transfer.created_at).format("ddd, MMMM Do YYYY, h:mm:ss a")}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>}
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.ecommerce.orders,
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchOrderInfo,
    confirmOrder,
    cancelOrder,
    createCall,
    refundOrder,
    convertToOnline,
    resendPaymentLink,
    sendAyurvedicQuizLink,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderDetail);
