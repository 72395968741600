import React, {PureComponent} from 'react';
import * as PropTypes from 'prop-types';

import './styles.scss';
import Table from "react-bootstrap/Table";
import {getPrice} from "../../../constants";


export default class AddressAndItemList extends PureComponent {

    static propTypes = {
        shippingAddress: PropTypes.object,
        orderItems: PropTypes.array,
    };

    render() {

        let {shippingAddress = {}, orderItems = []} = this.props

        return (
            <div className="row ">
                <div className="col-12 col-md-5 p-0 address-container">
                    <h5 className="address-heading">Address</h5>
                    <p className="address">
                        {!!shippingAddress.name &&
                        <React.Fragment>
                            {shippingAddress.name} <br/>
                        </React.Fragment>}

                        {!!shippingAddress.street &&
                        <React.Fragment>
                            {shippingAddress.street} <br/>
                        </React.Fragment>}

                        {!!shippingAddress.street2 &&
                        <React.Fragment>
                            {shippingAddress.street2} <br/>
                        </React.Fragment>}
                        {!!shippingAddress.landmark &&
                        <React.Fragment>
                            {shippingAddress.landmark} <br/>
                        </React.Fragment>}

                        {!!shippingAddress.city ? shippingAddress.city + ", " : ''}
                        {!!shippingAddress.state ? shippingAddress.state + ", " : ''}
                        {shippingAddress.zipcode}
                    </p>
                </div>

                <div className="col-12 col-md-7 p-0 px-1 pr-md-3">
                    <h5 className="heading">Order Items</h5>
                    <Table className="order-list" responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>₹​{getPrice(item.total_price)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}
