import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMERS_RESULT,
    FETCH_CUSTOMERS_ERROR,
    FETCH_CUSTOMERS_RETRY,

    FETCH_CUSTOMER,
    FETCH_CUSTOMER_RESULT,
    FETCH_CUSTOMER_ERROR,
    FETCH_CUSTOMER_RETRY,
} from '../../actions/ecommerce/customers';


const initialState = {
    detail: {},
    list: {},
    isFetching: false,
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_CUSTOMERS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_CUSTOMERS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_CUSTOMERS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_CUSTOMER:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_CUSTOMER_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_CUSTOMER_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_CUSTOMER_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
