export const CREATE_ANSWER = 'CREATE_ANSWER';
export const CREATE_ANSWER_RESULT = 'CREATE_ANSWER_RESULT';
export const CREATE_ANSWER_ERROR = 'CREATE_ANSWER_ERROR';
export const CREATE_ANSWER_RETRY = 'CREATE_ANSWER_RETRY';

export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ANSWER_RESULT = 'UPDATE_ANSWER_RESULT';
export const UPDATE_ANSWER_ERROR = 'UPDATE_ANSWER_ERROR';
export const UPDATE_ANSWER_RETRY = 'UPDATE_ANSWER_RETRY';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_RESULT = 'CREATE_REPORT_RESULT';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const CREATE_REPORT_RETRY = 'CREATE_REPORT_RETRY';

export const CREATE_REPORT_FOR_QUIZ_TAKER = 'CREATE_REPORT_FOR_QUIZ_TAKER';
export const CREATE_REPORT_FOR_QUIZ_TAKER_RESULT = 'CREATE_REPORT_FOR_QUIZ_TAKER_RESULT';
export const CREATE_REPORT_FOR_QUIZ_TAKER_ERROR = 'CREATE_REPORT_FOR_QUIZ_TAKER_ERROR';
export const CREATE_REPORT_FOR_QUIZ_TAKER_RETRY = 'CREATE_REPORT_FOR_QUIZ_TAKER_RETRY';


export const createAnswer = (data) => ({
    type: CREATE_ANSWER,
    data,
});

export const updateAnswer = (data) => ({
    type: UPDATE_ANSWER,
    data,
});

export const createReport = (data) => ({
    type: CREATE_REPORT,
    data,
});

export const createReportForQuizTaker = (data) => ({
    type: CREATE_REPORT_FOR_QUIZ_TAKER,
    data,
});
