/* global require */
import * as queryString from "query-string";
import moment from "moment";
import {toast} from "react-toastify";
import logo from "./assets/images/logo.svg"

export const DEBUG = process.env.REACT_APP_DEBUG == "true";
export const HOST = process.env.REACT_APP_HOST;
export const NODE_SERVER_HOST = process.env.REACT_APP_NODE_SERVER_HOST;


export const getMessages = (message) => {
    let messages = [];
    if (typeof message === 'string' && message.length > 0) {
        messages.push(message);
    }
    if (typeof message === 'object' && message) {
        let objectMessages = Object.values(message);
        objectMessages.map(
            msg => {
                if (typeof msg === "string") {
                    messages = [...messages, msg];
                } else {
                    messages = [...messages, ...msg];
                }
            }
        );
    }
    return messages;
};

export const getRupeeFromPaisa = (paisa) => parseFloat(paisa) / 100;

export const RANGE = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const fieldTypes = {
    TEXT: 'text',
    INTEGER: 'integer',
    FLOAT: 'float',
    BOOL: 'bool',
    FILE: 'file',
    CHOICE: 'choice',
    MULTIPLE_CHOICE: 'multiple_choice',
    READ_ONLY_TEXT: 'read_only_text',
    DATE: 'date',
    STAR: 'star',
    HEIGHT: 'height',
    RATING: 'rating',
};

export const documentTypes = {
    REPORT: 'report',
    PRESCRIPTION: 'prescription',
    VITAL: 'vital',
    NOTE: 'note',
};

export const Images = {
    logo: logo,
};

export const scrollToTop = (scrollDuration) => {
    const scrollHeight = window.scrollY;
    const scrollStep = Math.PI / (scrollDuration / 15);
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin;
    let scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
            scrollCount = scrollCount + 1;
            scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
            window.scrollTo(0, (scrollHeight - scrollMargin));
        } else clearInterval(scrollInterval);
    }, 15);
};

export const zTableX = [-3.4, -3.3, -3.2, -3.1, -3, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2, -1.9, -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1, -0.9, -0.8, -0.7, -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3, 3.1, 3.2, 3.3, 3.4];

export const getStorageItem = (key, defaultValue = null) => {
    try {
        return localStorage.getItem(key) || defaultValue
    } catch (e) {
        return defaultValue;
    }
};


export const setStorageItem = (key, value) => {
    try {
        return localStorage.setItem(key, value);
    } catch (e) {
        return null;
    }
};


export const removeStorageItem = (key) => {
    try {
        return localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};

export const getPhoneNumberWithCountryCode = (number) => {
    if (!number) number = '';
    if (number.startsWith('+91')) {
        return number;
    }
    return `+91${number}`;
};

export const getSearchParams = (path) => {
    let values = path.split('?');
    return queryString.parse(`?${values[values.length - 1]}`);
};

export const getPrice = (price) => (parseFloat(price) / 100).toFixed(2);


export const orderColorCode = {
    lessThan24Hours: {
        zeroAttempts: 'rgb(248, 247, 229)',
        oneAttempts: '#f1de95',
        moreThanOneAttempts: '#efb24d',
    },
    moreThan24Hours: {
        zeroAttempts: '#ffdbdb',
        oneAttempts: '#ff8b8b',
        moreThanOneAttempts: '#d0021b',
    }
}

export const getCallAttemptsBackgroundColor = ({status, status_created_at, attempted_calls}) => {
    let isDateBefore24hours = moment().subtract(24, 'hours').isAfter(status_created_at);
    let isCreatedOrderBefore24hours = status === "created" && !!isDateBefore24hours;

    if (!!isCreatedOrderBefore24hours && attempted_calls >= 2)
        return orderColorCode.moreThan24Hours.moreThanOneAttempts;
    else if (!!isCreatedOrderBefore24hours && attempted_calls === 1)
        return orderColorCode.moreThan24Hours.oneAttempts;
    else if (!!isCreatedOrderBefore24hours)
        return orderColorCode.moreThan24Hours.zeroAttempts;
    else if (attempted_calls >= 2)
        return orderColorCode.lessThan24Hours.moreThanOneAttempts;
    else if (attempted_calls === 1)
        return orderColorCode.lessThan24Hours.oneAttempts;
    else
        return orderColorCode.lessThan24Hours.zeroAttempts;
}

export const orderCallTypeFromStatus = (status) => {
    switch (status) {
        case 'created':
            return 'create_order';
        case 'pending':
            return 'pending_order';
        case 'canceled':
            return 'canceled_order';
        case 'delivered':
            return 'delivered_order';
        case 'shipped':
            return 'shipped_order';
        case 'return_received':
            return 'returned_order';
        default:
            return 'manual_call';
    }
}

export const callTypes = {
    PENDING_ORDER: 'pending_order',
    CREATE_ORDER: 'create_order',
    MANUAL_CALL: 'manual_call',
    CANCELED_ORDER: 'canceled_order',
    RETURNED_ORDER: 'returned_order',
    DELIVERED_ORDER: 'delivered_order',
    SHIPPED_ORDER: 'shipped_order',
    QUIZ_CALL: 'quiz_call',
    INCOMING_CALL: 'incoming_call',
    INCOMING_CALL_DOCTOR: 'incoming_call_doctor',
}

export const getQueryString = (params) => {
    return Object.keys(params).map(key => key + '=' + params[key]).join('&');
};

export const isObjectsEqual = (object, prevObject) => {
    let status = true;
    if (!Object.keys(object).length && !!Object.keys(prevObject).length)
        return false;

    Object.keys(object).forEach(key => {
        if (object[key] !== prevObject[key])
            status = false;
    });

    return status;
};

export const orderStatuses = [
    {label: 'Created', value: 'created'},
    {label: 'Pending', value: 'pending'},
    {label: 'Canceled', value: 'canceled'},
    {label: 'Failed', value: 'failed'},
    {label: 'Consultation Pending', value: 'consultation_pending'},
    {label: 'Confirm', value: 'confirm'},
    {label: 'Packed', value: 'packed'},
    {label: 'Shipped', value: 'shipped'},
    {label: 'Delivered', value: 'delivered'},
    {label: 'Return Initiated', value: 'return_initiated'},
    {label: 'Return In Transit', value: 'return_in_transit'},
    {label: 'Return Received', value: 'return_received'},
    {label: 'Refund Initiated', value: 'refund_initiated'},
    {label: 'Refunded', value: 'refunded'},
]

export const PaymentType = [
    {label: 'COD', value: 'cod'},
    {label: 'ONLINE', value: 'online'},
]

export const orderType = [
    {label: 'Single Order', value: 'single_order'},
    {label: 'Repeat Order', value: 'repeat_order'},
]


export const cancelReason = [
    {
        value: 'address_too_short',
        label: 'ADDRESS TOO SHORT AND NOT CONTACTABLE',
    },
    {
        value: 'customer_not_interested',
        label: 'CUSTOMER NOT INTERESTED',
    },
    {
        value: 'rejected_by_delivery_partner',
        label: 'REJECTED BY DELIVERY PARTNER',
    },
    {
        value: 'not_suitable_for_patient',
        label: 'NOT SUITABLE FOR PATIENT',
    },
    {
        value: 'only_prepaid_order_allowed',
        label: 'ONLY PREPAID ORDER ALLOWED',
    },
    {
        value: 'invalid_number',
        label: 'INVALID NUMBER',
    },
    {
        value: 'order_canceled_and_new_created',
        label: 'CANCELED and MODIFIED ORDER CREATED',
    },
    {
        value: 'looking_for_something_else',
        label: 'LOOKING FOR SOMETHING ELSE'
    },
    {
        value: 'duplicate_order',
        label: 'DUPLICATE ORDER'
    },
    {
        value: 'test_order',
        label: 'TEST ORDER'
    },
    {
        value: 'other',
        label: 'OTHER'
    },
];

export const getIndexCount = (index, page, perPage) => {
    return (index + 1) + (((page || 1) - 1) * perPage);
};

export const copyText = (text) => {
    toast.dismiss();
    let copyText = text;
    let element = document.createElement("input");
    element.type = 'text';
    element.value = copyText;
    element.style.position = "fixed"; // Prevent MS edge scrolling.
    document.body.append(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
    toast(`Copied: ${text}`,);
};

export const languageOptions = [
    {label: 'English', value: 'english', code: 'en', db_label: ''},
    {label: 'Hindi', value: 'hindi', code: 'hi', db_label: '_hi'},
];

export const SEX_QUIZ_BOT_FORM_ID = 'sex_quiz_ver28v9';
export const SEX_QUIZ_FIELD_ID = 'age';
export const AYURVEDIC_QUIZ_BOT_FORM_ID = 'ayurvedicn3l';
export const AYURVEDIC_QUIZ_FIELD_ID = 'start';
