import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import './styles.scss';


export default class DateRange extends PureComponent {

    static propTypes = {
        handleChangeFromDate: PropTypes.func,
        handleChangeToDate: PropTypes.func,
        fromDate: PropTypes.string,
        toDate: PropTypes.string,
        label: PropTypes.string,
    };

    static defaultProps = {
        fromDate: '',
        toDate: '',
        label: 'Order Date'
    };

    render() {
        let {fromDate, toDate, handleChangeFromDate, handleChangeToDate, label} = this.props;
        fromDate = !!fromDate ? moment(fromDate).toDate() : '';
        toDate = !!toDate ? moment(toDate).toDate() : '';

        return (
            <div className="row date-picker-rage">
                <div className="col-12">
                    <Form.Label className="custom-form-label">{label}</Form.Label>
                </div>
                <div className="col-6 pr-1">
                    <Form.Group controlId="fieldId" className="m-0">
                        <DatePicker className="form-control custom-form-control"
                                    dateFormat="dd/MM/yyyy" placeholderText="From"
                                    onChange={date => handleChangeFromDate(date)}
                                    selectsStart isClearable
                                    selected={fromDate}
                                    startDate={fromDate}
                                    endDate={toDate}
                        />
                    </Form.Group>
                </div>
                <div className="col-6 pl-1">
                    <Form.Group controlId="fieldId" className="m-0">
                        <DatePicker className="form-control custom-form-control"
                                    dateFormat="dd/MM/yyyy" placeholderText="To"
                                    onChange={date => handleChangeToDate(date)}
                                    selectsEnd isClearable
                                    selected={toDate}
                                    startDate={fromDate}
                                    endDate={toDate}
                                    minDate={fromDate}
                        />
                    </Form.Group>
                </div>
            </div>
        )
    }
}
