import {takeEvery, all} from 'redux-saga/effects';

import accounts from './accounts';
import ecommerce from './ecommerce';
import consultations from './consultations';
import quiz from './quiz';
import support from './support';


export default function* sagas() {
    yield all([
        ...accounts,
        ...ecommerce,
        ...consultations,
        ...quiz,
        ...support,
    ]);
}
