import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocketIOClient from "socket.io-client";

import "./styles.scss";
import {fetchIncomingCall} from "../../../actions/ecommerce/calls";
import {getMessages, languageOptions, NODE_SERVER_HOST} from "../../../constants";
import {withRouter} from "react-router";
import Modal from "react-bootstrap/Modal";


class ScheduledCallToast extends React.Component {

    componentDidMount() {
        // this.props.fetchIncomingCall();
        this.setSocketListeners();
    }

    state = {
        scheduled_call_id: null,
    }

    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999
    });

    setSocketListeners = () => {
        let {profile} = this.props;
        // console.warn(`SCHEDULED_CALL:${profile.data.id}`);
        if (profile.data) {
            this.socket.on(`SCHEDULED_CALL:${profile.data.id}`, this.handleIncomingCalls);
        }
        this.socket.on('reconnect', (attemptNumber) => {
            // this.handleOrderUpdates();
        });
        this.socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.socket.connect();
            }
            this.setState({connected: false});
        });
    };

    handleIncomingCalls = (scheduled_call_id) => {
        // this.props.fetchIncomingCall();
        // console.warn('scheduled_call_id', scheduled_call_id);
        this.setState({scheduled_call_id});
    };

    render() {
        let {history} = this.props;
        let {scheduled_call_id} = this.state;

        return (
            <Modal show={!!scheduled_call_id} onHide={() => this.setState({scheduled_call_id: null})}
                   size='s' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scheduled Call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="text-center text-danger">ATTENTION: You have a call scheduled for now!</h2>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary mr-2"
                            onClick={() => {history.push('/scheduled-calls'); this.setState({scheduled_call_id: null});}}>
                        Show me
                    </button>
                    <button className="btn btn-danger"
                            onClick={() => this.setState({scheduled_call_id: null})}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.accounts.profile,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchIncomingCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ScheduledCallToast));
