import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Table from "react-bootstrap/Table";
import moment from "moment";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";


class OrderHistoryTable extends PureComponent {

    static propTypes = {
        orderHistory: PropTypes.array,
    };

    static defaultProps = {
        orderHistory: [],
    };

    handleOnClick = (order) => {
        let {history} = this.props;
        history.push(`/orders/${order.id}`);
    }

    render() {
        let {orderHistory} = this.props

        return (
            <React.Fragment>
                <h5 className="heading">Customer’s Order History</h5>
                <Table className="order-list" responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        orderHistory.map((order, index) => (
                                <tr key={order.cid}>
                                    <td>{index + 1}</td>
                                    <td>{order.cid}</td>
                                    <td>
                                        {moment(order.created_at).format("DD MMMM YYYY,")}
                                        <br/>
                                        {moment(order.created_at).format("h:mm a")}
                                    </td>
                                    <td>{order.status}</td>
                                    <td>
                                        <Button variant="dark" size={'sm'}
                                                onClick={() => this.handleOnClick(order)}>
                                            View
                                        </Button>
                                    </td>
                                </tr>
                            )
                        )
                    }
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

export default withRouter(OrderHistoryTable)
