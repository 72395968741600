import {call, put} from 'redux-saga/effects';
import {
    CREATE_ANSWER_RESULT,
    CREATE_ANSWER_ERROR,
    CREATE_ANSWER_RETRY,
    UPDATE_ANSWER_RESULT,
    UPDATE_ANSWER_ERROR,
    UPDATE_ANSWER_RETRY,
    CREATE_REPORT_RESULT,
    CREATE_REPORT_ERROR,
    CREATE_REPORT_RETRY,
    CREATE_REPORT_FOR_QUIZ_TAKER_RESULT, CREATE_REPORT_FOR_QUIZ_TAKER_ERROR, CREATE_REPORT_FOR_QUIZ_TAKER_RETRY,
} from '../../actions/quiz/answers';
import api from "../api";
import {toast} from "react-toastify";


export function* createAnswer(action) {
    try {
        const response = yield call(api.quiz.answers.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createAnswer', action, result);

        if (response.status === 200) {
            yield put({type: CREATE_ANSWER_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_ANSWER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ANSWER_RETRY, retry: true, message: e.message});
    }
}


export function* updateAnswer(action) {
    try {
        const response = yield call(api.quiz.answers.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateAnswer', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_ANSWER_RESULT, data: result});
        }
        else {
            yield put({type: UPDATE_ANSWER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ANSWER_RETRY, retry: true, message: e.message});
    }
}


export function* createReport(action) {
    try {
        const response = yield call(api.quiz.answers.assignReport, action.data);
        const result = yield response.json();
        // console.warn('SAGA:createReport', action, result);

        if (response.status === 200) {
            yield put({type: CREATE_REPORT_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_REPORT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_REPORT_RETRY, retry: true, message: e.message});
    }
}

export function* createReportForQuizTaker(action) {
    try {
        const response = yield call(api.quiz.answers.assignReportForQuizTaker, action.data);
        const result = yield response.json();
        // console.warn('SAGA:createReport', action, result);

        if (response.status === 200) {
            yield put({type: CREATE_REPORT_FOR_QUIZ_TAKER_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: CREATE_REPORT_FOR_QUIZ_TAKER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_REPORT_FOR_QUIZ_TAKER_RETRY, retry: true, message: e.message});
    }
}
