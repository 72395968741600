export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_RESULT = 'FETCH_ORDERS_RESULT';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const FETCH_ORDERS_RETRY = 'FETCH_ORDERS_RETRY';

export const FETCH_PENDING_ORDERS = 'FETCH_PENDING_ORDERS';
export const FETCH_PENDING_ORDERS_RESULT = 'FETCH_PENDING_ORDERS_RESULT';
export const FETCH_PENDING_ORDERS_ERROR = 'FETCH_PENDING_ORDERS_ERROR';
export const FETCH_PENDING_ORDERS_RETRY = 'FETCH_PENDING_ORDERS_RETRY';

export const CANCELED_ORDERS = 'CANCELED_ORDERS';
export const CANCELED_ORDERS_RESULT = 'CANCELED_ORDERS_RESULT';
export const CANCELED_ORDERS_ERROR = 'CANCELED_ORDERS_ERROR';
export const CANCELED_ORDERS_RETRY = 'CANCELED_ORDERS_RETRY';

export const FETCH_ORDER_INFO = 'FETCH_ORDER_INFO';
export const FETCH_ORDER_INFO_RESULT = 'FETCH_ORDER_INFO_RESULT';
export const FETCH_ORDER_INFO_ERROR = 'FETCH_ORDER_INFO_ERROR';
export const FETCH_ORDER_INFO_RETRY = 'FETCH_ORDER_INFO_RETRY';

export const CONFIRM_ORDER = 'CONFIRM_ORDER';
export const CONFIRM_ORDER_RESULT = 'CONFIRM_ORDER_RESULT';
export const CONFIRM_ORDER_ERROR = 'CONFIRM_ORDER_ERROR';
export const CONFIRM_ORDER_RETRY = 'CONFIRM_ORDER_RETRY';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_RESULT = 'CANCEL_ORDER_RESULT';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export const CANCEL_ORDER_RETRY = 'CANCEL_ORDER_RETRY';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_RESULT = 'CREATE_ORDER_RESULT';
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR';
export const CREATE_ORDER_RETRY = 'CREATE_ORDER_RETRY';

export const FETCH_ORDER_ESTIMATE = 'FETCH_ORDER_ESTIMATE';
export const FETCH_ORDER_ESTIMATE_RESULT = 'FETCH_ORDER_ESTIMATE_RESULT';
export const FETCH_ORDER_ESTIMATE_ERROR = 'FETCH_ORDER_ESTIMATE_ERROR';
export const FETCH_ORDER_ESTIMATE_RETRY = 'FETCH_ORDER_ESTIMATE_RETRY';

export const REFUND_ORDER = 'REFUND_ORDER';
export const REFUND_ORDER_RESULT = 'REFUND_ORDER_RESULT';
export const REFUND_ORDER_ERROR = 'REFUND_ORDER_ERROR';
export const REFUND_ORDER_RETRY = 'REFUND_ORDER_RETRY';

export const UPDATE_ORDER_ADDRESS = 'UPDATE_ORDER_ADDRESS';
export const UPDATE_ORDER_ADDRESS_RESULT = 'UPDATE_ORDER_ADDRESS_RESULT';
export const UPDATE_ORDER_ADDRESS_ERROR = 'UPDATE_ORDER_ADDRESS_ERROR';
export const UPDATE_ORDER_ADDRESS_RETRY = 'UPDATE_ORDER_ADDRESS_RETRY';


export const CONVERT_TO_ONLINE = 'CONVERT_TO_ONLINE';
export const CONVERT_TO_ONLINE_RESULT = 'CONVERT_TO_ONLINE_RESULT';
export const CONVERT_TO_ONLINE_ERROR = 'CONVERT_TO_ONLINE_ERROR';
export const CONVERT_TO_ONLINE_RETRY = 'CONVERT_TO_ONLINE_RETRY';


export const RESEND_PAYMENT_LINK = 'RESEND_PAYMENT_LINK';
export const RESEND_PAYMENT_LINK_RESULT = 'RESEND_PAYMENT_LINK_RESULT';
export const RESEND_PAYMENT_LINK_ERROR = 'RESEND_PAYMENT_LINK_ERROR';
export const RESEND_PAYMENT_LINK_RETRY = 'RESEND_PAYMENT_LINK_RETRY';

export const SEND_AYURVEDIC_QUIZ_LINK = 'SEND_AYURVEDIC_QUIZ_LINK';
export const SEND_AYURVEDIC_QUIZ_LINK_RESULT = 'SEND_AYURVEDIC_QUIZ_LINK_RESULT';
export const SEND_AYURVEDIC_QUIZ_LINK_ERROR = 'SEND_AYURVEDIC_QUIZ_LINK_ERROR';
export const SEND_AYURVEDIC_QUIZ_LINK_RETRY = 'SEND_AYURVEDIC_QUIZ_LINK_RETRY';

export const CHECKOUT_LINK = 'CHECKOUT_LINK';
export const CHECKOUT_LINK_RESULT = 'CHECKOUT_LINK_RESULT';
export const CHECKOUT_LINK_ERROR = 'CHECKOUT_LINK_ERROR';
export const CHECKOUT_LINK_RETRY = 'CHECKOUT_LINK_RETRY';

export const FETCH_REPEAT_ORDERS = 'FETCH_REPEAT_ORDERS';
export const FETCH_REPEAT_ORDERS_RESULT = 'FETCH_REPEAT_ORDERS_RESULT';
export const FETCH_REPEAT_ORDERS_ERROR = 'FETCH_REPEAT_ORDERS_ERROR';
export const FETCH_REPEAT_ORDERS_RETRY = 'FETCH_REPEAT_ORDERS_RETRY';


export const fetchOrders = (data) => ({
    type: FETCH_ORDERS,
    data
});

export const fetchPendingOrders = (data) => ({
    type: FETCH_PENDING_ORDERS,
    data
});

export const fetchCanceledOrders = (data) => ({
    type: CANCELED_ORDERS,
    data,
});

export const fetchOrderInfo = (data) => ({
    type: FETCH_ORDER_INFO,
    data,
});

export const confirmOrder = (data) => ({
    type: CONFIRM_ORDER,
    data,
});

export const cancelOrder = (data) => ({
    type: CANCEL_ORDER,
    data,
});

export const createOrder = (data) => ({
    type: CREATE_ORDER,
    data,
});

export const fetchOrderEstimate = (data) => ({
    type: FETCH_ORDER_ESTIMATE,
    data,
});

export const refundOrder = (data) => ({
    type: REFUND_ORDER,
    data,
});

export const updateOrderAddress = (data) => ({
    type: UPDATE_ORDER_ADDRESS,
    data,
});

export const convertToOnline = (data) => ({
    type: CONVERT_TO_ONLINE,
    data,
});

export const resendPaymentLink = (data) => ({
    type: RESEND_PAYMENT_LINK,
    data,
});

export const sendAyurvedicQuizLink = (data) => ({
    type: SEND_AYURVEDIC_QUIZ_LINK,
    data,
});

export const sendCheckoutLink = (data) => ({
    type: CHECKOUT_LINK,
    data,
});

export const fetchRepeatOrders = (data) => ({
    type: FETCH_REPEAT_ORDERS,
    data
});