import {
    FETCH_PHARMACY_LIST,
    FETCH_PHARMACY_LIST_RESULT,
    FETCH_PHARMACY_LIST_ERROR,
    FETCH_PHARMACY_LIST_RETRY,
} from '../../actions/ecommerce/pharmacy';


const initialState = {
    detail: {},
    list: [],
    isFetching: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_PHARMACY_LIST:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PHARMACY_LIST_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_PHARMACY_LIST_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PHARMACY_LIST_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
