import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form} from "react-bootstrap";
import './styles.scss';
import Select from "react-select";
import {RANGE} from "../../../constants";


export default class CallConnectedRange extends PureComponent {

    static propTypes = {
        handleChangeStartRange: PropTypes.func,
        handleChangeEndRange: PropTypes.func,
        startRange: PropTypes.string,
        endRange: PropTypes.string,
    };

    static defaultProps = {
        startRange: '',
        endRange: '',
    };

    render() {
        let {startRange, endRange, handleChangeStartRange, handleChangeEndRange} = this.props;

        return (
            <div className="row call-connected-rage">
                <div className="col-12">
                    <Form.Label className="custom-form-label">Call Connected</Form.Label>
                </div>
                <div className="col-12 pr-1">
                    <Form.Group controlId="fieldId" className="m-0">
                        <Select options={RANGE.map(range => ({label: range, value: range}))}
                                className="custom-form-control" placeholder="From" isClearable
                                value={RANGE.filter(range => range === startRange).map(range => ({
                                    label: range,
                                    value: range
                                }))}
                                onChange={(range) => handleChangeStartRange(range)}/>
                    </Form.Group>
                </div>
            </div>
        )
    }
}
