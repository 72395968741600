import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import moment from "moment";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {sendAyurvedicQuizLink,} from "../../actions/ecommerce/orders";
import {createCall, fetchCall} from "../../actions/ecommerce/calls";
import {
    AYURVEDIC_QUIZ_BOT_FORM_ID,
    AYURVEDIC_QUIZ_FIELD_ID,
    getPrice,
    orderCallTypeFromStatus,
} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import CallDetailForm from "../../components/forms/CallDetailForm";
import OrderDetailHeader from "../../components/others/OrderDetailHeader";
import OrderDetailButtons from "../../components/others/OrderDetailButtons";
import CallHistory from "../../components/others/CallHistory";
import OrderHistoryTable from "../../components/others/OrderHistoryTable";
import CallDetailModal from "../../components/modals/CallDetailModal";
import OrderCreate from "../../components/modals/OrderCreate";
import QuizModal from "../../components/modals/QuizModal";
import {getTextFromHtml} from "../../utils";
import Button from "react-bootstrap/Button";
import {callTypeOptions} from "../../callConstants";
import {NavLink} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ScheduleCallModal from "../../components/modals/ScheduleCallModal";


class CallDetail extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        calls: PropTypes.object,
        createCall: PropTypes.func,
        sendAyurvedicQuizLink: PropTypes.func,
    };

    state = {
        order: null,
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.props.fetchCall({sid: params.callSid});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {calls, orders, match: {params}} = this.props;

        if (params.callSid !== prevProps.match.params.callSid) {
            this.props.fetchCall({sid: params.callSid});
        }

        if (!orders.isCreating && prevProps.orders.isCreating && !orders.error && !orders.retry) {
            toast("Order created");
            this.handleOrderUpdates();
        }

        if (!calls.isCreating && prevProps.calls.isCreating && !calls.error && !calls.retry) {
            toast("Call Created");
            this.handleOrderUpdates();
        }

        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            toast("Call Detail Updated");
            this.handleOrderUpdates();
        }
    }

    handleOrderUpdates = () => {
        let {params} = this.props.match;
        this.props.fetchCall({sid: params.callSid});
    };

    handleMakeCall = (call) => {
        this.props.createCall({call_sid: call.sid, call_type: orderCallTypeFromStatus()});
    };

    handleSendAyurvedicQuizLink = () => {
        let {calls: {detail}} = this.props;
        this.props.sendAyurvedicQuizLink({call_sid: detail.sid});
    };

    render() {
        let {history, calls: {detail, detail: {shipping_address = {}, support_calls = [], orders_history = [], reports_history = []}}} = this.props;
        shipping_address = shipping_address || {};

        let formFields = callTypeOptions[detail.call_type] ? callTypeOptions[detail.call_type].data : {};
        let attributes = detail.data;

        return (
            <SidebarContainer>
                <OrderCreate show={this.state.showCreate} address={shipping_address} callSid={detail.sid}
                             handleClose={() => this.setState({showCreate: false})}/>

                {
                    !!this.state.callDetail &&
                    <CallDetailModal show={true} callDetail={this.state.callDetail}
                                     handleClose={() => this.setState({callDetail: undefined})}/>
                }
                {
                    !!this.state.showAyurvedicQuiz &&
                    <QuizModal show={true}
                               field_id={AYURVEDIC_QUIZ_FIELD_ID}
                               bot_form_id={AYURVEDIC_QUIZ_BOT_FORM_ID}
                               report_id={detail.id}
                               handleClose={() => this.setState({showAyurvedicQuiz: undefined})}/>
                }
                {
                    !!this.state.scheduledCall &&
                    <ScheduleCallModal show={true} data={this.state.scheduledCall}
                                       handleClose={() => this.setState({scheduledCall: undefined})}/>
                }
                <div className="container-fluid pt-5 position-relative">
                    <span className="material-icons back-button"
                          onClick={() => this.props.history.goBack()}>keyboard_backspace</span>

                    <div className="row">
                        <div className="col-12 col-md-7">
                            <OrderDetailHeader objectId={detail.sid} objectTitle={'Call SID'}/>
                        </div>
                        <div className="col-12 col-md-5">
                            <OrderDetailButtons showCall={!!detail.id}
                                                onClickAyurvedicLink={this.handleSendAyurvedicQuizLink}
                                                onClickCallOrder={() => this.handleMakeCall(detail)}
                                                onClickScheduledCall={() => this.setState({scheduledCall: {support_call: detail.id}})}
                                                onClickCreateOrder={() => this.setState({showCreate: true})}
                                                onClickAyurvedicQuiz={() => this.setState({showAyurvedicQuiz: true})}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 px-1">
                            <div className="row">
                                {!!detail.id && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Call ID</p>
                                    <p className="text">{detail.id}</p>
                                </div>}

                                {!!detail.customer_id && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Customer ID</p>
                                    <p className="text">
                                        <NavLink to={`/customers/${detail.customer_id}`}>{detail.customer_id}</NavLink>
                                    </p>
                                </div>}
                                {
                                    !!detail.clinic_name &&  <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Clinic Name</p>
                                        <p className="text">
                                            {detail.clinic_name}
                                        </p>
                                    </div>
                                }

                                {!!detail.created_at && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Call Date</p>
                                    <p className="text">{moment(detail.created_at).format("DD MMMM YYYY, h:mm a")}</p>
                                </div>}

                                {!!detail.order_id && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Order ID</p>
                                    <p className="text">
                                        <NavLink to={`/orders/${detail.order_id}`} className="text-primary">
                                            {detail.order_id}</NavLink>
                                    </p>
                                </div>}

                                {!!detail.report_id && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Report ID</p>
                                    <p className="text">
                                        <NavLink to={`/quizzers/${detail.report_id}`} className="text-primary">
                                            {detail.report_id}</NavLink>
                                    </p>
                                </div>}

                                {!!detail.call_type && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Call Type</p>
                                    <p className="text text-capitalize">{detail.call_type.split('_').join(" ")}</p>
                                </div>}

                                {!!detail.user_name && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Called By</p>
                                    <p className="text">{detail.user_name}</p>
                                </div>}

                                {detail.direction === 'incoming' && <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Conversation Duration</p>
                                    <p className="text">{detail.conversation_duration.toString()}</p>
                                </div>}

                                {detail.direction === 'incoming' && !!detail.conversation_status &&
                                <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Conversation Status</p>
                                    <p className="text">{detail.conversation_status}</p>
                                </div>}

                                <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Conversation Completed</p>
                                    <p className="text">{detail.call_completed ? 'Yes' : 'No'}</p>
                                </div>

                                <div className="col-6 col-md-4">
                                    <p className="heading mb-0">Call Status</p>
                                    <p className="text">{detail.status}</p>
                                </div>
                                {
                                    !!detail.conversation_status &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Conversation Status</p>
                                        <p className="text">{detail.conversation_status}</p>
                                    </div>
                                }
                            </div>

                            {!!detail.recording_url && <React.Fragment>
                                <p className="heading mb-0">Call Recording</p>
                                <div className="mb-3">
                                    <audio controls controlsList="nodownload"
                                           style={{maxWidth: '250px', height: '30px'}}>
                                        <source src={detail.recording_url}/>
                                    </audio>
                                </div>
                            </React.Fragment>}

                            {
                                !!detail.editable_call &&
                                <CallDetailForm label={`Edit Call`} callData={detail.editable_call}/>
                            }

                            <p className="heading mb-0">Call Details</p>
                            <Table className="order-list" responsive>
                                <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Key</td>
                                    <td>Answer</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(formFields).map(
                                        (key, index) => {
                                            let label = formFields[key].label;
                                            let value = attributes[key];
                                            return (
                                                <tr key={key} className="col-12 p-0">
                                                    <td>{index + 1}</td>
                                                    <td>{label}</td>
                                                    {
                                                        (typeof value === 'boolean') &&
                                                        <td>{!!value ? 'yes' : 'no'}</td>
                                                    }
                                                    {
                                                        (typeof value === 'undefined') &&
                                                        <td> - </td>
                                                    }
                                                    {typeof value === 'string' && <td>{value}</td>}
                                                </tr>
                                            )
                                        }
                                    )
                                }
                                </tbody>
                            </Table>
                            <CallDetailForm label="Edit Call Details" callData={detail}/>
                            {!!detail.remarks && <React.Fragment>
                                <p className="heading mt-3 mb-0">Remarks</p>
                                <p className="text">{detail.remarks}</p>
                            </React.Fragment>}

                            <OrderHistoryTable orderHistory={orders_history}/>

                            <div>
                                <h5 className="heading">Customer’s Report History</h5>
                                <Table className="order-list" responsive>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Report ID</th>
                                        <th>Date</th>
                                        <th>Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        reports_history.map((report, index) => (
                                                <tr key={report.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{report.report_id}</td>
                                                    <td>
                                                        {moment(report.created_at).format("DD MMMM YYYY,")}
                                                        <br/>
                                                        {moment(report.created_at).format("h:mm a")}
                                                    </td>
                                                    <td>
                                                        <Button variant="dark" size={'sm'}
                                                                onClick={() => history.push(`/quizzers/${report.id}`)}>
                                                            View
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <CallDetailForm callData={detail.ongoing_call}/>
                            <CallHistory supportCalls={support_calls}
                                         onClickView={(call) => this.setState({callDetail: call, order: null})}/>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.ecommerce.orders,
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCall,
    createCall,
    sendAyurvedicQuizLink,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CallDetail);
