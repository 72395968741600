import {HOST, DEBUG} from "./constants";
import * as htmlToText from "html-to-text";


export const getHttpsImageUrl = (url) => {
    if (!url) url = '';
    if (url.startsWith('https')) {
        return url;
    } else if (url.startsWith('http')) {
        if (DEBUG)
            return url.replace('http', 'http'); // TODO: Change this to https
        else
            return url.replace('http', 'https');
    } else {
        return HOST + url;
    }
};


export const getTextFromHtml = (text) => {
    if (!!text) {
        text = text.replace(/<(?:.|\s)*?>/g, "");
        return htmlToText.fromString(text, {wordwrap: 30});
    }
};


export const debounce = (callback, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), delay);
    }
}
