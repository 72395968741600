import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";


class OrderStatusModal extends React.Component {

    static propTypes = {
        orderStatus: PropTypes.array,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        orderStatus: [],
        show: false,
    };


    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {

        }
    }

    render() {
        let {show, handleClose, orderStatus} = this.props;

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Order Tracking</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="mb-0">
                        {orderStatus.map((status) => (
                            <li className="mb-3" key={status.id}>
                                <p className="heading mb-0 text-capitalize">Order {status.status}</p>
                                <p className="text mb-0">
                                    at {moment(status.created_at).format("DD MMMM YYYY, h:mm a")}
                                </p>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({});

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderStatusModal);
