import {takeEvery} from 'redux-saga/effects';

import {createAnswer, updateAnswer, createReport, createReportForQuizTaker} from "./answers";
import {CREATE_ANSWER, UPDATE_ANSWER, CREATE_REPORT, CREATE_REPORT_FOR_QUIZ_TAKER} from "../../actions/quiz/answers";
import {FETCH_QUIZZERS, FETCH_QUIZZER_INFO} from "../../actions/quiz/quizzers";
import {fetchQuizzers, fetchQuizzerInfo} from "./quizzers";

export default [
    takeEvery(CREATE_ANSWER, createAnswer),
    takeEvery(UPDATE_ANSWER, updateAnswer),
    takeEvery(CREATE_REPORT, createReport),
    takeEvery(CREATE_REPORT_FOR_QUIZ_TAKER, createReportForQuizTaker),

    takeEvery(FETCH_QUIZZERS, fetchQuizzers),
    takeEvery(FETCH_QUIZZER_INFO, fetchQuizzerInfo),
];
