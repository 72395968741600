import {call, put} from 'redux-saga/effects';
import {
    FETCH_SHIFT_RESULT,
    FETCH_SHIFT_ERROR,
    FETCH_SHIFT_RETRY,

    UPDATE_SHIFT_RESULT,
    UPDATE_SHIFT_ERROR,
    UPDATE_SHIFT_RETRY,
} from '../../actions/ecommerce/shift';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchShift(action) {
    try {
        const response = yield call(api.ecommerce.shift.get);
        const result = yield response.json();
        console.warn('SAGA:fetchShift', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_SHIFT_RESULT, data: result});
        } else {
            yield put({type: FETCH_SHIFT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_SHIFT_RETRY, retry: true, message: e.message});
    }
}

export function* updateShift(action) {
    try {
        const response = yield call(api.ecommerce.shift.update);
        const result = yield response.json();
        console.warn('SAGA:updateShift', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_SHIFT_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message);
            yield put({type: UPDATE_SHIFT_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_SHIFT_RETRY, retry: true, message: e.message});
    }
}
