import {call, put} from 'redux-saga/effects';
import {
    FETCH_REPORTS,
    FETCH_REPORTS_RESULT,
    FETCH_REPORTS_ERROR,
    FETCH_REPORTS_RETRY,
} from '../../actions/ecommerce/reports';
import api from "../api";


export function* fetchReports(action) {
    try {
        const response = yield call(api.ecommerce.reports.list);
        const result = yield response.json();
        console.warn('SAGA:fetchReports', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPORTS_RESULT, data: result});
        } else {
            yield put({type: FETCH_REPORTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPORTS_RETRY, retry: true, message: e.message});
    }
}
