import {call, put} from 'redux-saga/effects';
import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_RESULT,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_RETRY,
} from '../../actions/ecommerce/products';
import api from "../api";


export function* fetchProducts(action) {
    try {
        const response = yield call(api.ecommerce.products.list);
        const result = yield response.json();
        console.warn('SAGA:fetchOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PRODUCTS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_PRODUCTS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PRODUCTS_RETRY, retry: true, message: e.message});
    }
}
