
export const FETCH_CLINICS = 'FETCH_CLINICS';
export const FETCH_CLINICS_RESULT = 'FETCH_CLINICS_RESULT';
export const FETCH_CLINICS_ERROR = 'FETCH_CLINICS_ERROR';
export const FETCH_CLINICS_RETRY = 'FETCH_CLINICS_RETRY';


export const fetchClinics = (data) => ({
    type: FETCH_CLINICS,
    data,
});
