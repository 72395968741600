export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_RESULT = 'FETCH_CUSTOMERS_RESULT';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';
export const FETCH_CUSTOMERS_RETRY = 'FETCH_CUSTOMERS_RETRY';

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_RESULT = 'FETCH_CUSTOMER_RESULT';
export const FETCH_CUSTOMER_ERROR = 'FETCH_CUSTOMER_ERROR';
export const FETCH_CUSTOMER_RETRY = 'FETCH_CUSTOMER_RETRY';


export const fetchCustomers = (data) => ({
    type: FETCH_CUSTOMERS,
    data
});


export const fetchCustomer = (data) => ({
    type: FETCH_CUSTOMER,
    data,
});
