//External Imports
import React from 'react';
import {Route, Switch} from 'react-router';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogRocket from 'logrocket';
//My Imports
import routes from './routes';
import './App.scss';
import LoginRedirect from "./components/others/LoginRedirect";
import IncomingCallToast from "./components/toasts/IncomingCallToast";
import ScheduledCallToast from "./components/toasts/ScheduledCallToast";


LogRocket.init('hh20cy/misters');


class App extends React.Component {

  componentDidMount() {
  }

  render() {
        return (
            <div>
                <LoginRedirect/>
                <Switch>
                    {
                        routes.map(
                            (route, index) => <Route key={index} {...route} />
                        )
                    }
                </Switch>
                <ToastContainer enableMultiContainer
                                autoClose={3000}
                                className={'toast-container'}/>
                <IncomingCallToast/>
                <ScheduledCallToast/>
            </div>
        );
    }
}

export default App;
