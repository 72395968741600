import {call, put} from 'redux-saga/effects';
import {
    FETCH_CLINICS_RESULT, FETCH_CLINICS_ERROR, FETCH_CLINICS_RETRY
} from '../../actions/support/clinics';
import api from "../api";


export function* fetchClinics(action) {
    try {
        const response = yield call(api.support.clinics.list);
        const result = yield response.json();
        console.warn('SAGA:fetchClinics', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_CLINICS_RESULT, data: result});
        }
        else {
            yield put({type: FETCH_CLINICS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_CLINICS_RETRY, retry: true, message: e.message});
    }
}

