import {call, put} from 'redux-saga/effects';
import {
    FETCH_COUPONS,
    FETCH_COUPONS_RESULT,
    FETCH_COUPONS_ERROR,
    FETCH_COUPONS_RETRY,
} from '../../actions/ecommerce/coupons';
import api from "../api";


export function* fetchCoupons(action) {
    try {
        const response = yield call(api.ecommerce.coupons.list);
        const result = yield response.json();
        console.warn('SAGA:fetchCoupons', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_COUPONS_RESULT, data: result});
        } else {
            yield put({type: FETCH_COUPONS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_COUPONS_RETRY, retry: true, message: e.message});
    }
}
