//External Imports
import {combineReducers} from 'redux';
//My Imports
import answers from './answers';
import quizzers from './quizzers';


export default combineReducers({
    answers,
    quizzers,
})
