import React from "react";
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import CryptoJS from "crypto-js";

import {fetchCall, updateCall} from "../../../actions/ecommerce/calls";
import "./styles.scss";
import moment from "moment";
import TransferCallModal from "../../modals/TransferCallModal";


class IncomingCallDetailForm extends React.Component {

    static propTypes = {
        callData: PropTypes.object,
        toggleCollapse: PropTypes.func,
        updateCall: PropTypes.func,
    };

    static defaultProps = {
        callData: {},
    };

    state = {
        callData: {},
        currentDuration: 0,
        totalDuration: 0,
        showTransferCall: false,
        createdCallTransfer: false,
    };

    componentDidMount() {
        let {callData} = this.props;
        let timeDiffCall = moment().diff(moment(callData.agent_pass_through.agent_called_at));
        let timeDiffTotal = moment().diff(moment(callData.created_at));
        this.setState({
            callData,
            currentDuration: moment.duration(timeDiffCall).asSeconds(),
            totalDuration: moment.duration(timeDiffTotal).asSeconds()
        });
        this.handleStartTimer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {calls, callData} = this.props;
        if (!calls.isCreatingCallTransfer && prevProps.calls.isCreatingCallTransfer && !calls.error && !calls.retry) {
            this.setState({createdCallTransfer: true});
        }
        if (callData !== prevProps.callData) {
            this.setState({callData});
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getCurrentCallDuration = () => {
        let {currentDuration} = this.state;
        return new Date(1000 * currentDuration).toISOString().substr(11, 8);
    }

    getTotalCallDuration = () => {
        let {totalDuration} = this.state;
        return new Date(1000 * totalDuration).toISOString().substr(11, 8);
    }

    handleStartTimer = () => {
        this.timer = setInterval(
            () => {this.setState(prevState => ({
                totalDuration: prevState.totalDuration + 1,
                currentDuration: prevState.currentDuration + 1
            }))},
            1000
        );
    }

    render() {
        let {callData, currentDuration, totalDuration, showTransferCall, createdCallTransfer} = this.state;
        let {isCollapsed} = this.props;
        if (!callData || !callData.id) return null;
        let customerIdHash = CryptoJS.MD5(`misters${callData.customer_id}@hfactorial.com`);

        let gravatarUrl = `https://gravatar.com/avatar/${customerIdHash}.jpg?s=200&r=pg&d=retro`;
        // console.warn('gravatarUrl', gravatarUrl);
        /*
        * Incoming Call | {obj.id} | <span style={{color: 'red'}}>{obj.status}</span>
        * */
        if (isCollapsed) {
            return (
                <div className="incoming-call-detail-form">
                    <div className="incoming-call-detail-form-header-small">
                        <img src={gravatarUrl} className="incoming-call-detail-form-user-image" alt="User"/>
                        <div className="incoming-call-detail-form-header-content">
                            <div>Call ID: <Link to={`/calls/${callData.sid}`}>{callData.id}</Link></div>
                        </div>
                        <button className="btn btn-primary"
                                onClick={() => this.props.toggleCollapse(true)}>
                            Show
                        </button>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="incoming-call-detail-form">
                    <div className="incoming-call-detail-form-header">
                        <img src={gravatarUrl} className="incoming-call-detail-form-user-image" alt="User"/>
                        <div className="incoming-call-detail-form-header-content">
                            <div>Customer ID: <Link to={`/customers/${callData.customer_id}`}>{callData.customer_id}</Link></div>
                            <div>Call ID: <Link to={`/calls/${callData.sid}`}>{callData.id}</Link></div>
                            {
                                !!callData.last_order &&
                                <div>
                                    Last Order ID: <Link to={`/orders/${callData.last_order.id}`}>{callData.last_order.id}</Link>
                                </div>
                            }
                            <div>ExoPhone SID: {callData.exophone_sid}</div>
                            <div>First ExoPhone SID: {callData.first_exophone_sid}</div>
                            <div>Clinic Name: {callData.clinic_name}</div>
                        </div>
                    </div>
                    <div className="incoming-call-detail-form-body">
                        {
                            !!totalDuration &&
                            <div className="incoming-call-detail-form-duration-total">
                                Total Call Time: {this.getTotalCallDuration()}
                            </div>
                        }
                        {
                            !!currentDuration &&
                            currentDuration > 0 &&
                            <div className="incoming-call-detail-form-duration">
                                {this.getCurrentCallDuration()}
                            </div>
                        }
                        {
                            (
                                createdCallTransfer ||
                                (!!callData.in_progress_transfers && !!callData.in_progress_transfers.length)
                            ) &&
                            <div className="text-center pb-2">Transfer will happen after the call disconnects</div>
                        }
                        {
                            (
                                !createdCallTransfer &&
                                (!!callData.in_progress_transfers && !callData.in_progress_transfers.length)
                            ) &&
                            <div>
                                <button className="btn btn-warning"
                                        onClick={() => this.setState({showTransferCall: true})}>Transfer Call</button>
                            </div>
                        }
                        <div>
                            <NavLink to={`/calls/${callData.sid}`} className="btn btn-primary">Record Call Summary</NavLink>
                        </div>
                        <div>
                            <button className="btn btn-danger"
                                    onClick={() => this.props.toggleCollapse(false)}>
                                Hide
                            </button>
                        </div>
                    </div>

                    {!!showTransferCall && <TransferCallModal data={callData} show={showTransferCall}
                                                              handleClose={() => this.setState({showTransferCall: false,})}/>}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCall,
    updateCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(IncomingCallDetailForm);
