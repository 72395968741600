import {
    FETCH_CLINICS,
    FETCH_CLINICS_RESULT,
    FETCH_CLINICS_ERROR,
    FETCH_CLINICS_RETRY,
} from '../../actions/support/clinics';


const initialState = {
    list: [],
    isFetchingList: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_CLINICS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_CLINICS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_CLINICS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_CLINICS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
