import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchCoupons} from "../../../actions/ecommerce/coupons";
import {sendCheckoutLink} from "../../../actions/ecommerce/orders";


class CouponModal extends React.Component {

    static propTypes = {
        orderId: PropTypes.number,
        coupons: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleCancel: PropTypes.func,
        fetchCoupons: PropTypes.func,
        sendCheckoutLink: PropTypes.func,
    };

    state = {
        coupon_code: '',
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            this.setState({
                coupon_code: '',
            });

            this.props.fetchCoupons();
        }
    }

    handleSubmitClick = () => {
        let {orderId} = this.props;
        let {coupon_code} = this.state;
        this.props.sendCheckoutLink({id: orderId, coupon_code});
    }

    render() {
        let {show, handleClose, coupons} = this.props;
        let {coupon_code} = this.state;

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Coupon Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="coupon">
                        <Form.Label>Select Coupon</Form.Label>
                        <Select options={coupons.list.map(coupon => ({label: coupon.code, value: coupon.code}))}
                                value={coupons.list.filter(coupon => coupon.code === coupon_code).map(coupon => ({
                                    label: coupon.code,
                                    value: coupon.code
                                }))}
                                onChange={({value}) => this.setState({coupon_code: value})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => this.handleSubmitClick()}
                            disabled={!coupon_code}>Submit
                    </Button>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    coupons: state.ecommerce.coupons,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCoupons,
    sendCheckoutLink,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CouponModal);