import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import {createCallTransfer, fetchActiveAgents} from "../../../actions/ecommerce/calls";
import {allowedCallTransferCallTypes, allowedOutgoingCallTypes} from "../../../callConstants";
import calls from "../../../reducers/ecommerce/calls";


class TransferCallModal extends React.Component {

    static propTypes = {
        data: PropTypes.object,
        calls: PropTypes.object,
        updateScheduledCall: PropTypes.func,
        createScheduledCall: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        data: {},
        calls: {},
        show: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            transferCall: {support_call: props.data && props.data.id, call_type: 'incoming_call'}
        };
    }

    componentDidMount() {
        this.props.fetchActiveAgents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {data, calls} = this.props;

        if (this.props.show && !prevProps.show) {
            this.setState({transferCall: {support_call: data.id, call_type: 'incoming_call'}});
        }
        if (!calls.isCreating && prevProps.calls.isCreatingCallTransfer && !calls.error && !calls.retry) {
            this.props.handleClose();
        }
    }

    handleCallTypeChange = (call_type) => {
        this.setState(prevState => ({transferCall: {...prevState.transferCall, call_type}}));
    }

    handleTransferredToChange = (transferred_to) => {
        this.setState(prevState => ({transferCall: {...prevState.transferCall, transferred_to}}));
    }

    handleSubmit = () => {
        let {transferCall} = this.state;
        // console.warn(transferCall);
        this.props.createCallTransfer(transferCall);
    }

    render() {
        let {data, show, handleClose, calls: {isCreatingCallTransfer, activeAgents, message}} = this.props;

        return (
            <Modal size={'md'} show={show} onHide={handleClose} centered style={{zIndex: 10001}}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer call {data.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="dateTime">
                        <Form.Label>Transfer to</Form.Label>
                        <br/>
                    </Form.Group>
                    <Form.Group controlId="remarks">
                        <Form.Label>Active Agent</Form.Label>
                        <select name="call_type" id="call_type"
                                defaultValue={''}
                                onChange={e => this.handleTransferredToChange(e.target.value)}>
                            <option value={''}>-</option>
                            {
                                activeAgents.map(
                                    (activeAgent) => (
                                        <option value={activeAgent.user} key={activeAgent.id}>{activeAgent.email}</option>
                                    )
                                )
                            }
                        </select>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.call_type}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <p>or</p>
                    <Form.Group controlId="remarks">
                        <Form.Label>Call Type</Form.Label>
                        <select name="call_type" id="call_type"
                                onChange={e => this.handleCallTypeChange(e.target.value)}>
                            {
                                Object.values(allowedCallTransferCallTypes).map(
                                    (callType, index) => (
                                        <option value={callType.value} key={index}>{callType.label}</option>
                                    )
                                )
                            }
                        </select>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.call_type}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleSubmit} disabled={isCreatingCallTransfer}>Save</Button>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    createCallTransfer,
    fetchActiveAgents,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(TransferCallModal);
