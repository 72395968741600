export const FETCH_CALLS = 'FETCH_CALLS';
export const FETCH_CALLS_RESULT = 'FETCH_CALLS_RESULT';
export const FETCH_CALLS_ERROR = 'FETCH_CALLS_ERROR';
export const FETCH_CALLS_RETRY = 'FETCH_CALLS_RETRY';

export const FETCH_CALL = 'FETCH_CALL';
export const FETCH_CALL_RESULT = 'FETCH_CALL_RESULT';
export const FETCH_CALL_ERROR = 'FETCH_CALL_ERROR';
export const FETCH_CALL_RETRY = 'FETCH_CALL_RETRY';

export const CREATE_CALL = 'CREATE_CALL';
export const CREATE_CALL_RESULT = 'CREATE_CALL_RESULT';
export const CREATE_CALL_ERROR = 'CREATE_CALL_ERROR';
export const CREATE_CALL_RETRY = 'CREATE_CALL_RETRY';

export const UPDATE_CALL = 'UPDATE_CALL';
export const UPDATE_CALL_RESULT = 'UPDATE_CALL_RESULT';
export const UPDATE_CALL_ERROR = 'UPDATE_CALL_ERROR';
export const UPDATE_CALL_RETRY = 'UPDATE_CALL_RETRY';

export const FETCH_INCOMING_CALL = 'FETCH_INCOMING_CALL';
export const FETCH_INCOMING_CALL_RESULT = 'FETCH_INCOMING_CALL_RESULT';
export const FETCH_INCOMING_CALL_ERROR = 'FETCH_INCOMING_CALL_ERROR';
export const FETCH_INCOMING_CALL_RETRY = 'FETCH_INCOMING_CALL_RETRY';

export const CREATE_CALL_TRANSFER = 'CREATE_CALL_TRANSFER';
export const CREATE_CALL_TRANSFER_RESULT = 'CREATE_CALL_TRANSFER_RESULT';
export const CREATE_CALL_TRANSFER_ERROR = 'CREATE_CALL_TRANSFER_ERROR';
export const CREATE_CALL_TRANSFER_RETRY = 'CREATE_CALL_TRANSFER_RETRY';

export const FETCH_ACTIVE_AGENTS = 'FETCH_ACTIVE_AGENTS';
export const FETCH_ACTIVE_AGENTS_RESULT = 'FETCH_ACTIVE_AGENTS_RESULT';
export const FETCH_ACTIVE_AGENTS_ERROR = 'FETCH_ACTIVE_AGENTS_ERROR';
export const FETCH_ACTIVE_AGENTS_RETRY = 'FETCH_ACTIVE_AGENTS_RETRY';


export const fetchCalls = (data) => ({
    type: FETCH_CALLS,
    data
});

export const fetchCall = (data) => ({
    type: FETCH_CALL,
    data
});

export const createCall = (data) => ({
    type: CREATE_CALL,
    data
});

export const updateCall = (data) => ({
    type: UPDATE_CALL,
    data
});

export const fetchIncomingCall = () => ({
    type: FETCH_INCOMING_CALL,
});

export const createCallTransfer = (data) => ({
    type: CREATE_CALL_TRANSFER,
    data
});

export const fetchActiveAgents = () => ({
    type: FETCH_ACTIVE_AGENTS,
});
