// External Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getHttpsImageUrl} from "../../../utils";
import Button from "react-bootstrap/Button";


export default class ChoiceInput extends Component {

    static propTypes = {
        choices: PropTypes.array,
        handleSendClick: PropTypes.func.isRequired,
        multipleChoice: PropTypes.bool,
        edit: PropTypes.bool,
    };

    static defaultProps = {
        choices: [],
        multipleChoice: false,
    };

    state = {
        choices: [],
        selectedChoices: {},
    };

    componentDidMount() {
        let {choices} = this.props;
        this.setState({choices});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.choices)
        if (JSON.stringify(this.props.choices) !== JSON.stringify(prevProps.choices)) {
            this.setState({choices: this.props.choices});
        }
    }

    handleChoiceClick = (choice) => {
        const choiceId = choice.id.toString();
        let {selectedChoices} = this.state;

        this.setState(prevState => {
            let text = 'true';

            if (this.props.multipleChoice) {
                if (selectedChoices[choiceId] && selectedChoices[choiceId].text === 'true') {
                    text = 'false';
                } else {
                    let singleCondition = choice.bot_field.text.toLowerCase() === 'none of these';
                    if (singleCondition) {
                        return {
                            selectedChoices: {
                                [choiceId]: {id: choiceId, text, singleChoiceForced: true}
                            }
                        }
                    }
                }
                let selectedChoicesArray = Object.values(selectedChoices).filter(c => !c.singleChoiceForced);
                let newSelectedChoices = {};
                for (let i = 0; i < selectedChoicesArray.length; i++) {
                    newSelectedChoices[selectedChoicesArray[i].id] = selectedChoicesArray[i];
                }

                return {
                    selectedChoices: {
                        ...newSelectedChoices,
                        [choiceId]: {id: choiceId, text,}
                    }
                }
            } else {
                if (selectedChoices[choiceId] && selectedChoices[choiceId].text === 'true')
                    return {selectedChoices: {}};
            }
            return {
                selectedChoices: {
                    [choiceId]: {id: choiceId, text,}
                }
            }
        }, () => {
            if (!this.props.multipleChoice) {
                let {choices, selectedChoices} = this.state;
                let textArray = [];
                choices.map(
                    choice => {
                        if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
                            textArray.push(choice.bot_field.field_id);
                        }
                    }
                );

                const text = textArray.join(',');
                this.handleSend(text);
            }
        });
    };

    handleSend = (text) => {
        const {choices, selectedChoices} = this.state;
        let selectedData = [];
        let unselectedData = [];

        choices.map(
            choice => {
                if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
                    selectedData.push(selectedChoices[choice.id]);
                } else {
                    unselectedData.push({id: choice.id, text: 'false'});
                }
            }
        );
        const data = [...selectedData, ...unselectedData];

        if (selectedData.length) {
            this.props.handleSendClick({children: JSON.stringify(data), text});
        }
    };

    render() {
        let {choices, selectedChoices} = this.state;
        const {edit, multipleChoice} = this.props;
        let textArray = [];

        choices = choices.sort((a, b) => (a.bot_field.priority - b.bot_field.priority));

        choices.map(
            choice => {
                if (selectedChoices[choice.id] && selectedChoices[choice.id].text === 'true') {
                    textArray.push(choice.bot_field.field_id);
                }
            }
        );

        const text = textArray.join(',');

        const sendButtonStyle = {
            opacity: Object.keys(selectedChoices).length > 0 ? 1 : 0
        };

        // console.warn(selectedChoices);

        return (
            <div className='choice-item-container'>
                {
                    multipleChoice &&
                    <small className="text-center">(select all that apply)</small>
                }
                <div className={`choice-item-content ${edit ? '' : 'clicked'}`}>
                    {
                        choices.map(
                            (choice, index) => {
                                let tempValue = false;
                                let files = choice.bot_field.bot_field_files;
                                let {[this.props.language]: placeholder = choice.bot_field.text} = choice.bot_field.languages;

                                if (selectedChoices[choice.id])
                                    tempValue = selectedChoices[choice.id].text;
                                let variant = tempValue === 'true' ? 'dark' : 'outline-dark';

                                return (
                                    <Button key={choice.id}
                                            variant={variant}
                                            className="mr-3 mt-3"
                                            onClick={() => this.handleChoiceClick(choice)}>
                                        {
                                            !!files &&
                                            !!files.length &&
                                            <div className="choice-item-image-container">
                                                <img style={{width: '100%'}} src={getHttpsImageUrl(files[0].file)}
                                                     alt={placeholder}/>
                                            </div>
                                        }
                                        <div className="choice-item-button-text">
                                            {placeholder}
                                        </div>
                                    </Button>
                                )
                            }
                        )
                    }
                </div>

                {
                    multipleChoice &&
                    Object.keys(selectedChoices).length > 0 &&
                    <div className="field-footer">
                        <div className="container d-flex justify-content-end pt-3">
                            <Button onClick={() => this.handleSend(text)} block>Next</Button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
