import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {fetchProducts} from "../../../actions/ecommerce/products";
import {createOrder, fetchOrderEstimate} from "../../../actions/ecommerce/orders";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import {getPrice} from "../../../constants";
import './styles.scss';
import {fetchActiveAgents} from "../../../actions/ecommerce/calls";


class OrderCreate extends React.Component {

    static propTypes = {
        show: PropTypes.bool,
        address: PropTypes.object,
        orderId: PropTypes.number,
        customerId: PropTypes.number,
        reportId: PropTypes.number,
        callSid: PropTypes.string,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        address: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {
                order_items: [{product_id: '', quantity: 1}],
                payment_type: 'cod',
                name: '',
                street: '',
                street2: '',
                landmark: '',
                city: '',
                state: '',
                zipcode: '',
                coupon: '',
                previous_order_id: '',
                report_id: '',
                email: '',
                created_by: props.profile.data.email
            },
            message: undefined,
            orderEstimate: false,
        };
    }

    componentDidMount() {
        const {products} = this.props;
        this.props.fetchActiveAgents();
        if (!products.list.length) {
            this.props.fetchProducts({});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {orderId, customerId, reportId, callSid, address, profile} = this.props;
        let {name = '', street = '', street2 = '', landmark = '', city = '', state = '', zipcode = '', email = ''} = address;
        if (this.props.show && !prevProps.show) {
            this.setState({
                form: {
                    order_items: [{product_id: '', quantity: 1}],
                    payment_type: 'cod',
                    name,
                    street,
                    street2,
                    landmark,
                    city,
                    state,
                    zipcode,
                    coupon: '',
                    previous_order_id: orderId || '',
                    customer_id: customerId || '',
                    report_id: reportId || '',
                    call_sid: callSid || '',
                    email: !!email ? email : '',
                    created_by: profile.data.email,
                },
                message: undefined,
                orderEstimate: false,
            });
        }

        const {orders: {isCreating, isFetchingEstimate, message, error, retry}, handleClose} = this.props;

        if (!isCreating && prevProps.orders.isCreating && !error && !retry) {
            handleClose();
        }
        if (!isFetchingEstimate && prevProps.orders.isFetchingEstimate && !error && !retry) {
            this.setState({orderEstimate: true});
        }
        if (!isCreating && prevProps.orders.isCreating && error && !retry) {
            this.setState({message});
        }
    }

    getOrderFormData = () => {
        let {form: data, form: {order_items}} = this.state;
        let newOrderItems = order_items.filter(o => !!o.product_id);
        return {...data, order_items: newOrderItems};
    }

    handleCreate = () => {
        this.props.createOrder(this.getOrderFormData());
    };

    handleEstimate = () => {
        this.props.fetchOrderEstimate(this.getOrderFormData());
    }

    handleFormChange = (form) => {
        this.setState({form});
    };

    handleAddItem = () => {
        let newItem = {product_id: '', quantity: 1};
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                order_items: [...prevState.form.order_items, newItem],
            }
        }));
    }

    handleItemChange = ({index, ...data}) => {
        console.warn(index, data);
        let order_items = this.state.form.order_items;
        order_items[index] = {...order_items[index], ...data};
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                order_items
            }
        }));
    }

    handleAgentSelect = (value) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                created_by: value
            }
        }));
    }

    handleDeleteItem = ({index}) => {
        let order_items = this.state.form.order_items;
        order_items.splice(index, 1);
        this.setState(prevState => ({form: {...prevState.form, order_items,}}));
    }

    paymentType = [
        {label: 'COD', value: 'cod'},
        {label: 'Online', value: 'online'}
    ]

    render() {
        const {products, show, handleClose, orders: {isCreating, isFetchingEstimate, estimate}, calls: {activeAgents}, profile} = this.props;
        let {form, form: {name, street, street2, landmark, city, state, zipcode,
            order_items, payment_type, email, coupon, created_by}, message, orderEstimate} = this.state;

        const allAgents = [{email: profile.data.email, user: profile.data.id}, ...activeAgents].map(user => ({
            label: `${user.email}`,
            value: user.email
        }));
        const selectedAgent = allAgents.filter(agent => agent.value === created_by)[0] || ''

        return (
            <Modal show={show} onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <div className="row mb-2">
                            <div className="col-12 text-right">
                                {(!order_items.length || !!order_items[order_items.length - 1].product_id) &&
                                <Button variant="primary" size="sm" onClick={this.handleAddItem}>Add More Item</Button>}
                            </div>
                        </div>
                        <div className="order-create-modal-table-container">
                            <Table className="order-create-modal-table" striped bordered>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {order_items.map((item, index) => {
                                    let filteredProducts = products.list.filter(p => item.product_id === p.id);
                                    let selectedValue = {label: 'Select One', value: ''};
                                    if (filteredProducts.length) {
                                        selectedValue = {
                                            label: `${filteredProducts[0].product_id} - ${filteredProducts[0].title}`,
                                            value: filteredProducts[0].id
                                        };
                                    }
                                    return (
                                      <tr key={index + 1}>
                                          <th style={{width: '20px'}}>#{index + 1}</th>
                                          <td style={{minWidth: '100px'}}>
                                              <Form.Group className="mb-0" controlId="items_select">
                                                  <Select placeholder="Select Product" isMulti={false}
                                                          options={products.list.map(p => ({
                                                              label: `${p.product_id} - ${p.title}`,
                                                              value: p.id
                                                          }))}
                                                          className={!!message && !!message.order_items && 'form-control is-invalid p-0'}
                                                          value={selectedValue}
                                                          onChange={({value}) => this.handleItemChange({product_id: value, index})}/>
                                              </Form.Group>
                                          </td>
                                          <td style={{width: '80px'}}>
                                              <Form.Group className="mb-0" controlId="qty">
                                                  <Form.Control type="number" placeholder="quantity"
                                                                value={item.quantity || ''}
                                                                onChange={(e) => this.handleItemChange({quantity: e.target.value, index})}/>
                                              </Form.Group>
                                          </td>
                                          <td style={{width: '60px'}}>
                                              {item.product_id &&
                                              <Button variant="danger" size="sm" className="my-2"
                                                      onClick={() => this.handleDeleteItem({index})}>
                                                  Delete
                                              </Button>}
                                          </td>
                                      </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                        <Form.Group as={Col} controlId="payment_type">
                            <Form.Label className="mb-0">Payment type*</Form.Label>
                            <Select placeholder="Select Payment Type"
                                    options={this.paymentType.map(type => type)}
                                    className={!!message && !!message.payment_type && 'form-control is-invalid p-0'}
                                    value={this.paymentType.filter(type => type.value === payment_type)[0] || ''}
                                    onChange={(p) => this.handleFormChange({
                                        ...form,
                                        payment_type: p.value
                                    })}/>
                            <Form.Control type="hidden" isInvalid={!!message && !!message.payment_type}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.payment_type}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formName">
                            <Form.Label className="mb-0">Name*</Form.Label>
                            <Form.Control type="text" placeholder="Name"
                                          isInvalid={!!message && !!message.name}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              name: e.target.value
                                          })}
                                          value={name}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label className="mb-0">Email</Form.Label>
                            <Form.Control type="email" placeholder="Email"
                                          isInvalid={!!message && !!message.email}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              email: e.target.value
                                          })}
                                          value={email}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formStreet">
                        <Form.Label className="mb-0">Flat, House no, Building, Company, Apartments*</Form.Label>
                        <Form.Control type="text" placeholder="Flat, House no, Building, Company, Apartments"
                                      isInvalid={!!message && !!message.street}
                                      onChange={(e) => this.handleFormChange({
                                          ...form,
                                          street: e.target.value
                                      })}
                                      value={street}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.street}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formStreet2">
                        <Form.Label className="mb-0">Area, Colony, Street, Sector, Village</Form.Label>
                        <Form.Control type="text" placeholder="Area, Colony, Street, Sector, Village"
                                      isInvalid={!!message && !!message.street2}
                                      onChange={(e) => this.handleFormChange({
                                          ...form,
                                          street2: e.target.value
                                      })}
                                      value={street2}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.street2}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formState">
                            <Form.Label className="mb-0">State*</Form.Label>
                            <Form.Control type="text" placeholder="State"
                                          isInvalid={!!message && !!message.state}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              state: e.target.value
                                          })}
                                          value={state}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.state}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formCity">
                            <Form.Label className="mb-0">City*</Form.Label>
                            <Form.Control type="text" placeholder="City"
                                          isInvalid={!!message && !!message.city}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              city: e.target.value
                                          })}
                                          value={city}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.city}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formZipcode">
                            <Form.Label className="mb-0">Pincode*</Form.Label>
                            <Form.Control type="number" placeholder="Pincode"
                                          isInvalid={!!message && !!message.zipcode}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              zipcode: e.target.value
                                          })}
                                          value={zipcode}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.zipcode}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="landmark">
                            <Form.Label className="mb-0">Landmark</Form.Label>
                            <Form.Control type="text" placeholder="e.g. Near AIMS Fliover, near pipal ka ped"
                                          isInvalid={!!message && !!message.landmark}
                                          onChange={(e) => this.handleFormChange({
                                              ...form,
                                              landmark: e.target.value
                                          })}
                                          value={landmark}/>
                            <Form.Control.Feedback type="invalid">
                                {!!message && message.landmark}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formCoupon">
                        <Form.Label className="mb-0">Coupon</Form.Label>
                        <Form.Control type="text" placeholder="Coupon"
                                      isInvalid={!!message && !!message.coupon}
                                      onChange={(e) => this.handleFormChange({
                                          ...form,
                                          coupon: e.target.value
                                      })}
                                      value={coupon}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.coupon}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="remarks">
                        <Form.Label>Created by Agent</Form.Label>
                        <Form.Group className="mb-0" controlId="items_select">
                            <Select placeholder="Select Agent" isMulti={false}
                                    options={allAgents}
                                    className={!!message && !!message.order_items && 'form-control is-invalid p-0'}
                                    value={selectedAgent}
                                    onChange={({value}) => this.handleAgentSelect(value)}/>
                        </Form.Group>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <div className="row flex-fill">
                        <div className="col-12 col-md-6">
                            {!!orderEstimate && <React.Fragment>
                                {(estimate.total_mrp !== estimate.subtotal.subtotal) ?
                                    <div className="d-flex">
                                        <p className="text mb-0 mr-3 strike">
                                            Total MRP: ₹{getPrice(estimate.total_mrp)}</p>
                                        <p className="text mb-0">Sub Total: ₹{getPrice(estimate.subtotal.subtotal)}</p>
                                    </div>
                                    :
                                    <p className="text mb-0">Total MRP: ₹{getPrice(estimate.total_mrp)}</p>
                                }
                            </React.Fragment>}
                        </div>

                        <div className="col-12 col-md-6 text-right pb-5">
                            <Button className={`${isFetchingEstimate ? 'disabled' : ''} my-2`} variant="info"
                                    disabled={isFetchingEstimate}
                                    onClick={this.handleEstimate} style={{minWidth: '100px'}}>
                                {isFetchingEstimate ? <Spinner size="sm" animation="border"/> : "Get Estimate"}
                            </Button>

                            <Button className={`mx-2 ${isCreating ? 'disabled' : ''} my-2`} variant="success"
                                    disabled={isCreating}
                                    onClick={this.handleCreate} style={{minWidth: '100px'}}>
                                {isCreating ? <Spinner size="sm" animation="border"/> : "Create"}
                            </Button>
                            <Button className='my-2' variant="danger" onClick={handleClose}>Close</Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    products: state.ecommerce.products,
    orders: state.ecommerce.orders,
    calls: state.ecommerce.calls,
    profile: state.accounts.profile,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchProducts,
    createOrder,
    fetchOrderEstimate,
    fetchActiveAgents,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderCreate);
