export const FETCH_QUIZZERS = 'FETCH_QUIZZERS';
export const FETCH_QUIZZERS_RESULT = 'FETCH_QUIZZERS_RESULT';
export const FETCH_QUIZZERS_ERROR = 'FETCH_QUIZZERS_ERROR';
export const FETCH_QUIZZERS_RETRY = 'FETCH_QUIZZERS_RETRY';

export const FETCH_QUIZZER_INFO = 'FETCH_QUIZZER_INFO';
export const FETCH_QUIZZER_INFO_RESULT = 'FETCH_QUIZZER_INFO_RESULT';
export const FETCH_QUIZZER_INFO_ERROR = 'FETCH_QUIZZER_INFO_ERROR';
export const FETCH_QUIZZER_INFO_RETRY = 'FETCH_QUIZZER_INFO_RETRY';


export const fetchQuizzers = (data) => ({
    type: FETCH_QUIZZERS,
    data,
});

export const fetchQuizzerInfo = (data) => ({
    type: FETCH_QUIZZER_INFO,
    data,
});