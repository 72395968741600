export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_RESULT = 'FETCH_PRODUCTS_RESULT';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCTS_RETRY = 'FETCH_PRODUCTS_RETRY';


export const fetchProducts = (data) => ({
    type: FETCH_PRODUCTS,
    data
});
