import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import {createScheduledCall, updateScheduledCall} from "../../../actions/ecommerce/scheduledcalls";
import {fetchActiveAgents} from "../../../actions/ecommerce/calls";


class ScheduleCallModal extends React.Component {

    static propTypes = {
        data: PropTypes.object,
        scheduledcalls: PropTypes.object,
        fetchActiveAgents: PropTypes.func,
        updateScheduledCall: PropTypes.func,
        createScheduledCall: PropTypes.func,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        data: {},
        show: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
        };
    }

    componentDidMount() {
        this.props.fetchActiveAgents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {data, scheduledcalls} = this.props;

        if (this.props.show && !prevProps.show) {
            this.setState({data});
        }
        if (!scheduledcalls.isCreating && prevProps.scheduledcalls.isCreating && !scheduledcalls.error && !scheduledcalls.retry) {
            this.props.handleClose();
        }
        if (!scheduledcalls.isUpdating && prevProps.scheduledcalls.isUpdating && !scheduledcalls.error && !scheduledcalls.retry) {
            this.props.handleClose();
        }
    }

    handleSubmit = () => {
        let {data} = this.state;
        if (data.id) {
            this.props.updateScheduledCall(data);
        }
        else {
            this.props.createScheduledCall(data);
        }
    }

    render() {
        let {show, handleClose, scheduledcalls: {message},
            calls: {activeAgents}} = this.props;
        let {data} = this.state;

        return (
            <Modal size={'md'} show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule a call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="remarks">
                        <Form.Label>Schedule For</Form.Label>
                        <select name="call_type" id="call_type"
                                defaultValue={''}
                                onChange={(e) => this.setState({
                                    data: {
                                        ...data,
                                        user: e.target.value,
                                    }
                                })}>
                            <option value={''}>-</option>
                            {
                                activeAgents.map(
                                    (activeAgent) => (
                                        <option value={activeAgent.user} key={activeAgent.id}>{activeAgent.email}</option>
                                    )
                                )
                            }
                        </select>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.call_type}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="dateTime">
                        <Form.Label>Date / time *</Form.Label>
                        <br/>
                        <DatePicker className='form-control' timeClassName="date-picker-time"
                                    placeholderText="Schedule time"
                                    dateFormat="Pp"
                                    timeIntervals={15}
                                    selected={!!data.schedule_time ? new Date(data.schedule_time) : ''}
                                    showTimeSelect
                                    onChange={(date) => this.setState({
                                        data: {
                                            ...data,
                                            schedule_time: moment(date).format(),
                                        }
                                    })}
                        />
                    </Form.Group>
                    <Form.Group controlId="remarks">
                        <Form.Label>Remarks*</Form.Label>
                        <Form.Control as="textarea" rows="3"
                                      value={data.remarks}
                                      isInvalid={!!message && !!message.remarks}
                                      onChange={(e) => this.setState({
                                          data: {
                                              ...data,
                                              remarks: e.target.value,
                                          }
                                      })}/>
                        <Form.Control.Feedback type="invalid">
                            {!!message && message.remarks}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleSubmit}>Save</Button>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    scheduledcalls: state.ecommerce.scheduledcalls,
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    updateScheduledCall,
    createScheduledCall,
    fetchActiveAgents,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ScheduleCallModal);
