import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import moment from "moment";
import SocketIOClient from "socket.io-client";
import {Form, InputGroup, Spinner, Button, Table} from "react-bootstrap";
import {toast} from "react-toastify";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {fetchRepeatOrders} from "../../actions/ecommerce/orders";
import {
    getCallAttemptsBackgroundColor, isObjectsEqual,
    getPrice, getQueryString,
    getSearchParams,
    NODE_SERVER_HOST, orderStatuses, PaymentType,
    scrollToTop, getIndexCount, orderType, RANGE
} from "../../constants";
import PaginationComponent from "../../components/others/PaginationComponent";
import Select from "react-select";
import OrdersColorCoding from "../../components/others/OrdersColorCoding";
import DateRange from "../../components/others/DateRange";
import CallConnectedRange from "../../components/others/CallConnectedRange";


class RepeatOrders extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        fetchRepeatOrders: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {
            page = '1',
            q = '',
            payment_type = '',
            order_type = 'single_order',
            from_date = '', to_date = '',
            status_from_date = '',
            status_to_date = '',
            from_connected_calls = '',
            to_connected_calls = '',
            order_history_count = '',
            attempted_calls = '',
        } = getSearchParams(search);

        this.state = {
            order: null,

            filters: {
                payment_type,
                order_type,
                from_date,
                to_date,
                from_connected_calls,
                to_connected_calls,
                order_history_count,
                status_from_date,
                status_to_date,
                attempted_calls,
                status: 'delivered',
                q,
                page: parseInt(page),
            }
        };
    }

    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999
    });

    componentDidMount() {
        this.handleOrderUpdates();
        this.setSocketListeners();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetchingRepeat, error, retry} = this.props.orders;

        let {filters} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isObjectsEqual(queryParams, prevQueryParams)) {
            this.setState({
                filters: {
                    ...filters,
                    payment_type: queryParams.payment_type || '',
                    order_type: queryParams.order_type || '',
                    from_date: queryParams.from_date || '',
                    to_date: queryParams.to_date || '',
                    from_connected_calls: queryParams.from_connected_calls || '',
                    to_connected_calls: queryParams.to_connected_calls || '',
                    order_history_count: queryParams.order_history_count || '',
                    status_from_date: queryParams.status_from_date || '',
                    status_to_date: queryParams.status_to_date || '',
                    attempted_calls: queryParams.attempted_calls || '',
                    q: queryParams.q || '',
                    page: parseInt(queryParams.page || "1"),
                },
            }, () => this.handleOrderUpdates());
        }

        if (!isFetchingRepeat && prevProps.orders.isFetchingRepeat && !error && !retry) {
            scrollToTop();
        }
    }

    handleChangeFilter = (filters) => {
        this.setState({filters});
    }

    handleChangePagination = (filters) => {
        this.setState({filters}, () => this.handleSubmitFilters());
    }

    handleSubmitFilters = (e) => {
        !!e && e.preventDefault();
        let {filters} = this.state;
        this.props.history.push(`/orders/repeat/?${getQueryString(filters)}`);
    }

    handleOrderUpdates = () => {
        let {filters} = this.state;
        this.props.fetchRepeatOrders(filters);
    };

    setSocketListeners = () => {
        this.socket.on('DOCTOR:ORDERS', this.handleOrderUpdates);
        this.socket.on('reconnect', (attemptNumber) => {
            this.handleOrderUpdates();
            toast("Connected Successfully",);
        });
        this.socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.socket.connect();
            }
            this.setState({connected: false});
            toast("Connectivity lost",);
        });
    };

    handleClickInfo = (order) => {
        let {history} = this.props
        history.push(`/orders/${order.id}`)
    }

    render() {
        let {orders: {repeatList: {results = [], count}, isFetchingRepeat}} = this.props;
        let {filters, filters: {q, page, payment_type, order_type, from_date, status_from_date,
            status_to_date, to_date, from_connected_calls, to_connected_calls, order_history_count, attempted_calls}} = this.state;

        return (
            <SidebarContainer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <MainNavbar pageName={'Repeat Orders'}/>

                            <form className="row" onSubmit={this.handleSubmitFilters}>
                                <div className="col-12">
                                    <Form.Group className="m-0">
                                        <Form.Control type="text" className="custom-form-control mr-2"
                                                      value={q}
                                                      placeholder="Search Order..."
                                                      onChange={(e) => this.handleChangeFilter({
                                                          ...filters,
                                                          page: 1,
                                                          q: e.target.value
                                                      })}/>
                                    </Form.Group>
                                </div>
                                <div className="col-6 mt-2">
                                    <Form.Group controlId="fieldId" className="m-0">
                                        <Form.Label className="custom-form-label">Payment Type</Form.Label>
                                        <Select options={PaymentType.map(type => type)}
                                                className="custom-form-control" placeholder="Select Payment Type"
                                                value={PaymentType.filter(type => type.value === payment_type)[0]}
                                                isClearable={true}
                                                onChange={(type) => this.handleChangeFilter({
                                                    ...filters,
                                                    page: 1,
                                                    payment_type: type ? type.value : ''
                                                })}/>
                                    </Form.Group>
                                </div>

                                <div className="col-6 mt-2">
                                    <Form.Group controlId="orderType" className="m-0">
                                        <Form.Label className="custom-form-label">Order Type</Form.Label>
                                        <Select options={orderType.map(type => type)}
                                                className="custom-form-control" placeholder="Select Order Type"
                                                value={orderType.filter(type => type.value === order_type)[0]}
                                                isClearable={true}
                                                onChange={(type) => this.handleChangeFilter({
                                                    ...filters,
                                                    page: 1,
                                                    order_type: type ? type.value : ''
                                                })}/>
                                    </Form.Group>
                                </div>

                                <div className="col-12 col-sm-6 mt-2">
                                    <DateRange fromDate={from_date} toDate={to_date}
                                               handleChangeFromDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   from_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}
                                               handleChangeToDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   to_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}/>
                                    <DateRange fromDate={status_from_date} toDate={status_to_date}
                                               label={'Order Status Date'}
                                               handleChangeFromDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   status_from_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}
                                               handleChangeToDate={date => this.handleChangeFilter({
                                                   ...filters,
                                                   page: 1,
                                                   status_to_date: !!date ? moment(date).format('YYYY-MM-DDT00:00:00') : ''
                                               })}/>
                                </div>

                                <div className="col-12 col-sm-6 mt-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <CallConnectedRange startRange={from_connected_calls}
                                                                endRange={to_connected_calls}
                                                                handleChangeStartRange={range => this.handleChangeFilter({
                                                                    ...filters,
                                                                    page: 1,
                                                                    to_connected_calls: !!range ? range.value : '',
                                                                    from_connected_calls: !!range ? range.value : '',
                                                                })}
                                                /* handleChangeEndRange={range => this.handleChangeFilter({
                                                     ...filters,
                                                     page: 1,
                                                     to_connected_calls: !!range ? range.value : ''
                                                 })}*/
                                            />
                                        </div>
                                        <div className="col-6 pl-1">
                                            <Form.Group controlId="fieldId" className="m-0">
                                                <Form.Label className="custom-form-label">Call Attempted</Form.Label>
                                                <Select options={RANGE.map(range => ({label: range, value: range}))}
                                                        className="custom-form-control" isClearable
                                                        value={RANGE.filter(range => range === attempted_calls).map(range => ({
                                                            label: range,
                                                            value: range
                                                        }))}
                                                        onChange={range => this.handleChangeFilter({
                                                            ...filters,
                                                            page: 1,
                                                            attempted_calls: !!range ? range.value : '',
                                                        })}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-6">
                                            <Form.Group controlId="fieldId" className="m-0">
                                                <Form.Label className="custom-form-label">History Count</Form.Label>
                                                <Select options={RANGE.map(range => ({label: range, value: range}))}
                                                        className="custom-form-control" isClearable
                                                        value={RANGE.filter(range => range === order_history_count).map(range => ({
                                                            label: range,
                                                            value: range
                                                        }))}
                                                        onChange={range => this.handleChangeFilter({
                                                            ...filters,
                                                            page: 1,
                                                            order_history_count: !!range ? range.value : '',
                                                        })}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    <Button className="custom-button py-2" type="submit" variant="success" size="sm"
                                            style={{maxWidth: '200px', width: '100%'}}>
                                        {isFetchingRepeat ?
                                            <Spinner size="sm" animation="border"/> :
                                            "Apply Filter"
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div>

                        <div className="col-12 col-md-6 offset-md-1 mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <OrdersColorCoding/>
                                </div>

                                {!!count &&
                                <div className="col-12 mt-4">
                                    <PaginationComponent className="m-0" page={parseInt(page)} count={parseInt(count)}
                                                         perPage={10}
                                                         onClick={(page) => this.handleChangePagination({
                                                             ...filters,
                                                             page,
                                                         })}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>


                    <div className="row pt-3">
                        <div className="col-12">
                            <Table className="order-list text-unselectable" responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order ID</th>
                                    <th>Products</th>
                                    <th>Order Date</th>
                                    <th className="text-center">Status</th>
                                    <th>Status Date</th>
                                    <th className="text-center">Payment Type</th>
                                    <th className="text-center">Name</th>
                                    <th className="text-center">Order History Count</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Call Attempts</th>
                                    <th className="text-center">Call Connected</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (order, index) => (
                                            <tr key={order.cid} onClick={() => this.handleClickInfo(order)}
                                                className="cursor-pointer"
                                                style={{backgroundColor: getCallAttemptsBackgroundColor(order)}}>
                                                <td>{getIndexCount(index, page, 10)}</td>
                                                <td>{order.cid}</td>
                                                <td className='order-products'>
                                                    <ul>
                                                        {
                                                            order.order_items.map(
                                                                (item, index) => (
                                                                    <li key={index}>
                                                                        {item.quantity} | {item.product_id}
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </td>
                                                <td>
                                                    {moment(order.created_at).format("D MMMM YYYY,")}
                                                    <br/>
                                                    {moment(order.created_at).format("h:mm:ss a")}
                                                </td>
                                                <td className="text-center">
                                                    {order.status.replace(/_/g, ' ')}
                                                </td>
                                                <td>
                                                    {moment(order.status_created_at).format("D MMMM YYYY,")}
                                                    <br/>
                                                    {moment(order.status_created_at).format("h:mm:ss a")}
                                                </td>
                                                <td className="text-center">
                                                    {order.payment_type}
                                                </td>
                                                <td className="text-center">
                                                    {order.shipping_address.name}
                                                </td>
                                                <td className="text-center">
                                                    {order.order_history_count}
                                                </td>
                                                <td className="text-center">
                                                    ₹{order.total}
                                                </td>
                                                <td className="text-center">
                                                    {order.attempted_calls}
                                                </td>
                                                <td className="text-center">
                                                    {order.connected_calls}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.ecommerce.orders,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchRepeatOrders,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(RepeatOrders);
