import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_RESULT,
    FETCH_PRODUCTS_ERROR,
    FETCH_PRODUCTS_RETRY,
} from '../../actions/ecommerce/products';


const initialState = {
    detail: {},
    list: [],
    isFetching: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_PRODUCTS_RESULT:
            return {...state, isFetching: false, list: action.data};
        case FETCH_PRODUCTS_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_PRODUCTS_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};
        default:
            return state;
    }
};

export default reducer;
