import {
    CREATE_ANSWER,
    CREATE_ANSWER_RESULT,
    CREATE_ANSWER_ERROR,
    CREATE_ANSWER_RETRY,

    UPDATE_ANSWER,
    UPDATE_ANSWER_RESULT,
    UPDATE_ANSWER_ERROR,
    UPDATE_ANSWER_RETRY,

    CREATE_REPORT,
    CREATE_REPORT_RESULT,
    CREATE_REPORT_ERROR,
    CREATE_REPORT_RETRY,

    CREATE_REPORT_FOR_QUIZ_TAKER,
    CREATE_REPORT_FOR_QUIZ_TAKER_RESULT,
    CREATE_REPORT_FOR_QUIZ_TAKER_ERROR,
    CREATE_REPORT_FOR_QUIZ_TAKER_RETRY,
} from '../../actions/quiz/answers';


const initialState = {
    detail: {},
    report: {},
    isCreating: false,
    isCreatingReport: false,
    isUpdating: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state=initialState, action) => {
    switch (action.type) {
        case CREATE_ANSWER:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_ANSWER_RESULT:
            return {...state, isCreating: false, detail: action.data};
        case CREATE_ANSWER_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_ANSWER_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case UPDATE_ANSWER:
            return {...state, isUpdating: true, error: false, message: null, retry: false};
        case UPDATE_ANSWER_RESULT:
            return {...state, isUpdating: false, detail: action.data};
        case UPDATE_ANSWER_ERROR:
            return {...state, isUpdating: false, error: true, message: action.message};
        case UPDATE_ANSWER_RETRY:
            return {...state, isUpdating: false, retry: true, message: action.message};

        case CREATE_REPORT:
            return {...state, isCreatingReport: true, error: false, message: null, retry: false};
        case CREATE_REPORT_RESULT:
            return {...state, isCreatingReport: false, report: action.data};
        case CREATE_REPORT_ERROR:
            return {...state, isCreatingReport: false, error: true, message: action.message};
        case CREATE_REPORT_RETRY:
            return {...state, isCreatingReport: false, retry: true, message: action.message};

        case CREATE_REPORT_FOR_QUIZ_TAKER:
            return {...state, isCreatingReport: true, error: false, message: null, retry: false};
        case CREATE_REPORT_FOR_QUIZ_TAKER_RESULT:
            return {...state, isCreatingReport: false, report: action.data};
        case CREATE_REPORT_FOR_QUIZ_TAKER_ERROR:
            return {...state, isCreatingReport: false, error: true, message: action.message};
        case CREATE_REPORT_FOR_QUIZ_TAKER_RETRY:
            return {...state, isCreatingReport: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
