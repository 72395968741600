import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


class OrderConfirm extends React.Component {

    static propTypes = {
        order: PropTypes.any,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        handleConfirm: PropTypes.func,
    };

    state = {
        orderId: '',
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.show && !prevProps.show) {
            this.setState({
                orderId: '',
            });
        }
    }

    handleConfirm = () => {
        let {order, handleConfirm} = this.props;

        if (order.cid === this.state.orderId) {
            handleConfirm({id: order.id})
        }
    }

    render() {
        let {order, show, handleClose} = this.props;
        if (!order)
            order = {};

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Are you sure you want to confirm this order?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>If yes then type the order ID - {order.cid} below and press Confirm Order</p>
                    <Form.Control size="md" type="text" placeholder="Order ID"
                                  value={this.state.orderId}
                                  onChange={(e) => this.setState({orderId: e.target.value})}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            className={`mr-2 ${!(order.cid === this.state.orderId) ? 'disabled' : ''}`}
                            onClick={this.handleConfirm}>
                        Confirm
                    </Button>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({});

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(OrderConfirm);
