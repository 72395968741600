import {call, put} from 'redux-saga/effects';
import {
    FETCH_QUIZZERS_RESULT,
    FETCH_QUIZZERS_ERROR,
    FETCH_QUIZZERS_RETRY,

    FETCH_QUIZZER_INFO_RESULT,
    FETCH_QUIZZER_INFO_ERROR,
    FETCH_QUIZZER_INFO_RETRY,
} from '../../actions/quiz/quizzers';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchQuizzers(action) {
    try {
        const response = yield call(api.quiz.quizzers.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchQuizzers', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_QUIZZERS_RESULT, data: result});
        }
        else {
            if (result.message)
                toast(result.message, );
            yield put({type: FETCH_QUIZZERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_QUIZZERS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchQuizzerInfo(action) {
    try {
        const response = yield call(api.quiz.quizzers.info, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchQuizzerInfo', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_QUIZZER_INFO_RESULT, data: result});
        } else {
            yield put({type: FETCH_QUIZZER_INFO_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_QUIZZER_INFO_RETRY, retry: true, message: e.message});
    }
}

