// External Imports
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from "react-datepicker";
// My Imports
import {fieldTypes} from '../../../constants';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const {INTEGER, TEXT, FLOAT, DATE} = fieldTypes;


export default class SingleInput extends Component {


    static propTypes = {
        placeholder: PropTypes.string,
        inputType: PropTypes.oneOf(Object.values(fieldTypes)),
        handleSendClick: PropTypes.func,
        edit: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: 'answer here',
        inputType: 'text',
        edit: true,
    };

    state = {
        value: '',
        startDate: null,
    };

    componentDidMount() {
    };

    onChangeText = (value) => {

        const condition = (
            (this.props.inputType === INTEGER && /^[0-9]*$/.test(value)) ||
            (this.props.inputType === FLOAT && /^[0-9.]*$/.test(value)) ||
            (this.props.inputType === TEXT)
        );
        // console.warn(value);
        if (condition) {
            this.setState({value});
        }
    };
    onChangeDate = (date) => {
        this.setState({startDate: date});
        this.setDate(date);
    };

    setDate = (value) => {
        this.setState({value: moment(value).format('YYYY-MM-DD')});
    };

    handleSend = () => {
        if (this.state.value.length > 0) {
            this.props.handleSendClick({text: this.state.value})
        }
    };

    render() {
        const {placeholder, inputType} = this.props;
        const {edit} = this.props;
        let keyboardType = 'text';

        switch (inputType) {
            case INTEGER:
                keyboardType = 'number';
                break;
            case FLOAT:
                keyboardType = 'number';
                break;
            case DATE:
                keyboardType = 'date';
                break;
        }

        return (
            <div className='single-input-card-container'>
                {
                    edit &&
                    (inputType === INTEGER || inputType === FLOAT || inputType === TEXT) &&
                    <Form.Group>
                        <Form.Control placeholder={placeholder}
                                      value={this.state.value}
                                      onChange={(e) => this.onChangeText(e.target.value)}
                                      type={keyboardType}
                                      onKeyPress={(event) => {
                                          if (event.key === "Enter") {
                                              this.handleSend()
                                          }
                                      }}/>
                    </Form.Group>
                }
                {
                    edit &&
                    inputType === DATE &&
                    <DatePicker
                        className='date-input'
                        placeholderText='Select Date'
                        selected={this.state.startDate}
                        onChange={(date) => this.onChangeDate(date)}
                    />
                }
                {!edit && <p>{this.state.value}</p>}
                {
                    edit && this.state.value.length > 0 &&
                    <div className="field-footer">
                        <div className="container d-flex justify-content-end">
                            <Button variant={'dark'} onClick={this.handleSend}>
                                Next
                            </Button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
