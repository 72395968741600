//External Imports
import {combineReducers} from 'redux';
//My Imports
import orders from './orders';
import products from './products';
import pharmacy from './pharmacy';
import calls from './calls';
import scheduledcalls from './scheduledcalls';
import reports from './reports';
import coupons from './coupons';
import customers from './customers';
import shift from './shift';


export default combineReducers({
    orders,
    products,
    pharmacy,
    calls,
    scheduledcalls,
    reports,
    coupons,
    customers,
    shift,
})
