import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import moment from "moment";
import {Spinner} from "react-bootstrap";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {
    cancelOrder,
    confirmOrder,
    convertToOnline,
    refundOrder,
    resendPaymentLink,
    sendAyurvedicQuizLink,
} from "../../actions/ecommerce/orders";
import {createCall} from "../../actions/ecommerce/calls";
import {
    AYURVEDIC_QUIZ_BOT_FORM_ID,
    AYURVEDIC_QUIZ_FIELD_ID,
} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import CallDetailForm from "../../components/forms/CallDetailForm";
import OrderDetailHeader from "../../components/others/OrderDetailHeader";
import OrderDetailButtons from "../../components/others/OrderDetailButtons";
import CallHistory from "../../components/others/CallHistory";
import OrderHistoryTable from "../../components/others/OrderHistoryTable";
import ScheduleCallModal from "../../components/modals/ScheduleCallModal";
import CallDetailModal from "../../components/modals/CallDetailModal";
import OrderStatusModal from "../../components/modals/OrderStatusModal";
import OrderCreate from "../../components/modals/OrderCreate";
import CouponModal from "../../components/modals/CouponModal";
import QuizModal from "../../components/modals/QuizModal";
import {fetchCustomer} from "../../actions/ecommerce/customers";
import {debounce} from "../../utils";


class CustomerDetail extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        fetchCustomer: PropTypes.func,
        confirmOrder: PropTypes.func,
        cancelOrder: PropTypes.func,
        createCall: PropTypes.func,
        refundOrder: PropTypes.func,
        convertToOnline: PropTypes.func,
        resendPaymentLink: PropTypes.func,
        sendAyurvedicQuizLink: PropTypes.func,
    };

    state = {
        showConfirm: false,
        showCancel: false,
        showStatuses: false,
        showCreate: false,
        showCheckout: false,
        order: null,
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.props.fetchCustomer({id: params.customerId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            detail, isFetching, isUpdatingAddress, isConfirming, isRefunding, isCreating,
            isFetchingConvertToOnline, isFetchingResendPaymentLink, isSendingAyurvedicQuizLink, isSendingCheckoutLink, isCanceling, error, retry
        } = this.props.orders;
        let {calls, match: {params}} = this.props;

        if (params.customerId !== prevProps.match.params.customerId) {
            this.props.fetchCustomer({id: params.customerId});
        }

        if (!isCreating && prevProps.orders.isCreating && !error && !retry) {
            toast("Order created");
            this.handleCustomerUpdates();
        }

        if (!isConfirming && prevProps.orders.isConfirming && !error && !retry) {
            toast("Order confirmed",);
            this.setState({showConfirm: false, order: null});
            this.handleCustomerUpdates();
        }

        if (!isCanceling && prevProps.orders.isCanceling && !error && !retry) {
            toast("Order cancelled");
            this.setState({showCancel: false, showConfirm: false, order: null});
            this.handleCustomerUpdates();
        }

        if (!isRefunding && prevProps.orders.isRefunding && !error && !retry) {
            toast("Order refund initiated");
            this.handleCustomerUpdates();
        }

        if (!calls.isCreating && prevProps.calls.isCreating && !calls.error && !calls.retry) {
            toast("Call Created");
            this.handleCustomerUpdates();
        }

        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            toast("Call Detail Updated");
            this.handleCustomerUpdates();
        }

        if (!isUpdatingAddress && prevProps.orders.isUpdatingAddress && !error && !retry) {
            toast("Address Updated");
            this.handleCustomerUpdates();
        }

        if (!isFetchingConvertToOnline && prevProps.orders.isFetchingConvertToOnline && !error && !retry) {
            toast("Converted to online");
            this.handleCustomerUpdates();
        }

        if (!isFetchingResendPaymentLink && prevProps.orders.isFetchingResendPaymentLink && !error && !retry) {
            toast("Resend payment link");
            this.handleCustomerUpdates();
        }

        if (!isSendingAyurvedicQuizLink && prevProps.orders.isSendingAyurvedicQuizLink && !error && !retry) {
            toast("Ayurvedic quiz link successfully sent");
            this.handleCustomerUpdates();
        }

        if (!isSendingCheckoutLink && prevProps.orders.isSendingCheckoutLink && !error && !retry) {
            toast("Checkout link successfully sent");
            this.setState({showCheckout: false})
            this.handleCustomerUpdates();
        }
    }

    handleCustomerUpdates = () => {
        let {params} = this.props.match;
        this.props.fetchCustomer({id: params.customerId});
    };

    handleCancel = ({id, message}) => {
        this.props.cancelOrder({id, message});
    };

    handleConfirm = (order) => {
        this.props.confirmOrder(order);
    };

    handleMakeCall = debounce((customer, call_type) => {
        this.props.createCall({customer_id: customer.id, call_type});
    }, 500);

    handleRefund = () => {
        let {orders: {detail}} = this.props;
        this.props.refundOrder({id: detail.id});
    };

    handleConvertToOnline = () => {
        let {orders: {detail}} = this.props;
        this.props.convertToOnline({id: detail.id});
    };

    handleResendPaymentLink = () => {
        let {orders: {detail}} = this.props;
        this.props.resendPaymentLink({id: detail.id});
    };

    handleSendAyurvedicQuizLink = () => {
        let {orders: {detail}} = this.props;
        this.props.sendAyurvedicQuizLink({order_id: detail.id});
    };

    render() {
        let {customers: {detail, isFetching, detail: {shipping_address = {},
            support_calls = [], orders_history = [], order_statuses = []}}} = this.props;

        if (!shipping_address) {shipping_address = {}}

        if (isFetching) {
            return (
                <div className="text-center py-5">
                    <Spinner size="sm" animation="border"/>
                </div>
            )
        }
        return (
            <SidebarContainer>
                <OrderCreate show={this.state.showCreate} customerId={detail.id} address={shipping_address}
                             handleClose={() => this.setState({showCreate: false})}/>

                <CouponModal show={this.state.showCheckout} customerId={detail.id}
                             handleClose={() => this.setState({showCheckout: false})}/>

                <OrderStatusModal show={this.state.showStatuses} orderStatus={order_statuses}
                                  handleClose={() => this.setState({showStatuses: false})}/>
                {
                    !!this.state.callDetail &&
                    <CallDetailModal show={true} callDetail={this.state.callDetail}
                                     handleClose={() => this.setState({callDetail: undefined})}/>
                }
                {
                    !!this.state.scheduledCall &&
                    <ScheduleCallModal show={true} data={this.state.scheduledCall}
                                       handleClose={() => this.setState({scheduledCall: undefined})}/>
                }
                {
                    !!this.state.showAyurvedicQuiz &&
                    <QuizModal show={true}
                               field_id={AYURVEDIC_QUIZ_FIELD_ID}
                               bot_form_id={AYURVEDIC_QUIZ_BOT_FORM_ID}
                               order_id={detail.id}
                               handleClose={() => this.setState({showAyurvedicQuiz: undefined})}/>
                }
                <div className="container-fluid pt-3 pl-5 position-relative">
                    <span className="material-icons back-button"
                          onClick={() => this.props.history.goBack()}>keyboard_backspace</span>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <OrderDetailHeader objectId={detail.id}
                                               objectTitle={'Customer ID'}
                                               shippingAddressName={!!shipping_address ? shipping_address.name: ''}/>
                        </div>
                        <div className="col-12 col-md-5">
                            <OrderDetailButtons showCall={!!detail.id}
                                                onClickCallOrder={() => this.handleMakeCall(detail, "manual_call")}
                                                onClickScheduledCall={() => this.setState({scheduledCall: {customer: detail.id}})}
                                                onClickCreateOrder={() => this.setState({showCreate: true})}
                                                onClickAyurvedicQuiz={() => {
                                                    console.warn('show modal');
                                                    this.setState({showAyurvedicQuiz: true})
                                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="row">
                                {
                                    !!detail.date_joined &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Date joined</p>
                                        <p className="text">
                                            {moment(detail.date_joined).format("D MMMM YYYY,")}
                                            <br/>
                                            {moment(detail.date_joined).format("h:mm:ss a")}
                                        </p>
                                    </div>
                                }
                                {
                                    !!detail.date_joined &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Date joined</p>
                                        <p className="text">
                                            {moment(detail.date_joined).format("D MMMM YYYY,")}
                                            <br/>
                                            {moment(detail.date_joined).format("h:mm:ss a")}
                                        </p>
                                    </div>
                                }
                                {
                                    !!detail.attempted_calls &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Attempted Calls</p>
                                        <p className="text">{detail.attempted_calls}</p>
                                    </div>
                                }
                                {
                                    !!detail.connected_calls &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Connected Calls</p>
                                        <p className="text">{detail.connected_calls}</p>
                                    </div>
                                }
                                {
                                    !!detail.order_history_count &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Order History Count</p>
                                        <p className="text">{detail.order_history_count}</p>
                                    </div>
                                }
                                {
                                    !!detail.report_history_count &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Reports count</p>
                                        <p className="text">{detail.attempted_calls}</p>
                                    </div>
                                }
                                {
                                    !!detail.missed_calls &&
                                    <div className="col-6 col-md-4">
                                        <p className="heading mb-0">Missed Calls</p>
                                        <p className="text">{detail.missed_calls}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-12 col-md-6 pr-0">
                            <div className="row">
                                <div className="col-12 p-1 pr-md-5 pt-4">
                                    <OrderHistoryTable orderHistory={orders_history}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <CallDetailForm callData={detail.ongoing_call}/>
                            <CallHistory supportCalls={support_calls}
                                         title={'Customer call history'}
                                         onClickView={(call) => this.setState({callDetail: call, order: null})}/>
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    customers: state.ecommerce.customers,
    orders: state.ecommerce.orders,
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCustomer,
    confirmOrder,
    cancelOrder,
    createCall,
    refundOrder,
    convertToOnline,
    resendPaymentLink,
    sendAyurvedicQuizLink,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CustomerDetail);
