import React from "react";
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchCall, updateCall} from "../../../actions/ecommerce/calls";
import Form from "react-bootstrap/Form";
import {callTypeOptions} from "../../../callConstants";
import {Link} from "react-router-dom";


class CallDetailForm extends React.Component {

    static propTypes = {
        callData: PropTypes.object,
        updateCall: PropTypes.func,
        label: PropTypes.string,
        variant: PropTypes.oneOf(['small', 'medium']),
    };

    static defaultProps = {
        callData: {},
        label: 'Ongoing/Last Call details',
        variant: 'medium',
    };

    state = {
        callData: {},
    };

    componentDidMount() {
        let {callData} = this.props;
        this.setState({callData});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {calls, callData} = this.props;
        if (!calls.isFetching && prevProps.calls.isFetching && !calls.error && !calls.retry) {

        }
        if (callData !== prevProps.callData) {
            this.setState({callData});
        }
    }

    handleSubmitCall = () => {
        let {callData} = this.state;

        let valueFieldKeys = Object.keys(callData.data)
        let requiredFieldKeys = Object.keys(callTypeOptions[callData.call_type].data);
        let isValid = true

        if (callData.call_completed) {
            for (let i in requiredFieldKeys) {
                let requiredFieldKey = requiredFieldKeys[i];
                isValid = isValid && valueFieldKeys.includes(requiredFieldKey);
            }
        }
        if (isValid) {
            this.props.updateCall(callData);
        } else {
            alert('Please fill all call details');
        }
    }

    handleRemarkChange = (value) => {
        this.setState(prevState => ({
            callData: {
                ...prevState.callData,
                remarks: value,
            }
        }));
    }

    handleAttributeChange = (key, value) => {
        this.setState(prevState => ({
            callData: {
                ...prevState.callData,
                data: {
                    ...prevState.callData.data,
                    [key]: value,
                }
            }
        }));
    }

    render() {
        let {callData} = this.state;
        let {variant} = this.props;

        if (!callData || !callData.id) return null;
        let formFields = callTypeOptions[callData.call_type] ? callTypeOptions[callData.call_type].data : {};
        let attributes = callData.data;
        return (
            <div className="call-detail-form">
                <h6>{this.props.label}</h6>
                <div className="row">
                    <div className={variant === 'small' ? "col-12": "col-6"}>
                        {
                            !!callData.last_order &&
                            <div className="row">
                                <div className="col-12">
                                    Last Order: <Link to={`/orders/${callData.last_order.id}`}>{callData.last_order.cid}</Link>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12">
                                Call ID: <Link to={`/calls/${callData.sid}`}>{callData.id}</Link>
                            </div>
                        </div>
                        <div className="row pl-3 pr-0">
                            <div className="col-12 p-0">
                                <Form.Group className="mb-0">
                                    <div className="call-detail-form-option">
                                        <div className="call-detail-form-label">Could you complete conversation?</div>
                                        <div className="call-detail-form-options">
                                            <input type="radio" id={`call-completed-yes-${callData.id}`}
                                                   name={`call-completed-${callData.id}`}
                                                   checked={callData.call_completed === true}
                                                   onChange={() => this.setState({
                                                       callData: {
                                                           ...callData,
                                                           call_completed: true
                                                       }
                                                   })}/>
                                            <label htmlFor={`call-completed-yes-${callData.id}`}>Yes</label><br/>
                                        </div>
                                        <div className="call-detail-form-options">
                                            <input type="radio" id={`call-completed-no-${callData.id}`}
                                                   name={`call-completed-${callData.id}`}
                                                   checked={callData.call_completed === false}
                                                   onChange={() => this.setState({
                                                       callData: {
                                                           ...callData,
                                                           call_completed: false
                                                       }
                                                   })}/>
                                            <label htmlFor={`call-completed-no-${callData.id}`}>No</label><br/>
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            {
                                Object.keys(formFields).map(
                                    (key) => {
                                        let label = formFields[key].label;
                                        let value = attributes[key];
                                        let options = formFields[key].options;

                                        return (
                                            <div key={key} className="col-12 p-0">
                                                <Form.Group controlId={key} className="mb-0">
                                                    {
                                                        !options &&
                                                        <div className="call-detail-form-option">
                                                            <div className="call-detail-form-label">{label}</div>
                                                            <div className="call-detail-form-options">
                                                                <input type="radio" id={`${key}-true-${callData.id}`}
                                                                       name={`${key}-${callData.id}`}
                                                                       value={true}
                                                                       checked={value === true}
                                                                       onChange={() => this.handleAttributeChange(key, true)}/>
                                                                <label htmlFor={`${key}-true`}>Yes</label><br/>
                                                            </div>
                                                            <div className="call-detail-form-options">
                                                                <input type="radio" id={`${key}-false-${callData.id}`}
                                                                       name={`${key}-${callData.id}`}
                                                                       value={false}
                                                                       checked={value === false}
                                                                       onChange={() => this.handleAttributeChange(key, false)}/>
                                                                <label htmlFor={`${key}-false`}>No</label><br/>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        !!options &&
                                                        <div className="call-detail-form-option">
                                                            <div className="call-detail-form-label">{label}</div>
                                                            {
                                                                options.map((option, index) => (
                                                                    <div className="call-detail-form-options"
                                                                         key={index}>
                                                                        <input type="radio" id={`${key}-${option}-${callData.id}`}
                                                                               name={`${key}-${callData.id}`}
                                                                               value={option}
                                                                               checked={value === option}
                                                                               onChange={() => this.handleAttributeChange(key, option)}/>
                                                                        <label
                                                                            htmlFor={`${key}-${option}-${callData.id}`}>{option}</label><br/>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </Form.Group>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                    <div className={variant === 'small' ? "col-12 pt-2": "col-6 px-1"}>
                        <Form.Group controlId="call-remarks" className="mb-0 mt-2">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control as="textarea"
                                          required
                                          onChange={(e) => this.handleRemarkChange(e.target.value)}
                                          value={callData.remarks || ''}/>
                        </Form.Group>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-12">
                        <div className="text-left">
                            <Button variant="primary" onClick={this.handleSubmitCall}>Save Call details</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchCall,
    updateCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CallDetailForm);
