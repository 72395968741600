import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import {fetchQuizzerInfo} from "../../actions/quiz/quizzers";
import {createCall} from "../../actions/ecommerce/calls";
import {
    AYURVEDIC_QUIZ_BOT_FORM_ID,
    AYURVEDIC_QUIZ_FIELD_ID, callTypes,
} from "../../constants";
import './styles.scss';
import {toast} from "react-toastify";
import CallDetailForm from "../../components/forms/CallDetailForm";
import OrderDetailHeader from "../../components/others/OrderDetailHeader";
import OrderDetailButtons from "../../components/others/OrderDetailButtons";
import CallHistory from "../../components/others/CallHistory";
import OrderHistoryTable from "../../components/others/OrderHistoryTable";
import CallDetailModal from "../../components/modals/CallDetailModal";
import OrderCreate from "../../components/modals/OrderCreate";
import QuizModal from "../../components/modals/QuizModal";
import moment from "moment";
import {getTextFromHtml} from "../../utils";
import Button from "react-bootstrap/Button";
import {sendAyurvedicQuizLink} from "../../actions/ecommerce/orders";
import ScheduleCallModal from "../../components/modals/ScheduleCallModal";


class QuizzerDetail extends React.Component {

    static propTypes = {
        quizzers: PropTypes.object,
        fetchQuizzerInfo: PropTypes.func,
        createCall: PropTypes.func,
    };

    state = {
        showCreate: false,
        order: null,
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.props.fetchQuizzerInfo({id: params.reportId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {detail, isFetching, isCreating, error, retry} = this.props.quizzers;
        let {calls, orders, match: {params}} = this.props;

        if (params.reportId !== prevProps.match.params.reportId) {
            this.props.fetchQuizzerInfo({id: params.reportId});
        }

        if (!orders.isCreating && prevProps.orders.isCreating && !orders.error && !orders.retry) {
            toast("Order created");
            this.handleOrderUpdates();
        }

        if (!calls.isCreating && prevProps.calls.isCreating && !calls.error && !calls.retry) {
            toast("Call Created");
            this.handleOrderUpdates();
        }

        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            toast("Call Detail Updated");
            this.handleOrderUpdates();
        }
    }

    handleOrderUpdates = () => {
        let {params} = this.props.match;
        this.props.fetchQuizzerInfo({id: params.reportId});
    };

    handleMakeCall = (report) => {
        this.props.createCall({report_id: report.id, call_type: callTypes.QUIZ_CALL});
    };

    handleSendAyurvedicQuizLink = () => {
        let {quizzers: {detail}} = this.props;
        this.props.sendAyurvedicQuizLink({report_id: detail.id});
    };

    render() {
        let {quizzers: {detail, detail: {shipping_address = {}, support_calls = [], orders_history = []}}} = this.props;
        shipping_address = shipping_address || {}

        return (
            <SidebarContainer>
                <OrderCreate show={this.state.showCreate} address={shipping_address} reportId={detail.id}
                             handleClose={() => this.setState({showCreate: false})}/>

                {
                    !!this.state.callDetail &&
                    <CallDetailModal show={true} callDetail={this.state.callDetail}
                                     handleClose={() => this.setState({callDetail: undefined})}/>
                }
                {
                    !!this.state.showAyurvedicQuiz &&
                    <QuizModal show={true}
                               field_id={AYURVEDIC_QUIZ_FIELD_ID}
                               bot_form_id={AYURVEDIC_QUIZ_BOT_FORM_ID}
                               report_id={detail.id}
                               handleClose={() => this.setState({showAyurvedicQuiz: undefined})}/>
                }
                {
                    !!this.state.scheduledCall &&
                    <ScheduleCallModal show={true} data={this.state.scheduledCall}
                                       handleClose={() => this.setState({scheduledCall: undefined})}/>
                }
                <div className="container-fluid pt-3 pl-5 position-relative">
                    <span className="material-icons back-button"
                          onClick={() => this.props.history.goBack()}>keyboard_backspace</span>

                    <div className="row">
                        <div className="col-12 col-md-7">
                            <OrderDetailHeader objectId={detail.report_id}
                                               objectTitle={'Report ID'}
                                               shippingAddressName={shipping_address.name}/>
                           <div className="row">
                               <div className="col-6 col-md-4">
                                   <p className="heading mb-0">Report Date</p>
                                   <p className="text">
                                       {moment(detail.created_at).format("DD MMMM YYYY, h:mm a")}
                                   </p>
                               </div>
                           </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <OrderDetailButtons showCall={!!detail.id}
                                                onClickAyurvedicLink={this.handleSendAyurvedicQuizLink}
                                                onClickCallOrder={() => this.handleMakeCall(detail)}
                                                onClickScheduledCall={() => this.setState({scheduledCall: {report: detail.id}})}
                                                onClickCreateOrder={() => this.setState({showCreate: true})}
                                                onClickAyurvedicQuiz={() => this.setState({showAyurvedicQuiz: true})}
                            />
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-12 col-md-6 pr-0">
                            <div className="row">
                                <div className="col-12 p-1 pr-md-5 pt-4">
                                    <OrderHistoryTable orderHistory={orders_history}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <CallDetailForm callData={detail.ongoing_call}/>
                            <CallHistory supportCalls={support_calls}
                                         onClickView={(call) => this.setState({callDetail: call, order: null})}/>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-12 pt-4 col-md-6">
                            <div className="responses-card">
                                <h2 className="heading">Responses</h2>
                                {
                                    !!detail.bot_fields &&
                                    detail.bot_fields.filter(
                                        ({field_id, field_parent_field_id}) => (!!detail.data[field_id] && !field_parent_field_id)
                                    ).map(({text, type, field_id, height, field_parent_field_id}, index) => {
                                        let value = detail.data[field_id].value;
                                        let answer_fields = detail.bot_fields.filter(({field_id, type}) => (value === field_id && type === 'bool'));
                                        if (answer_fields.length) {
                                            value = answer_fields[0].text;
                                        }
                                        return (
                                            <div key={field_id}>
                                                <h6>{index + 1}. {getTextFromHtml(text)}</h6>
                                                <p>Ans. {value}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-12 pt-4 col-md-6">
                            <div className="text-right pb-2">
                                <Button variant="primary" className="order-detail-button"
                                        onClick={() => {
                                            window.open(`https://misters.in/en/quiz/reports/?id=${detail.report_id}`, '_blank')
                                        }}>Open report in new Tab</Button>
                            </div>
                            {
                                !!detail.report_id &&
                                <iframe className="quiz-report"
                                        src={`https://misters.in/en/quiz/reports/?id=${detail.report_id}`}
                                        frameBorder="0"/>
                            }
                        </div>
                    </div>
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.ecommerce.orders,
    quizzers: state.quiz.quizzers,
    calls: state.ecommerce.calls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchQuizzerInfo,
    createCall,
    sendAyurvedicQuizLink,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(QuizzerDetail);
