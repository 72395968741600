import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import {NavLink} from "react-router-dom";

import {callTypeOptions} from "../../../callConstants";
import "./styles.scss";
import Table from "react-bootstrap/Table";


class CallDetailModal extends React.Component {

    static propTypes = {
        callDetail: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        callDetail: {},
        show: false,
    };


    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {

        }
    }

    render() {
        let {show, handleClose, callDetail} = this.props;
        let formFields = callTypeOptions[callDetail.call_type] ? callTypeOptions[callDetail.call_type].data : {};
        let attributes = callDetail.data;

        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="row">
                        {!!callDetail.created_at && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Call Date</p>
                            <p className="text">{moment(callDetail.created_at).format("DD MMMM YYYY, h:mm a")}</p>
                        </div>}

                        {!!callDetail.sid && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Call SID</p>
                            <p className="text">
                                <NavLink to={`/calls/${callDetail.sid}`} className="text-primary">
                                    {callDetail.sid}</NavLink>
                            </p>
                        </div>}

                        {!!callDetail.order_id && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Order ID</p>
                            <p className="text">
                                <NavLink to={`/orders/${callDetail.order_id}`} className="text-primary">
                                    {callDetail.order_id}</NavLink>
                            </p>
                        </div>}

                        {!!callDetail.report_id && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Report ID</p>
                            <p className="text">
                                <NavLink to={`/quizzers/${callDetail.report_id}`} className="text-primary">
                                    {callDetail.report_id}</NavLink>
                            </p>
                        </div>}

                        {!!callDetail.call_type && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Call Type</p>
                            <p className="text text-capitalize">{callDetail.call_type.split('_').join(" ")}</p>
                        </div>}

                        {!!callDetail.user_name && <div className="col-12 col-md-6">
                            <p className="heading mb-0">Called By</p>
                            <p className="text">{callDetail.user_name}</p>
                        </div>}
                    </div>

                    {!!callDetail.recording_url && <React.Fragment>
                        <p className="heading mb-0">Call Recording</p>
                        <div className="mb-3">
                            <audio controls controlsList="nodownload" style={{maxWidth: '250px', height: '30px'}}>
                                <source src={callDetail.recording_url}/>
                            </audio>
                        </div>
                    </React.Fragment>}

                    <p className="heading mb-0">Call Details</p>
                    <Table className="order-list">
                        <thead>
                        <tr><td>Key</td><td>Answer</td></tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(formFields).map(
                                (key) => {
                                    let label = formFields[key].label;
                                    let value = attributes[key];
                                    return (
                                        <tr key={key} className="col-12 p-0">
                                            <td>{label}</td>
                                            {
                                                (typeof value === 'boolean') &&
                                                <td>{!!value ? 'yes': 'no'}</td>
                                            }
                                            {
                                                (typeof value === 'undefined')  &&
                                                <td> - </td>
                                            }
                                            {typeof value === 'string' && <td>{value}</td>}
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </Table>

                    {!!callDetail.remarks && <React.Fragment>
                        <p className="heading mt-3 mb-0">Remarks</p>
                        <p className="text">{callDetail.remarks}</p>
                    </React.Fragment>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({});

const matchDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(CallDetailModal);
