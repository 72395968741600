import {
    FETCH_ORDERS,
    FETCH_ORDERS_RESULT,
    FETCH_ORDERS_ERROR,
    FETCH_ORDERS_RETRY,

    FETCH_PENDING_ORDERS,
    FETCH_PENDING_ORDERS_RESULT,
    FETCH_PENDING_ORDERS_ERROR,
    FETCH_PENDING_ORDERS_RETRY,

    CANCELED_ORDERS,
    CANCELED_ORDERS_RESULT,
    CANCELED_ORDERS_ERROR,
    CANCELED_ORDERS_RETRY,

    CONFIRM_ORDER,
    CONFIRM_ORDER_RESULT,
    CONFIRM_ORDER_ERROR,
    CONFIRM_ORDER_RETRY,

    CANCEL_ORDER,
    CANCEL_ORDER_RESULT,
    CANCEL_ORDER_ERROR,
    CANCEL_ORDER_RETRY,

    FETCH_ORDER_INFO,
    FETCH_ORDER_INFO_RESULT,
    FETCH_ORDER_INFO_ERROR,
    FETCH_ORDER_INFO_RETRY,

    CREATE_ORDER,
    CREATE_ORDER_RESULT,
    CREATE_ORDER_RETRY,
    CREATE_ORDER_ERROR,

    FETCH_ORDER_ESTIMATE,
    FETCH_ORDER_ESTIMATE_RESULT,
    FETCH_ORDER_ESTIMATE_ERROR,
    FETCH_ORDER_ESTIMATE_RETRY,

    REFUND_ORDER,
    REFUND_ORDER_RESULT,
    REFUND_ORDER_RETRY,
    REFUND_ORDER_ERROR,

    UPDATE_ORDER_ADDRESS,
    UPDATE_ORDER_ADDRESS_RESULT,
    UPDATE_ORDER_ADDRESS_ERROR,
    UPDATE_ORDER_ADDRESS_RETRY,

    RESEND_PAYMENT_LINK,
    RESEND_PAYMENT_LINK_RESULT,
    RESEND_PAYMENT_LINK_ERROR,
    RESEND_PAYMENT_LINK_RETRY,

    SEND_AYURVEDIC_QUIZ_LINK,
    SEND_AYURVEDIC_QUIZ_LINK_RESULT,
    SEND_AYURVEDIC_QUIZ_LINK_ERROR,
    SEND_AYURVEDIC_QUIZ_LINK_RETRY,

    CHECKOUT_LINK,
    CHECKOUT_LINK_RESULT,
    CHECKOUT_LINK_ERROR,
    CHECKOUT_LINK_RETRY,

    CONVERT_TO_ONLINE,
    CONVERT_TO_ONLINE_RESULT,
    CONVERT_TO_ONLINE_ERROR,
    CONVERT_TO_ONLINE_RETRY,

    FETCH_REPEAT_ORDERS,
    FETCH_REPEAT_ORDERS_RESULT,
    FETCH_REPEAT_ORDERS_ERROR,
    FETCH_REPEAT_ORDERS_RETRY,
} from '../../actions/ecommerce/orders';


const initialState = {
    detail: {},
    list: {},
    pendingList: {},
    canceledList: {},
    repeatList: {},
    estimate: {},
    isFetching: false,
    isFetchingList: false,
    isFetchingPending: false,
    isFetchingCanceled: false,
    isFetchingRepeat: false,
    isConfirming: false,
    isCanceling: false,
    isCreating: false,
    isRefunding: false,
    isUpdatingAddress: false,
    isFetchingConvertToOnline: false,
    isFetchingResendPaymentLink: false,
    isSendingAyurvedicQuizLink: false,
    isSendingCheckoutLink: false,
    isFetchingEstimate: false,
    error: false,
    message: null,
    retry: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return {...state, isFetchingList: true, error: false, message: null, retry: false};
        case FETCH_ORDERS_RESULT:
            return {...state, isFetchingList: false, list: action.data};
        case FETCH_ORDERS_ERROR:
            return {...state, isFetchingList: false, error: true, message: action.message};
        case FETCH_ORDERS_RETRY:
            return {...state, isFetchingList: false, retry: true, message: action.message};

        case FETCH_PENDING_ORDERS:
            return {...state, isFetchingPending: true, error: false, message: null, retry: false};
        case FETCH_PENDING_ORDERS_RESULT:
            return {...state, isFetchingPending: false, pendingList: action.data};
        case FETCH_PENDING_ORDERS_ERROR:
            return {...state, isFetchingPending: false, error: true, message: action.message};
        case FETCH_PENDING_ORDERS_RETRY:
            return {...state, isFetchingPending: false, retry: true, message: action.message};

        case CANCELED_ORDERS:
            return {...state, isFetchingCanceled: true, error: false, message: null, retry: false};
        case CANCELED_ORDERS_RESULT:
            return {...state, isFetchingCanceled: false, canceledList: action.data};
        case CANCELED_ORDERS_ERROR:
            return {...state, isFetchingCanceled: false, error: true, message: action.message};
        case CANCELED_ORDERS_RETRY:
            return {...state, isFetchingCanceled: false, retry: true, message: action.message};

        case FETCH_ORDER_INFO:
            return {...state, isFetching: true, error: false, message: null, retry: false};
        case FETCH_ORDER_INFO_RESULT:
            return {...state, isFetching: false, detail: action.data};
        case FETCH_ORDER_INFO_ERROR:
            return {...state, isFetching: false, error: true, message: action.message};
        case FETCH_ORDER_INFO_RETRY:
            return {...state, isFetching: false, retry: true, message: action.message};

        case CONFIRM_ORDER:
            return {...state, isConfirming: true, error: false, message: null, retry: false};
        case CONFIRM_ORDER_RESULT:
            return {...state, isConfirming: false};
        case CONFIRM_ORDER_ERROR:
            return {...state, isConfirming: false, error: true, message: action.message};
        case CONFIRM_ORDER_RETRY:
            return {...state, isConfirming: false, retry: true, message: action.message};

        case CANCEL_ORDER:
            return {...state, isCanceling: true, error: false, message: null, retry: false};
        case CANCEL_ORDER_RESULT:
            return {...state, isCanceling: false};
        case CANCEL_ORDER_ERROR:
            return {...state, isCanceling: false, error: true, message: action.message};
        case CANCEL_ORDER_RETRY:
            return {...state, isCanceling: false, retry: true, message: action.message};

        case CREATE_ORDER:
            return {...state, isCreating: true, error: false, message: null, retry: false};
        case CREATE_ORDER_RESULT:
            return {...state, isCreating: false};
        case CREATE_ORDER_ERROR:
            return {...state, isCreating: false, error: true, message: action.message};
        case CREATE_ORDER_RETRY:
            return {...state, isCreating: false, retry: true, message: action.message};

        case FETCH_ORDER_ESTIMATE:
            return {...state, isFetchingEstimate: true, error: false, message: null, retry: false};
        case FETCH_ORDER_ESTIMATE_RESULT:
            return {...state, isFetchingEstimate: false, estimate: action.data};
        case FETCH_ORDER_ESTIMATE_ERROR:
            return {...state, isFetchingEstimate: false, error: true, message: action.message};
        case FETCH_ORDER_ESTIMATE_RETRY:
            return {...state, isFetchingEstimate: false, retry: true, message: action.message};

        case REFUND_ORDER:
            return {...state, isRefunding: true, error: false, message: null, retry: false};
        case REFUND_ORDER_RESULT:
            return {...state, isRefunding: false};
        case REFUND_ORDER_RETRY:
            return {...state, isRefunding: false, error: true, message: action.message};
        case REFUND_ORDER_ERROR:
            return {...state, isRefunding: false, retry: true, message: action.message};

        case UPDATE_ORDER_ADDRESS:
            return {...state, isUpdatingAddress: true, error: false, message: null, retry: false};
        case UPDATE_ORDER_ADDRESS_RESULT:
            return {...state, isUpdatingAddress: false};
        case UPDATE_ORDER_ADDRESS_ERROR:
            return {...state, isUpdatingAddress: false, error: true, message: action.message};
        case UPDATE_ORDER_ADDRESS_RETRY:
            return {...state, isUpdatingAddress: false, retry: true, message: action.message};

        case RESEND_PAYMENT_LINK:
            return {...state, isFetchingResendPaymentLink: true, error: false, message: null, retry: false};
        case RESEND_PAYMENT_LINK_RESULT:
            return {...state, isFetchingResendPaymentLink: false};
        case RESEND_PAYMENT_LINK_ERROR:
            return {...state, isFetchingResendPaymentLink: false, error: true, message: action.message};
        case RESEND_PAYMENT_LINK_RETRY:
            return {...state, isFetchingResendPaymentLink: false, retry: true, message: action.message};

        case SEND_AYURVEDIC_QUIZ_LINK:
            return {...state, isSendingAyurvedicQuizLink: true, error: false, message: null, retry: false};
        case SEND_AYURVEDIC_QUIZ_LINK_RESULT:
            return {...state, isSendingAyurvedicQuizLink: false};
        case SEND_AYURVEDIC_QUIZ_LINK_ERROR:
            return {...state, isSendingAyurvedicQuizLink: false, error: true, message: action.message};
        case SEND_AYURVEDIC_QUIZ_LINK_RETRY:
            return {...state, isSendingAyurvedicQuizLink: false, retry: true, message: action.message};

        case CHECKOUT_LINK:
            return {...state, isSendingCheckoutLink: true, error: false, message: null, retry: false};
        case CHECKOUT_LINK_RESULT:
            return {...state, isSendingCheckoutLink: false};
        case CHECKOUT_LINK_ERROR:
            return {...state, isSendingCheckoutLink: false, error: true, message: action.message};
        case CHECKOUT_LINK_RETRY:
            return {...state, isSendingCheckoutLink: false, retry: true, message: action.message};

        case CONVERT_TO_ONLINE:
            return {...state, isFetchingConvertToOnline: true, error: false, message: null, retry: false};
        case CONVERT_TO_ONLINE_RESULT:
            return {...state, isFetchingConvertToOnline: false};
        case CONVERT_TO_ONLINE_ERROR:
            return {...state, isFetchingConvertToOnline: false, error: true, message: action.message};
        case CONVERT_TO_ONLINE_RETRY:
            return {...state, isFetchingConvertToOnline: false, retry: true, message: action.message};

        case FETCH_REPEAT_ORDERS:
            return {...state, isFetchingRepeat: true, error: false, message: null, retry: false};
        case FETCH_REPEAT_ORDERS_RESULT:
            return {...state, isFetchingRepeat: false, repeatList: action.data};
        case FETCH_REPEAT_ORDERS_ERROR:
            return {...state, isFetchingRepeat: false, error: true, message: action.message};
        case FETCH_REPEAT_ORDERS_RETRY:
            return {...state, isFetchingRepeat: false, retry: true, message: action.message};

        default:
            return state;
    }
};

export default reducer;
