import {takeEvery} from 'redux-saga/effects';

import {
    FETCH_ORDERS,
    CONFIRM_ORDER,
    CANCELED_ORDERS,
    CANCEL_ORDER,
    FETCH_ORDER_INFO,
    CREATE_ORDER,
    FETCH_PENDING_ORDERS,
    REFUND_ORDER,
    UPDATE_ORDER_ADDRESS,
    RESEND_PAYMENT_LINK,
    SEND_AYURVEDIC_QUIZ_LINK,
    CONVERT_TO_ONLINE,
    FETCH_REPEAT_ORDERS,
    CHECKOUT_LINK,
    FETCH_ORDER_ESTIMATE,
} from "../../actions/ecommerce/orders";

import {FETCH_PRODUCTS} from "../../actions/ecommerce/products";
import {FETCH_PHARMACY_LIST} from "../../actions/ecommerce/pharmacy";
import {FETCH_REPORTS} from "../../actions/ecommerce/reports";
import {FETCH_COUPONS} from "../../actions/ecommerce/coupons";
import {
    CREATE_CALL,
    FETCH_CALLS,
    FETCH_CALL,
    UPDATE_CALL,
    FETCH_INCOMING_CALL,
    CREATE_CALL_TRANSFER, FETCH_ACTIVE_AGENTS
} from "../../actions/ecommerce/calls";
import {FETCH_CUSTOMER, FETCH_CUSTOMERS} from "../../actions/ecommerce/customers";
import {
    CREATE_SCHEDULED_CALL,
    FETCH_SCHEDULED_CALLS,
    UPDATE_SCHEDULED_CALL
} from "../../actions/ecommerce/scheduledcalls";

import {fetchProducts} from "./products";
import {fetchPharmacyList} from "./pharmacy";
import {fetchReports} from "./reports";
import {fetchCoupons} from "./coupons";
import {
    createCall,
    fetchCalls,
    fetchCall,
    updateCall,
    fetchIncomingCall,
    createCallTransfer,
    fetchActiveAgents
} from "./calls";
import {fetchScheduledCalls, createScheduledCall, updateScheduledCall} from "./scheduledcalls";
import {fetchCustomer, fetchCustomers} from "./customers";
import {
    fetchOrders,
    confirmOrder,
    fetchCanceledOrders,
    cancelOrder,
    fetchOrderInfo,
    createOrder,
    fetchPendingOrders,
    refundOrder,
    updateOrderAddress,
    resendPaymentLink,
    sendAyurvedicQuizLink,
    sendCheckoutLink,
    convertToOnline,
    fetchRepeatOrders,
    fetchOrderEstimate,
} from './orders';
import {fetchShift, updateShift} from "./shift";
import {FETCH_SHIFT, UPDATE_SHIFT} from "../../actions/ecommerce/shift";


export default [
    takeEvery(FETCH_ORDERS, fetchOrders),
    takeEvery(FETCH_PENDING_ORDERS, fetchPendingOrders),
    takeEvery(CANCELED_ORDERS, fetchCanceledOrders),
    takeEvery(FETCH_ORDER_INFO, fetchOrderInfo),
    takeEvery(CONFIRM_ORDER, confirmOrder),
    takeEvery(CANCEL_ORDER, cancelOrder),
    takeEvery(CREATE_ORDER, createOrder),
    takeEvery(REFUND_ORDER, refundOrder),
    takeEvery(UPDATE_ORDER_ADDRESS, updateOrderAddress),
    takeEvery(FETCH_REPEAT_ORDERS, fetchRepeatOrders),
    takeEvery(FETCH_ORDER_ESTIMATE, fetchOrderEstimate),

    takeEvery(FETCH_PRODUCTS, fetchProducts),
    takeEvery(FETCH_PHARMACY_LIST, fetchPharmacyList),

    takeEvery(CREATE_SCHEDULED_CALL, createScheduledCall),
    takeEvery(FETCH_SCHEDULED_CALLS, fetchScheduledCalls),
    takeEvery(UPDATE_SCHEDULED_CALL, updateScheduledCall),

    takeEvery(CREATE_CALL, createCall),
    takeEvery(UPDATE_CALL, updateCall),
    takeEvery(FETCH_CALLS, fetchCalls),
    takeEvery(FETCH_CALL, fetchCall),
    takeEvery(FETCH_INCOMING_CALL, fetchIncomingCall),
    takeEvery(CREATE_CALL_TRANSFER, createCallTransfer),
    takeEvery(FETCH_ACTIVE_AGENTS, fetchActiveAgents),

    takeEvery(CONVERT_TO_ONLINE, convertToOnline),
    takeEvery(RESEND_PAYMENT_LINK, resendPaymentLink),
    takeEvery(SEND_AYURVEDIC_QUIZ_LINK, sendAyurvedicQuizLink),
    takeEvery(CHECKOUT_LINK, sendCheckoutLink),

    takeEvery(FETCH_REPORTS, fetchReports),

    takeEvery(FETCH_COUPONS, fetchCoupons),

    takeEvery(FETCH_CUSTOMER, fetchCustomer),
    takeEvery(FETCH_CUSTOMERS, fetchCustomers),

    takeEvery(FETCH_SHIFT, fetchShift),
    takeEvery(UPDATE_SHIFT, updateShift),
];
