import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Pagination from "react-bootstrap/Pagination";


export default class PaginationComponent extends PureComponent {

    static propTypes = {
        onClick: PropTypes.func,
        className: PropTypes.string,
        page: PropTypes.number,
        count: PropTypes.number,
        perPage: PropTypes.number,
        totalLabel: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        page: 1,
        count: 1,
        perPage: 10,
        totalLabel: 'Total Orders',
    };

    render() {
        let {page, count, perPage, onClick, className, totalLabel} = this.props;
        let totalPage = Math.ceil(count / perPage);
        let startIndex = Math.max(page - 5, 1);
        let endIndex = Math.min(startIndex + 10, totalPage);
        startIndex = Math.min(Math.max(endIndex - 10, 1), startIndex);

        let pageArray = [];
        for (let i = startIndex; i <= endIndex; i++) {
            pageArray.push(i);
        }

        return (
            <React.Fragment>
                <Pagination className={className}>
                    <Pagination.Item className={`${page === 1 ? 'disabled' : ''}`}
                                     onClick={() => onClick(1)}>First</Pagination.Item>
                    {
                        pageArray.map(
                            pageNumber => (
                                <Pagination.Item key={pageNumber} active={page === pageNumber}
                                                 onClick={() => onClick(pageNumber)}>
                                    {pageNumber}
                                </Pagination.Item>
                            )
                        )
                    }
                    <Pagination.Item className={page >= totalPage ? 'disabled' : ''}
                                     onClick={() => onClick(totalPage)}>Last</Pagination.Item>
                </Pagination>
                <p className="mt-2 mb-0">{totalLabel}: {count}</p>
            </React.Fragment>
        )
    }
}
