import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PropTypes from 'prop-types';
import moment from "moment";
import {Form, InputGroup, Spinner, Button, Table} from "react-bootstrap";

import {SidebarContainer} from "../../components/sidebars/MainSidebar";
import MainNavbar from "../../components/navbars/MainNavbar";
import {NavLink} from "react-router-dom";
import Countdown from 'react-countdown';

import {
    getCallAttemptsBackgroundColor, isObjectsEqual,
    getPrice, getQueryString,
    getSearchParams,
    NODE_SERVER_HOST,
    scrollToTop, getIndexCount, orderCallTypeFromStatus, callTypes
} from "../../constants";
import './styles.scss';
import PaginationComponent from "../../components/others/PaginationComponent";
import OrdersColorCoding from "../../components/others/OrdersColorCoding";
import {fetchScheduledCalls, updateScheduledCall} from "../../actions/ecommerce/scheduledcalls";
import {createCall} from "../../actions/ecommerce/calls";
import ScheduleCallModal from "../../components/modals/ScheduleCallModal";


class ScheduledCalls extends React.Component {

    static propTypes = {
        orders: PropTypes.object,
        fetchScheduledCalls: PropTypes.func,
        updateScheduledCall: PropTypes.func,
    };

    constructor(props) {
        super(props);
        let {search} = props.history.location;
        let {page = '1', q = ''} = getSearchParams(search);

        this.state = {
            order: null,
            searchQuery: q,

            filters: {
                q,
                page: parseInt(page),
            }
        };
    }

    componentDidMount() {
        this.fetchScheduledCalls();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isFetchingList, isUpdating, error, retry} = this.props.scheduledcalls;

        let {filters} = this.state;
        let prevQueryParams = getSearchParams(prevProps.location.search);
        let queryParams = getSearchParams(this.props.location.search);

        if (!isObjectsEqual(filters, prevState.filters)) {
            this.fetchScheduledCalls();
        }

        if (!isObjectsEqual(queryParams, prevQueryParams)) {
            this.setState({
                searchQuery: queryParams.q || '',
                filters: {
                    ...filters,
                    q: queryParams.q || '',
                    page: parseInt(queryParams.page || "1"),
                },
            });
        }
        if (!isUpdating && prevProps.scheduledcalls.isUpdating && !error && !retry) {
            this.fetchScheduledCalls();
        }

        if (!isFetchingList && prevProps.scheduledcalls.isFetchingList && !error && !retry) {
            scrollToTop();
        }
    }

    handleChangeFilter = (filters, e) => {
        !!e && e.preventDefault();

        this.setState({filters});
        this.props.history.push(`/scheduled-calls?${getQueryString(filters)}`);
    };

    fetchScheduledCalls = () => {
        let {filters} = this.state;
        this.props.fetchScheduledCalls(filters);
    };

    handleCompleted = ({id}) => {
        this.props.updateScheduledCall({id, is_completed: true});
    };

    handlePostpone = (scheduledCall) => {
        this.setState({scheduledCall});
    };

    handleCallNow = ({order, report, support_call}) => {
        let {history} = this.props;
        if (order) {
            this.props.createCall({order_id: order.id, call_type: orderCallTypeFromStatus(order.status)});
        }
        else if(report) {
            this.props.createCall({report_id: report.id, call_type: callTypes.QUIZ_CALL});
        }
        else if(support_call) {
            this.props.createCall({call_sid: support_call.sid, call_type: support_call.call_type});
        }
    };

    render() {
        let {scheduledcalls: {list: {results = [], count}}} = this.props;
        let {filters, filters: {page}} = this.state;

        return (
            <SidebarContainer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-5">
                            <MainNavbar pageName={'Scheduled Calls'}/>
                                {!!count &&
                                <div className="col-12 mt-4">
                                    <PaginationComponent className="m-0" page={parseInt(page)} count={parseInt(count)}
                                                         perPage={25}
                                                         onClick={(page) => this.handleChangeFilter({
                                                             ...filters,
                                                             page,
                                                         })}/>
                                </div>
                                }
                        </div>

                        <div className="col-12 col-md-5 offset-md-2 mt-3">
                            <div className="row">
                                <div className="col-12">
                                    <OrdersColorCoding/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-12">
                            <Table className="order-list text-unselectable" responsive>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Customer ID</th>
                                    <th>Order ID</th>
                                    <th>Report ID</th>
                                    <th>Call ID</th>
                                    <th>Scheduled By</th>
                                    <th className="text-center">Scheduled Time</th>
                                    <th className="text-center">Remarks</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map(
                                        (obj, index) => (
                                            <tr key={obj.id}
                                                style={{
                                                    backgroundColor: getCallAttemptsBackgroundColor(obj)
                                                }}>
                                                <td>{getIndexCount(index, page, 25)}</td>
                                                <td>
                                                    {
                                                        !!obj.customer &&
                                                        <NavLink to={`/customers/${obj.customer}`}
                                                                 className="text-black">{obj.customer}</NavLink>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !!obj.order &&
                                                        <NavLink to={`/orders/${obj.order.id}`}
                                                                 className="text-black">{obj.order.cid}
                                                        </NavLink>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !!obj.report &&
                                                        <NavLink to={`/quizzers/${obj.report.id}`}
                                                                 className="text-black">{obj.report.report_id}
                                                        </NavLink>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !!obj.support_call &&
                                                        <NavLink to={`/calls/${obj.support_call.sid}`}
                                                                 className="text-black">{obj.support_call.sid}
                                                        </NavLink>
                                                    }
                                                </td>
                                                <td>
                                                    {obj.scheduled_by}
                                                </td>
                                                <td className="text-center">
                                                    <Countdown date={obj.schedule_time}
                                                               renderer={({hours, minutes, seconds, completed}) => {
                                                                   if (completed) {
                                                                       return null;
                                                                   }
                                                                   else if (hours) {
                                                                       return <div>{moment(obj.schedule_time).format('DD MMM YYYY, hh:mmA')}</div>
                                                                   }
                                                                   return <div>{minutes ? `${minutes} min` : ''}, {`${seconds} sec`}</div>
                                                               }}/>
                                                    <Button className="call-now-button"
                                                            onClick={() => this.handleCallNow(obj)}>Call Now</Button>
                                                    <div>
                                                        <small>{moment(obj.schedule_time).format('DD MMM YYYY, hh:mmA')}</small>
                                                    </div>
                                                </td>
                                                <td style={{maxWidth: 150, fontSize: 12, lineHeight: 1.2}}>
                                                    {obj.remarks}
                                                </td>
                                                <td>
                                                    {
                                                        !obj.is_completed &&
                                                        <Button className="call-now-button mb-1"
                                                                onClick={() => this.handleCompleted(obj)}>
                                                            Mark Completed
                                                        </Button>
                                                    }
                                                    <br/>
                                                    {
                                                        !obj.is_completed &&
                                                        <Button className="call-now-button"
                                                                onClick={() => this.handlePostpone(obj)}>Reschedule</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {
                        !!this.state.scheduledCall &&
                        <ScheduleCallModal show={true} data={this.state.scheduledCall}
                                           handleClose={() => this.setState({scheduledCall: undefined})}/>
                    }
                </div>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    scheduledcalls: state.ecommerce.scheduledcalls,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchScheduledCalls,
    updateScheduledCall,
    createCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(ScheduledCalls);
