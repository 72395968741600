export const FETCH_SCHEDULED_CALLS = 'FETCH_SCHEDULED_CALLS';
export const FETCH_SCHEDULED_CALLS_RESULT = 'FETCH_SCHEDULED_CALLS_RESULT';
export const FETCH_SCHEDULED_CALLS_ERROR = 'FETCH_SCHEDULED_CALLS_ERROR';
export const FETCH_SCHEDULED_CALLS_RETRY = 'FETCH_SCHEDULED_CALLS_RETRY';

export const CREATE_SCHEDULED_CALL = 'CREATE_SCHEDULED_CALL';
export const CREATE_SCHEDULED_CALL_RESULT = 'CREATE_SCHEDULED_CALL_RESULT';
export const CREATE_SCHEDULED_CALL_ERROR = 'CREATE_SCHEDULED_CALL_ERROR';
export const CREATE_SCHEDULED_CALL_RETRY = 'CREATE_SCHEDULED_CALL_RETRY';


export const UPDATE_SCHEDULED_CALL = 'UPDATE_SCHEDULED_CALL';
export const UPDATE_SCHEDULED_CALL_RESULT = 'UPDATE_SCHEDULED_CALL_RESULT';
export const UPDATE_SCHEDULED_CALL_ERROR = 'UPDATE_SCHEDULED_CALL_ERROR';
export const UPDATE_SCHEDULED_CALL_RETRY = 'UPDATE_SCHEDULED_CALL_RETRY';

export const fetchScheduledCalls = (data) => ({
    type: FETCH_SCHEDULED_CALLS,
    data
});

export const createScheduledCall = (data) => ({
    type: CREATE_SCHEDULED_CALL,
    data
});

export const updateScheduledCall = (data) => ({
    type: UPDATE_SCHEDULED_CALL,
    data
});

