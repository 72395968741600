
export const FETCH_SHIFT = 'FETCH_SHIFT';
export const FETCH_SHIFT_RESULT = 'FETCH_SHIFT_RESULT';
export const FETCH_SHIFT_ERROR = 'FETCH_SHIFT_ERROR';
export const FETCH_SHIFT_RETRY = 'FETCH_SHIFT_RETRY';

export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const UPDATE_SHIFT_RESULT = 'UPDATE_SHIFT_RESULT';
export const UPDATE_SHIFT_ERROR = 'UPDATE_SHIFT_ERROR';
export const UPDATE_SHIFT_RETRY = 'UPDATE_SHIFT_RETRY';

export const fetchShift = () => ({
    type: FETCH_SHIFT,
});


export const updateShift = () => ({
    type: UPDATE_SHIFT,
});
