import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {orderColorCode} from "../../../constants";


export default class OrdersColorCoding extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        let {className} = this.props;

        return (
            <div className={`orders-color-coding-container ${className}`}>
                <p className="m-0">Color coding for orders &lt; 24 hours old</p>
                <div className="orders-color-coding pb-1">
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.lessThan24Hours.zeroAttempts}}/>
                        0 call attempts</p>
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.lessThan24Hours.oneAttempts}}/>
                        1 attempt
                    </p>
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.lessThan24Hours.moreThanOneAttempts}}/>
                        &gt;= 2 attempts</p>
                </div>

                <p className="m-0">Color coding for orders &gt;= 24 hours old</p>
                <div className="orders-color-coding">
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.moreThan24Hours.zeroAttempts}}/>
                        0 call attempts </p>
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.moreThan24Hours.oneAttempts}}/>
                        1 attempt
                    </p>
                    <p className="color-code">
                        <span style={{backgroundColor: orderColorCode.moreThan24Hours.moreThanOneAttempts}}/>
                        &gt;= 2 attempts
                    </p>
                </div>
            </div>
        )
    }
}
