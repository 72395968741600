import {call, put} from 'redux-saga/effects';
import {
    FETCH_ORDERS,
    FETCH_ORDERS_RESULT,
    FETCH_ORDERS_ERROR,
    FETCH_ORDERS_RETRY,

    CANCELED_ORDERS_RESULT,
    CANCELED_ORDERS_ERROR,
    CANCELED_ORDERS_RETRY,

    CONFIRM_ORDER_RESULT,
    CONFIRM_ORDER_ERROR,
    CONFIRM_ORDER_RETRY,

    FETCH_ORDER_INFO_RESULT,
    FETCH_ORDER_INFO_ERROR,
    FETCH_ORDER_INFO_RETRY,

    CREATE_ORDER_RESULT,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_RETRY,

    FETCH_ORDER_ESTIMATE_RESULT,
    FETCH_ORDER_ESTIMATE_ERROR,
    FETCH_ORDER_ESTIMATE_RETRY,

    CANCEL_ORDER_RESULT,
    CANCEL_ORDER_ERROR,
    CANCEL_ORDER_RETRY,

    FETCH_PENDING_ORDERS_RESULT,
    FETCH_PENDING_ORDERS_ERROR,
    FETCH_PENDING_ORDERS_RETRY,

    REFUND_ORDER_RESULT,
    REFUND_ORDER_ERROR,
    REFUND_ORDER_RETRY,

    UPDATE_ORDER_ADDRESS_RESULT,
    UPDATE_ORDER_ADDRESS_ERROR,
    UPDATE_ORDER_ADDRESS_RETRY,

    CONVERT_TO_ONLINE_RESULT,
    CONVERT_TO_ONLINE_ERROR,
    CONVERT_TO_ONLINE_RETRY,

    RESEND_PAYMENT_LINK_RESULT,
    RESEND_PAYMENT_LINK_ERROR,
    RESEND_PAYMENT_LINK_RETRY,

    SEND_AYURVEDIC_QUIZ_LINK_RESULT,
    SEND_AYURVEDIC_QUIZ_LINK_RETRY,
    SEND_AYURVEDIC_QUIZ_LINK_ERROR,

    CHECKOUT_LINK_RESULT,
    CHECKOUT_LINK_ERROR,
    CHECKOUT_LINK_RETRY,

    FETCH_REPEAT_ORDERS_RESULT,
    FETCH_REPEAT_ORDERS_ERROR,
    FETCH_REPEAT_ORDERS_RETRY,

} from '../../actions/ecommerce/orders';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ORDERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ORDERS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchPendingOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.pending, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchPendingOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_PENDING_ORDERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_PENDING_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_PENDING_ORDERS_RETRY, retry: true, message: e.message});
    }
}

export function* fetchCanceledOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.canceled, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchCanceledOrders', action, result);

        if (response.status === 200) {
            yield put({type: CANCELED_ORDERS_RESULT, data: result});
        } else {
            yield put({type: CANCELED_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CANCELED_ORDERS_RETRY, retry: true, message: e.message});
    }
}


export function* fetchOrderInfo(action) {
    try {
        const response = yield call(api.ecommerce.orders.info, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrderInfo', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ORDER_INFO_RESULT, data: result});
        } else {
            yield put({type: FETCH_ORDER_INFO_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ORDER_INFO_RETRY, retry: true, message: e.message});
    }
}


export function* confirmOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.confirm, action.data);
        const result = yield response.json();
        console.warn('SAGA:confirmOrder', action, result);

        if (response.status === 200) {
            yield put({type: CONFIRM_ORDER_RESULT, data: result});
        } else {
            if (result.message) toast(result.message);
            yield put({type: CONFIRM_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CONFIRM_ORDER_RETRY, retry: true, message: e.message});
    }
}


export function* cancelOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.cancel, action.data);
        const result = yield response.json();
        console.warn('SAGA:cancelOrder', action, result);

        if (response.status === 200) {
            yield put({type: CANCEL_ORDER_RESULT, data: result});
        } else {
            yield put({type: CANCEL_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CANCEL_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* createOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createOrder', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_ORDER_RESULT, data: result});
        } else {
            yield put({type: CREATE_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* fetchOrderEstimate(action) {
    try {
        const response = yield call(api.ecommerce.orders.estimate, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchOrderEstimate', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_ORDER_ESTIMATE_RESULT, data: result});
        } else {
            yield put({type: FETCH_ORDER_ESTIMATE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_ORDER_ESTIMATE_RETRY, retry: true, message: e.message});
    }
}

export function* refundOrder(action) {
    try {
        const response = yield call(api.ecommerce.orders.refund, action.data);
        const result = yield response.json();
        console.warn('SAGA:refundOrder', action, result);

        if (response.status === 201) {
            yield put({type: REFUND_ORDER_RESULT, data: result});
        } else {
            yield put({type: REFUND_ORDER_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: REFUND_ORDER_RETRY, retry: true, message: e.message});
    }
}

export function* updateOrderAddress(action) {
    try {
        const response = yield call(api.ecommerce.orders.address, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateOrderAddress', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_ORDER_ADDRESS_RESULT, data: result});
        } else {
            yield put({type: UPDATE_ORDER_ADDRESS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_ORDER_ADDRESS_RETRY, retry: true, message: e.message});
    }
}

export function* convertToOnline(action) {
    try {
        const response = yield call(api.ecommerce.orders.convertToOnline, action.data);
        const result = yield response.json();
        console.warn('SAGA:convertToOnline', action, result);

        if (response.status === 200) {
            yield put({type: CONVERT_TO_ONLINE_RESULT, data: result});
        } else {
            if (result.message) toast(result.message);
            yield put({type: CONVERT_TO_ONLINE_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CONVERT_TO_ONLINE_RETRY, retry: true, message: e.message});
    }
}

export function* resendPaymentLink(action) {
    try {
        const response = yield call(api.ecommerce.orders.resendPaymentLink, action.data);
        const result = yield response.json();
        console.warn('SAGA:resendPaymentLink', action, result);

        if (response.status === 200) {
            yield put({type: RESEND_PAYMENT_LINK_RESULT, data: result});
        } else {
            if (result.message) toast(result.message);
            yield put({type: RESEND_PAYMENT_LINK_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: RESEND_PAYMENT_LINK_RETRY, retry: true, message: e.message});
    }
}

export function* sendAyurvedicQuizLink(action) {
    try {
        const response = yield call(api.ecommerce.orders.sendAyurvedicQuizLink, action.data);
        const result = yield response.json();
        console.warn('SAGA:sendAyurvedicQuizLink', action, result);

        if (response.status === 200) {
            yield put({type: SEND_AYURVEDIC_QUIZ_LINK_RESULT, data: result});
        } else {
            if (result.message) toast(result.message);
            yield put({type: SEND_AYURVEDIC_QUIZ_LINK_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: SEND_AYURVEDIC_QUIZ_LINK_RETRY, retry: true, message: e.message});
    }
}

export function* sendCheckoutLink(action) {
    try {
        const response = yield call(api.ecommerce.orders.checkoutLink, action.data);
        const result = yield response.json();
        console.warn('SAGA:sendCheckoutLink', action, result);

        if (response.status === 200) {
            yield put({type: CHECKOUT_LINK_RESULT, data: result});
        } else {
            if (result.message) toast(result.message);
            yield put({type: CHECKOUT_LINK_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CHECKOUT_LINK_RETRY, retry: true, message: e.message});
    }
}

export function* fetchRepeatOrders(action) {
    try {
        const response = yield call(api.ecommerce.orders.repeat, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchRepeatOrders', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_REPEAT_ORDERS_RESULT, data: result});
        } else {
            yield put({type: FETCH_REPEAT_ORDERS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_REPEAT_ORDERS_RETRY, retry: true, message: e.message});
    }
}
