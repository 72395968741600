import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocketIOClient from "socket.io-client";

import "./styles.scss";
import {fetchIncomingCall} from "../../../actions/ecommerce/calls";
import {NODE_SERVER_HOST} from "../../../constants";
import {withRouter} from "react-router";
import IncomingCallDetailForm from "../../forms/IncomingCallDetailForm";
import ring from "../../../assets/audio/ring.mp3"


class IncomingCallToast extends React.Component {

    componentDidMount() {
        this.props.fetchIncomingCall();
        this.setSocketListeners();
    }

    state = {
        showIncomingCall: {},
        connected: true,
    }

    socket = SocketIOClient(NODE_SERVER_HOST, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999
    });

    setSocketListeners = () => {
        let {profile} = this.props;
        if (profile.data) {
            this.socket.on(`SUPPORT:INCOMING_CALL:${profile.data.id}`, this.handleIncomingCalls);
        }
        this.socket.on('reconnect', (attemptNumber) => {
            // this.handleOrderUpdates();
            this.setState({connected: true});
        });
        this.socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.socket.connect();
            }
            this.setState({connected: false});
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {calls} = this.props;

        if (!calls.isFetchingIncomingCall && prevProps.calls.isFetchingIncomingCall && !calls.error && !calls.retry) {
            this.handleShowIncomingCalls();
        }
        if (!calls.isUpdating && prevProps.calls.isUpdating && !calls.error && !calls.retry) {
            this.props.fetchIncomingCall();
        }
    }

    handleIncomingCalls = (data) => {
        if(data.agent_pass_through.agent_status === 'busy') {
            let audio = new Audio(ring);
            audio.play();
        }
        this.props.fetchIncomingCall();
    };

    handleShowIncomingCalls = () => {
        let {calls: {incomingCall}} = this.props;
        let showIncomingCall = {};
        if (incomingCall && incomingCall.incoming_calls) {
            incomingCall.incoming_calls.map(
                obj => {
                    showIncomingCall[obj.sid] = true;
                }
            )
        }
        this.setState({showIncomingCall});
    };

    handleChangeShowIncomingCalls = (sid, value) => {
        this.setState(prevState => ({showIncomingCall: {...prevState.showIncomingCall, [sid]: value}}));
    };

    render() {
        let {calls: {incomingCall}} = this.props;
        let {connected, showIncomingCall} = this.state;

        // incomingCall = {
        //     ...incomingCall,
        //     incoming_calls: [
        //         {
        //             id: 1,
        //             sid: '1234',
        //             data: {},
        //             customer_id: 51,
        //             status: 'ringing',
        //             agent_status: 'free',
        //             agent_pass_through: {
        //                 agent_status: 'free',
        //                 agent_called_at: moment().format()
        //             }
        //         }
        //     ]
        // }

        return (
            <React.Fragment>
                {
                    !connected &&
                    <div className="connection-status">
                        Network Issue, please refresh the page.
                    </div>
                }
                {
                    !!incomingCall &&
                    !!incomingCall.incoming_calls &&
                    incomingCall.incoming_calls.filter(
                        obj => ((Object.keys(obj.data).length === 0) && (!obj.remarks))
                    ).map(
                        (obj, index) => {
                            let modalCount = index + 2;
                            return (
                                <React.Fragment key={obj.sid}>
                                    <div className={`incoming-call-toast incoming-call-toast-${modalCount}`}>
                                        <IncomingCallDetailForm callData={obj}
                                                                isCollapsed={!showIncomingCall[obj.sid]}
                                                                toggleCollapse={(value) => this.handleChangeShowIncomingCalls(obj.sid, value)}/>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    calls: state.ecommerce.calls,
    profile: state.accounts.profile,
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
    fetchIncomingCall,
}, dispatch);

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(IncomingCallToast));
