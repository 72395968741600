import store from "./store";

export const SUPPORT = 'support';

export const checkRole = (role, user = store.getState().accounts.login.detail) => {
    return (user.permissions.roles || []).includes(role);
};

export const isUserSupport = (user = store.getState().accounts.login.detail) => {
    return checkRole(SUPPORT, user);
};