import {getQueryString, HOST} from '../constants'
import makeRequest from "./makeRequest";

export {HOST};


export const jsonToForm = (data) => {
    const form = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);

    keys.map((key, i) => {
        form.append(key, values[i])
    });
    return form;
};


export const addFileToForm = ({form, key, file}) => {
    // form.append(key, {
    //     uri: file.uri,
    //     name: file.name,
    //     type: file.type ? file.type: 'image/jpg',
    // });
    form.append(key, file, file.name);
    return form;
};


export default {
    accounts: {
        postUserLogin: data => (makeRequest(
            `${HOST}/api/v1/accounts/login/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        getUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'get'}
        )),
        patchUser: data => (makeRequest(
            `${HOST}/api/v1/accounts/profile/`,
            {method: 'PATCH', body: jsonToForm(data)}
        )),
        requestOtp: data => (makeRequest(
            `${HOST}/api/v1/accounts/otp/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenVerify: data => (makeRequest(
            `${HOST}/api/v1/accounts/token/verify/`,
            {method: 'post', body: jsonToForm(data)}
        )),
        tokenRefresh: data => (fetch(
            `${HOST}/api/v1/accounts/token/refresh/`,
            {method: 'post', body: jsonToForm(data)}
        )),
    },
    ecommerce: {
        orders: {
            list: (data) => makeRequest(`${HOST}/api/v1/support/orders/?${getQueryString(data)}`,),
            pending: ({page = 1, search = ''}) => makeRequest(`${HOST}/api/v1/support/orders/?status=pending&page=${page}&q=${search}`,),
            canceled: ({page = 1, search = ''}) => makeRequest(`${HOST}/api/v1/support/orders/?status=canceled&page=${page}&q=${search}`,),
            confirm: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/confirm/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            repeat: (data) => makeRequest(`${HOST}/api/v1/support/orders/repeat/?${getQueryString(data)}`,),
            cancel: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/cancel/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            info: ({id}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/info/`,
            ),
            create: data => makeRequest(
                `${HOST}/api/v1/support/orders/create/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            estimate: data => makeRequest(
                `${HOST}/api/v1/support/orders/estimate/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            refund: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/refund/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            address: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/address/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            resendPaymentLink: ({id}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/payment-link/`,
                {method: 'POST'}
            ),
            sendAyurvedicQuizLink: (data) => makeRequest(
                `${HOST}/api/v1/support/send-ayurvedic-quiz-link/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            convertToOnline: ({id}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/convert-to-online/`,
                {method: 'POST'}
            ),
            checkoutLink: ({id, ...data}) => makeRequest(
                `${HOST}/api/v1/support/orders/${id}/checkout-link/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        calls: {
            list: (data) => makeRequest(`${HOST}/api/v1/support/call/?${getQueryString(data)}`,),
            get: ({sid}) => makeRequest(`${HOST}/api/v1/support/call/${sid}/`),
            create: data => makeRequest(
                `${HOST}/api/v1/support/call/create/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            update: data => makeRequest(
                `${HOST}/api/v1/support/call/${data.sid}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
            incomingCall: () => makeRequest(`${HOST}/api/v1/support/incoming-call/`),
            callTransfer: (data) => makeRequest(
            `${HOST}/api/v1/support/call-transfer/create/`,
            {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            activeAgents: () => makeRequest(
                `${HOST}/api/v1/support/active-agents/`,
            ),
        },
        scheduledcalls: {
            list: ({page = 1, search = ''}) => makeRequest(`${HOST}/api/v1/support/scheduled-calls/?page=${page}&q=${search}`,),
            create: data => makeRequest(
                `${HOST}/api/v1/support/scheduled-calls/create/`,
                {method: 'POST', body: JSON.stringify(data), bodyType: 'json'}
            ),
            update: data => makeRequest(
                `${HOST}/api/v1/support/scheduled-calls/${data.id}/`,
                {method: 'PATCH', body: JSON.stringify(data), bodyType: 'json'}
            ),
        },
        products: {
            list: () => makeRequest(`${HOST}/api/v1/support/products/`),
        },
        pharmacy: {
            list: () => makeRequest(`${HOST}/api/v1/support/pharmacy/`),
        },
        reports: {
            list: () => makeRequest(`${HOST}/api/v1/support/reports/`),
        },
        coupons: {
            list: () => makeRequest(`${HOST}/api/v1/support/coupons/`),
        },
        customers: {
            list: (data) => makeRequest(`${HOST}/api/v1/support/customers/?${getQueryString(data)}`,),
            get: ({id}) => makeRequest(
                `${HOST}/api/v1/support/customers/${id}/`,
            ),
        },
        shift: {
            get: () => makeRequest(
                `${HOST}/api/v1/support/shift/`,
            ),
            update: () => makeRequest(
                `${HOST}/api/v1/support/shift/`,
                {method: 'PATCH'}
            ),
        }
    },
    consultations: {
        consultations: {
            create: data => makeRequest(
                `${HOST}/api/v1/consultation/create/`,
                {method: 'POST', body: jsonToForm(data)}
            ),
            list: data => makeRequest(
                `${HOST}/api/v1/consultation/${data.patient_id}/`,
            ),
            templates: () => makeRequest(
                `${HOST}/api/v1/consultation/templates/`,
            ),
        },
    },
    quiz: {
        answers: {
            create: ({field_id, bot_form_id}) => makeRequest(
                `${HOST}/api/v1/bot/assign/`,
                {method: 'POST', body: JSON.stringify({field_id, bot_form_id}), bodyType: 'json'}
            ),
            update: (data) => makeRequest(
                `${HOST}/api/v1/bot/bot_field_response/${data.id}/`,
                {method: 'PATCH', body: jsonToForm(data)}
            ),
            assignReport: ({order_id, report_id}) => makeRequest(
                `${HOST}/api/v1/support/orders/${order_id}/assign-report/`,
                {method: 'POST', body: JSON.stringify({report_id}), bodyType: 'json'}
            ),
            assignReportForQuizTaker: ({new_report_id, report_id}) => makeRequest(
                `${HOST}/api/v1/support/reports/${report_id}/assign-report/`,
                {method: 'POST', body: JSON.stringify({new_report_id}), bodyType: 'json'}
            )
        },
        quizzers: {
            list: (data) => makeRequest(`${HOST}/api/v1/support/quizzers/?${getQueryString(data)}`),
            info: ({id}) => makeRequest(
                `${HOST}/api/v1/support/quizzers/${id}/`,
            ),
        }
    },
    support: {
        clinics: {
            list: () => makeRequest(`${HOST}/api/v1/clinic/clinics/`,),
        }
    }
}
