import {call, put} from 'redux-saga/effects';
import {

    FETCH_SCHEDULED_CALLS,
    FETCH_SCHEDULED_CALLS_RESULT,
    FETCH_SCHEDULED_CALLS_ERROR,
    FETCH_SCHEDULED_CALLS_RETRY,
    CREATE_SCHEDULED_CALL,
    CREATE_SCHEDULED_CALL_RESULT,
    CREATE_SCHEDULED_CALL_ERROR,
    CREATE_SCHEDULED_CALL_RETRY,
    UPDATE_SCHEDULED_CALL_RESULT,
    UPDATE_SCHEDULED_CALL_ERROR,
    UPDATE_SCHEDULED_CALL_RETRY,
} from '../../actions/ecommerce/scheduledcalls';
import api from "../api";
import {toast} from "react-toastify";


export function* fetchScheduledCalls(action) {
    try {
        const response = yield call(api.ecommerce.scheduledcalls.list, action.data);
        const result = yield response.json();
        console.warn('SAGA:fetchScheduledCalls', action, result);

        if (response.status === 200) {
            yield put({type: FETCH_SCHEDULED_CALLS_RESULT, data: result});
        } else {
            yield put({type: FETCH_SCHEDULED_CALLS_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: FETCH_SCHEDULED_CALLS_RETRY, retry: true, message: e.message});
    }
}

export function* createScheduledCall(action) {
    try {
        const response = yield call(api.ecommerce.scheduledcalls.create, action.data);
        const result = yield response.json();
        console.warn('SAGA:createScheduledCall', action, result);

        if (response.status === 201) {
            yield put({type: CREATE_SCHEDULED_CALL_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message);
            yield put({type: CREATE_SCHEDULED_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: CREATE_SCHEDULED_CALL_RETRY, retry: true, message: e.message});
    }
}

export function* updateScheduledCall(action) {
    try {
        const response = yield call(api.ecommerce.scheduledcalls.update, action.data);
        const result = yield response.json();
        console.warn('SAGA:updateScheduledCall', action, result);

        if (response.status === 200) {
            yield put({type: UPDATE_SCHEDULED_CALL_RESULT, data: result});
        } else {
            if (result.message)
                toast(result.message);
            yield put({type: UPDATE_SCHEDULED_CALL_ERROR, error: true, message: result});
        }
    } catch (e) {
        yield put({type: UPDATE_SCHEDULED_CALL_RETRY, retry: true, message: e.message});
    }
}
